import LoadingIcon from "../../components/Base/LoadingIcon";
import { Pagination } from "antd";
import clsx from "clsx";
import _ from "lodash";
import { useState } from "react";

const ListBase = ({
  title,
  actionsMenu,
  isFilterActive,
  filters,
  table,
  isLoading,
  headerType = "A",
  contentList,
  page,
  setPage,
  tableEmptyMsg,
  getContentListOnPageChange,
  setIsLoading,
}) => {

  const handlePageChange = _.debounce(async (newPage) => {
    setIsLoading(true);
    await getContentListOnPageChange(newPage);
    setIsLoading(false);
  }, 300);

  return (
    <div>
      {headerType === "A" ? (
        <>
          <section className="flex justify-between items-center flex-col lg:flex-row gap-3">
            <div>
              <h3 className="text-[24px] font-medium">{title}</h3>
            </div>

            <div className="flex flex-col lg:flex-row lg:flex-strech gap-2.5 w-full lg:w-fit">
              {actionsMenu}
            </div>
          </section>
          {isFilterActive && filters && (
            <div className="mt-4 lg:mt-6">
              {filters}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="flex flex-wrap items-center justify-between py-4 gap-3 xl:gap-0 border-b sm:py-0 sm:h-[58px] px-[30px]">
            <h6 className="text-[18px] font-semibold text-[#3F4254]">
              {title}
            </h6>

            <div className="flex flex-strech gap-2.5">{actionsMenu}</div>
          </div>
        </>
      )}

      <section
        className={clsx([
          headerType === "B" && "p-[30px] pb-0",
          "overflow-x-scroll xl:overflow-hidden",
        ])}
      >
        {isLoading ? (
          <div className="h-96 relative flex flex-col items-center justify-center">
            <LoadingIcon icon="three-dots" className="w-8 h-8" />
          </div>
        ) : (
          table
        )}

        {!isLoading && (contentList?.data?.length === 0 || contentList?.length === 0)&& (
          <p className="mx-auto w-full text-center my-16">{tableEmptyMsg}</p>
        )}
      </section>

      <div className={clsx(["text-center pt-6", headerType === "B" && "pb-6"])}>
        {contentList.pagination && !isLoading && contentList?.pagination.total >= 1 && (
          <Pagination
            showSizeChanger={false}
            className="mx-auto"
            defaultCurrent={+contentList?.pagination.current_page}
            current={page}
            defaultPageSize={+contentList?.pagination.per_page}
            total={+contentList?.pagination.last_page * +contentList?.pagination.per_page}
            onChange={(page) => {
              setPage(page);
              setIsLoading(true);
              handlePageChange(page);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default ListBase;
