import Button from "../../../components/Base/Button";
import Lucide from "../../../components/Base/Lucide";
import Modal from "../../../components/Base/Modal";
import CustomerService from "../../../pages/Customers/Services/CustomerService";
import { useFormik } from "formik";
import { useRef } from "react";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const FooterModal = ({ setCustomer, photo, customer_id, closeModal }) => {
  return (
    <div className="flex items-center justify-end gap-2">
      {photo && (
        <Button
          type="button"
          form="photoForm"
          variant="danger"
          size="sm"
          onClick={async () => {
            try {
              Swal.showLoading();
              await CustomerService.removePhoto(customer_id);

              setCustomer((oldCustomer) => {
                return {
                  ...oldCustomer,
                  photo_name: null,
                };
              });
              closeModal();

              Swal.fire({
                title: "A foto do cliente foi removida com sucesso!",
                icon: "success",
                confirmButtonColor: "#3E97FF",
              });
            } catch (e) {
              console.log(e);
            }
          }}
        >
          Remover foto
        </Button>
      )}

      <Button
        type="submit"
        form="photoForm"
        variant="primary"
        size="sm"
        // onClick={() => closeModal()}
        // className="text-[13px] font-semibold leading-4"
      >
        {/* <Lucide icon="X" className="mr-1  w-4" /> */}
        Salvar foto
      </Button>
    </div>
  );
};

const EditPhotoModal = (props) => {
  const { isModalOpen, setCustomer, closeModal, customer, customer_id } = props;

  const fileInputRef = useRef(null);

  const handleSelectImagem = () => {
    fileInputRef.current.click();
  };

  const handleUploadImagem = (event) => {
    const file = event.target.files[0]; // Obtém o primeiro arquivo do evento de upload

    // Verifica se um arquivo foi selecionado
    if (file) {
      // Verifica se o arquivo é uma imagem (opcional)
      if (file.type.startsWith("image/")) {
        // Define a imagem no estado do formulário
        formik.setFieldValue("imagem", file);
        // Limpa a URL da foto, se houver
        formik.setFieldValue("fotoUrl", "");
      } else {
        alert("Por favor, selecione uma imagem.");
      }
    }
  };

  const formik = useFormik({
    initialValues: {
      imagem: null,
    },
    onSubmit: async (values) => {
      Swal.showLoading();
      console.log("Imagem enviada:", values.imagem);

      try {
        const res = await CustomerService.updatePhoto(customer_id, {
          photo: values.imagem,
        });

        if (res.error) {
          if (res.error?.error) {
            toast.error("Corrija os erros e tente novamente.");
          } else {
            formik.setErrors(res.error.data.errors);
            toast.error("Corrija os erros e tente novamente.");
          }
        } else {
          const res = await CustomerService.findCustomer(customer_id);

          setCustomer(res.data);
          closeModal();

          Swal.fire({
            title: "A foto do cliente foi atualizada com sucesso!",
            icon: "success",
            confirmButtonColor: "#3E97FF",
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
  });

  const handleLimparImagem = () => {
    formik.setFieldValue("imagem", null);
    fileInputRef.current.value = "";
  };

  return (
    <Modal
      title="Editar foto do cliente"
      open={isModalOpen}
      width={450}
      footer={
        <FooterModal
          setCustomer={setCustomer}
          photo={customer.photo_name}
          customer_id={customer.id}
          closeModal={() => {
            closeModal();
            handleLimparImagem();
          }}
        />
      }
      closeModal={() => {
        handleLimparImagem();
        closeModal();
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
        className="grid grid-cols-12 gap-5"
        id="photoForm"
      >
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2 md:gap-5 w-full col-span-12">
          <div className="p-[5px] bg-light rounded border border-dashed min-w-[80px] min-h-[80px] flex items-center justify-center">
            <input
              id="imagem"
              name="imagem"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              ref={fileInputRef}
              onChange={handleUploadImagem}
            />
            {formik.values.imagem ? (
              <div>
                <img
                  src={URL.createObjectURL(formik.values.imagem)}
                  alt="Imagem"
                  className="w-[80px] h-[80px] object-cover mx-auto"
                />
              </div>
            ) : customer.photo_name ? (
              <div>
                <img
                  src={
                    import.meta.env.VITE_REACT_APP_API_URL +
                    "/images/" +
                    customer.photo_name
                  }
                  className="w-[80px] h-[80px] object-cover mx-auto"
                />
              </div>
            ) : (
              <div className="h-[60px] rounded-xl object-cover w-[60px] bg-slate-200 flex items-center justify-center">
                <Lucide
                  icon={customer.type === "PJ" ? "Building" : "User"}
                  className="w-10 h-10 text-slate-400"
                />
              </div>
            )}
          </div>

          <div className="flex flex-col justify-center items-start text-[#78829D] w-full">
            <p className="text-xs font-normal">
              <span className="font-bold">Atenção! </span>
              Somente serão aceitos arquivos nos formatos (PNG ou JPG); <br /> O
              tamanho do arquivo não deve ultrapassar 2MB.
            </p>

            <Button
              variant="outline-secondary"
              type="button"
              size="sm"
              onClick={handleSelectImagem}
              className="mt-2 md:mt-1"
            >
              <Lucide icon="Import" className="mr-1 h-4" />
              Selecionar imagem
            </Button>
          </div>

          {formik.errors.imagem && <div>{formik.errors.imagem}</div>}
        </div>
      </form>
    </Modal>
  );
};

export default EditPhotoModal;
