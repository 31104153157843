import _ from "lodash";
import dayjs from "dayjs";

const imageAssetsContext = require.context(
  "../assets/images/fakers",
  false,
  /\.(jpg|jpeg|png|svg)$/
);

const imageAssets = {};
imageAssetsContext.keys().forEach((key) => {
  const fileName = key.replace("./", "");
  imageAssets[fileName] = imageAssetsContext(key);
});

const fakers = {
  fakeUsers() {
    const users = [
      { name: "Johnny Depp", gender: "male" },
      { name: "Al Pacino", gender: "male" },
      { name: "Robert De Niro", gender: "male" },
      { name: "Kevin Spacey", gender: "male" },
      { name: "Denzel Washington", gender: "male" },
      { name: "Russell Crowe", gender: "male" },
      { name: "Brad Pitt", gender: "male" },
      { name: "Angelina Jolie", gender: "female" },
      { name: "Leonardo DiCaprio", gender: "male" },
      { name: "Tom Cruise", gender: "male" },
      { name: "John Travolta", gender: "male" },
      { name: "Arnold Schwarzenegger", gender: "male" },
      { name: "Sylvester Stallone", gender: "male" },
      { name: "Kate Winslet", gender: "female" },
      { name: "Christian Bale", gender: "male" },
      { name: "Morgan Freeman", gender: "male" },
      { name: "Keanu Reeves", gender: "male" },
      { name: "Nicolas Cage", gender: "male" },
      { name: "Hugh Jackman", gender: "male" },
      { name: "Edward Norton", gender: "male" },
      { name: "Bruce Willis", gender: "male" },
      { name: "Tom Hanks", gender: "male" },
      { name: "Charlize Theron", gender: "female" },
      { name: "Will Smith", gender: "male" },
      { name: "Sean Connery", gender: "male" },
      { name: "Keira Knightley", gender: "female" },
      { name: "Vin Diesel", gender: "male" },
      { name: "Matt Damon", gender: "male" },
      { name: "Richard Gere", gender: "male" },
      { name: "Catherine Zeta-Jones", gender: "female" },
    ];

    return _.sampleSize(users, 3).map((user) => ({
      name: user.name,
      gender: user.gender,
      email: _.toLower(_.replace(user.name, / /g, "") + "@left4code.com"),
    }));
  },
  fakePhotos() {
    const photos = [];
    for (let i = 0; i < 15; i++) {
      const imageName = "profile-" + _.random(1, 15) + ".jpg";
      if (imageAssets[imageName]) {
        photos.push(imageAssets[imageName].default);
      }
    }
    return _.sampleSize(photos, 10);
  },
  fakeImages() {
    const images = [];
    for (let i = 0; i < 15; i++) {
      const imageName = "preview-" + _.random(1, 15) + ".jpg";
      if (imageAssets[imageName]) {
        images.push(imageAssets[imageName].default);
      }
    }
    return _.sampleSize(images, 10);
  },
  fakeDates() {
    const dates = [];
    for (let i = 0; i < 5; i++) {
      dates.push(
        dayjs.unix(_.random(1586584776897, 1672333200000) / 1000).format("DD MMMM YYYY")
      );
    }
    return _.sampleSize(dates, 3);
  },
  fakeTimes() {
    const times = ["01:10 PM", "05:09 AM", "06:05 AM", "03:20 PM", "04:50 AM", "07:00 PM"];
    return _.sampleSize(times, 3);
  },
  fakeFormattedTimes() {
    const times = [
      _.random(10, 60) + " seconds ago",
      _.random(10, 60) + " minutes ago",
      _.random(10, 24) + " hours ago",
      _.random(10, 20) + " days ago",
      _.random(10, 12) + " months ago",
    ];
    return _.sampleSize(times, 3);
  },
  fakeTotals() {
    return _.shuffle([_.random(20, 220), _.random(20, 120), _.random(20, 50)]);
  },
  fakeTrueFalse() {
    return _.sampleSize([false, true, true], 1);
  },
  fakeStocks() {
    return _.shuffle([_.random(50, 220), _.random(50, 120), _.random(50, 50)]);
  },
  fakeProducts() {
    const products = [
      { name: "Dell XPS 13", category: "PC & Laptop" },
      { name: "Apple MacBook Pro 13", category: "PC & Laptop" },
      { name: "Oppo Find X2 Pro", category: "Smartphone & Tablet" },
      { name: "Samsung Galaxy S20 Ultra", category: "Smartphone & Tablet" },
      { name: "Sony Master Series A9G", category: "Electronic" },
      { name: "Samsung Q90 QLED TV", category: "Electronic" },
      { name: "Nike Air Max 270", category: "Sport & Outdoor" },
      { name: "Nike Tanjun", category: "Sport & Outdoor" },
      { name: "Nikon Z6", category: "Photography" },
      { name: "Sony A7 III", category: "Photography" },
    ];
    return _.shuffle(products);
  },
  fakeCategories() {
    const categories = [
      { name: "PC & Laptop", tags: "Apple, Asus, Lenovo, Dell, Acer" },
      {
        name: "Smartphone & Tablet",
        tags: "Samsung, Apple, Huawei, Nokia, Sony",
      },
      { name: "Electronic", tags: "Sony, LG, Toshiba, Hisense, Vizio" },
      {
        name: "Home Appliance",
        tags: "Whirlpool, Amana, LG, Frigidaire, Samsung",
      },
      { name: "Photography", tags: "Canon, Nikon, Sony, Fujifilm, Panasonic" },
      { name: "Fashion & Make Up", tags: "Nike, Adidas, Zara, H&M, Levi’s" },
      {
        name: "Kids & Baby",
        tags: "Mothercare, Gini & Jony, H&M, Babyhug, Liliput",
      },
      { name: "Hobby", tags: "Bandai, Atomik R/C, Atlantis Models, Carisma" },
      {
        name: "Sport & Outdoor",
        tags: "Nike, Adidas, Puma, Rebook, Under Armour",
      },
    ];

    return _.sampleSize(categories, 3).map((category) => ({
      name: category.name,
      tags: category.tags,
      slug: _.replace(_.replace(_.toLower(category.name), / /g, "-"), "&", "and"),
    }));
  },
  fakeNews() {
    const news = [
      {
        title: "Desktop publishing software like Aldus PageMaker",
        superShortContent: _.truncate(
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...",
          { length: 30, omission: "" }
        ),
        shortContent: _.truncate(
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s...",
          { length: 150, omission: "" }
        ),
        content:
          "Lorem Ipsum is simply dummy text of the printing and typesetting industry...",
      },
      {
        title: "Dummy text of the printing and typesetting industry",
        superShortContent: _.truncate(
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout...",
          { length: 30, omission: "" }
        ),
        shortContent: _.truncate(
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout...",
          { length: 150, omission: "" }
        ),
        content:
          "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout...",
      },
    ];
    return _.shuffle(news);
  },
  fakeFiles() {
    const files = [
      { fileName: "Celine Dion - Ashes.mp4", type: "MP4", size: "20 MB" },
      { fileName: "Laravel 7", type: "Empty Folder", size: "120 MB" },
      { fileName: fakers.fakeImages()[0], type: "Image", size: "1.2 MB" },
      { fileName: "Repository", type: "Folder", size: "20 KB" },
      { fileName: "Resources.txt", type: "TXT", size: "2.2 MB" },
    ];
    return _.shuffle(files);
  },
  fakeJobs() {
    const jobs = ["Frontend Engineer", "Software Engineer", "Backend Engineer", "DevOps Engineer"];
    return _.shuffle(jobs);
  },
  fakeNotificationCount() {
    return _.random(1, 7);
  },
  fakeFoods() {
    const foods = [
      {
        name: "Vanilla Latte",
        image: imageAssets["food-beverage-1.jpg"],
      },
      {
        name: "Milkshake",
        image: imageAssets["food-beverage-2.jpg"],
      },
    ];
    return _.shuffle(foods);
  },
  fakeCustomers() {
    return [
    {
      customer_id: 1,
      customer: {
        name: "João Silva",
        fantasy_name: "JS Comércio",
        document: "12345678900",
        email: "joao.silva@example.com",
        address: {
          zipcode: "12345-678",
          street: "Rua das Flores",
          number: "100",
          neighborhood: "Centro",
          city: "São Paulo",
          state: "SP",
        },
        telephone: "(11) 98765-4321",
        created_at: "2023-01-10T14:30:00",
        last_access: "2023-02-15T10:20:00",
        ip_address: "192.168.1.1",
        status: "Ativo",
        products: [
          {
            id: 101,
            name: "Hospedagem Básica",
            description: "Plano de hospedagem básico",
            price: 29.99,
            status: "Ativo",
          },
          {
            id: 102,
            name: "E-mail Profissional",
            description: "Conta de e-mail profissional",
            price: 9.99,
            status: "Ativo",
          },
        ],
      },
    },
    {
      customer_id: 2,
      customer: {
        name: "Maria Oliveira",
        fantasy_name: "MO Soluções",
        document: "98765432100",
        email: "maria.oliveira@example.com",
        address: {
          zipcode: "87654-321",
          street: "Avenida Principal",
          number: "200",
          neighborhood: "Bela Vista",
          city: "Rio de Janeiro",
          state: "RJ",
        },
        telephone: "(21) 91234-5678",
        created_at: "2023-02-05T09:15:00",
        last_access: "2023-02-20T16:45:00",
        ip_address: "192.168.1.2",
        status: "Inativo",
        products: [
          {
            id: 103,
            name: "Servidor VPS",
            description: "Servidor virtual privado",
            price: 199.99,
            status: "Inativo",
          },
        ],
      },
    },
    // Adicione mais clientes conforme necessário
  ];
 } 
};

const fakerData = [];
for (let i = 0; i < 20; i++) {
  fakerData.push({
    users: fakers.fakeUsers(),
    photos: fakers.fakePhotos(),
    images: fakers.fakeImages(),
    dates: fakers.fakeDates(),
    times: fakers.fakeTimes(),
    formattedTimes: fakers.fakeFormattedTimes(),
    totals: fakers.fakeTotals(),
    trueFalse: fakers.fakeTrueFalse(),
    stocks: fakers.fakeStocks(),
    products: fakers.fakeProducts(),
    categories: fakers.fakeCategories(),
    news: fakers.fakeNews(),
    files: fakers.fakeFiles(),
    jobs: fakers.fakeJobs(),
    notificationCount: fakers.fakeNotificationCount(),
    foods: fakers.fakeFoods(),
    customers: fakers.fakeCustomers(),
  });
}

export default fakerData;
