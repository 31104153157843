import HttpClient from "./HttpClient";
import TokenService from "./TokenService";

class AuthService {
  async login(credentials) {
    return HttpClient.post("/login", credentials);
  }

  async logout() {
    
    TokenService.removeAccessToken();
    
    return HttpClient.post("/logout");
  }
}

export default new AuthService();
