// Import necessary modules and components
import Badge from "../../../components/Badge";
import Lucide from "../../../components/Base/Lucide";
import Table from "../../../components/Base/Table";
import { useNavigate, useParams } from "react-router-dom";
import Cog from "../../../assets/images/cog2.svg";
import Button from "../../../components/Base/Button";
import MenuFloat from "../../../components/MenuFloat";
import { useEffect, useState } from "react";
import TicketService from "../../../pages/Tickets/Services/TicketService";
import ListBase from "../../../layout/ListBase";
import { t } from "i18next";
import { FormCheck } from "../../../components/Base/Form";
import {
  formatDate,
  formatToBRL,
  getPositionsMenuFloat,
} from "../../../utils/helpers";

/**
 * Tickets Component
 *
 * This component displays a list of tickets associated with a customer.
 */
const Tickets = () => {
  // Hooks
  const [positions, setPositions] = useState({ x: 0, y: 0 });
  const { customer_id } = useParams();
  const [tickets, setTickets] = useState([]); // Inicializado como array vazio
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null); // Estado para erros
  const navigate = useNavigate();
  const [productOrderId, setProductOrderId] = useState(null);
  const [menuFloatActive, setMenuFloatActive] = useState(false);

  // Função auxiliar para buscar tickets
  const fetchTickets = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await TicketService.getTicketsByCustomerId({
        limit: 10, // Limite de itens por página
        page: pageNumber, // Página atual
        orderBy: "created_at", // Ordenar por data de criação
        orderDirection: "desc", // Ordem decrescente
        customerId: customer_id, // ID do cliente
        filters: {}, // Filtros adicionais, se necessário
      });
      setTickets(response.data); // Ajuste conforme o formato de response
    } catch (error) {
      console.error("Erro ao carregar tickets:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Close the menu float
  const closeMenuFloat = () => {
    setProductOrderId(null);
    setMenuFloatActive(false);
  };

  // Handle click event for menu float
  const handleClick = (e, idProductOrder) => {
    setProductOrderId(idProductOrder);
    setPositions(getPositionsMenuFloat(e, 20));
    setMenuFloatActive(true);
  };

  // Fetch tickets on component mount
  useEffect(() => {
    fetchTickets();
  }, [customer_id]);

  // Handle page change in pagination
  const contentListOnPageChange = (newPage) => {
    fetchTickets(newPage);
  };

  return (
    <main>
      <section className="md:min-w-[460px] bg-white rounded-xl font-nunito shadow-custom-1">
        <ListBase
          filters={<></>}
          getContentListOnPageChange={contentListOnPageChange}
          isFilterActive={false}
          setIsLoading={setIsLoading}
          tableEmptyMsg="Não há nenhum ticket associado a este cliente."
          title="Tickets"
          headerType="B"
          isLoading={isLoading}
          table={
            <Table hover={true}>
              <Table hover className="mt-[0px] bord">
                <Table.Thead>
                  <Table.Tr className="bg-[#F9F9F9] h-[35px] pb-2">
                    <Table.Th className="border-b-0 whitespace-nowrap rounded-l">
                      <FormCheck.Input type="checkbox" />
                    </Table.Th>
                    <Table.Th className="text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold">
                      Departamento
                    </Table.Th>
                    <Table.Th className="text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold">
                      Assunto
                    </Table.Th>
                    <Table.Th className="text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold">
                      Requisitor
                    </Table.Th>
                    <Table.Th className="text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold">
                      Status
                    </Table.Th>
                    <Table.Th className="text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold">
                      Última Resposta
                    </Table.Th>
                    <Table.Th className="text-[#67759A] text-[14px] font-nunito text-center whitespace-nowrap font-semibold rounded-r">
                      Ações
                    </Table.Th>
                  </Table.Tr>
                </Table.Thead>
                {!isLoading && (
                  <Table.Tbody className="before:content-['@'] before:block before:leading-[8px] before:indent-0 before:opacity-0">
                    {tickets && tickets.map((ticket) => (
                      <Table.Tr key={ticket?.ticket_id} className="intro-x">
                        <Table.Td className="w-10 whitespace-nowrap border-x-0 shadow-[5px_3px_5px_#00000005] dark:bg-darkmode-600">
                          <FormCheck.Input type="checkbox" />
                        </Table.Td>
                        <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] dark:bg-darkmode-600 flex gap-[20px]">
                          <div>
                            <a href="#" className="whitespace-nowrap font-nunito text-[#3F4254] text-[15px] font-bold">
                              {ticket.department_name}
                            </a>
                            <div className="whitespace-nowrap dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                              {ticket.requester_name}
                            </div>
                          </div>
                        </Table.Td>
                        <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] whitespace-nowrap last:rounded-r-[0.6rem] dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                          {ticket?.title}
                        </Table.Td>
                        <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] dark:bg-darkmode-600 flex gap-[20px]">
                          <div>
                            <a href="#" className="whitespace-nowrap font-nunito text-[#3F4254] text-[15px] font-bold flex gap-2">
                              {ticket.requester_name} <Badge type="dark" text="Operador" />
                            </a>
                            <div className="whitespace-nowrap dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                              {ticket.assigned_to}
                            </div>
                          </div>
                        </Table.Td>
                        <Table.Td className="w-20 rounded-l-none rounded-r-none first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] dark:bg-darkmode-600">
                          <Badge type="success" text={ticket?.ticket_status_id} />
                        </Table.Td>
                        <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] whitespace-nowrap last:rounded-r-[0.6rem] dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                          {ticket?.updated_at}
                        </Table.Td>
                        <Table.Td className="w-38 rounded-l-none rounded-r-none first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] dark:bg-darkmode-600">
                          <button
                            className="hover:scale-105 hover:rotate-45 transition-all duration-300 ease-in-out"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleClick(e, ticket?.ticket_id);
                            }}
                          >
                            <img src={Cog} alt="Configuração" />
                          </button>
                        </Table.Td>
                      </Table.Tr>
                    ))}
                  </Table.Tbody>
                )}
              </Table>
            </Table>
          }
          contentList={tickets}
          actionsMenu={
            <>
              <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
                <p className="text-slate-400/90 dark:placeholder:text-slate-500/80">
                  {t("000099")}
                </p>
                <Lucide
                  icon="Search"
                  className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                />
              </div>

              <Button variant="primary" className="font-nunito text-[14px]" onClick={() => {
                  navigate(
                    `/tickets/create?customer_id=${customer_id}`
                  );
                }}>
                {t("000235")}
              </Button>
            </>
          }
        />
      </section>

      <MenuFloat
        active={menuFloatActive}
        closeMenuFloat={closeMenuFloat}
        positions={positions}
      >
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={() => {
            closeMenuFloat();
            navigate(
              `/tickets/${productOrderId}`
            );
          }}
        >
          <Lucide icon="Eye" className="w-4 h-4 mr-2" /> Detalhes
        </li>
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={() => {
            alert('Apaga');
          }}
        >
          <Lucide icon="Trash" className="w-4 h-4 mr-2" /> Excluir
        </li>
      </MenuFloat>
    </main>
  );
};

export default Tickets;
