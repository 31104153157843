import React from 'react';
import Badge from "../../../components/Badge";
import { t } from "i18next";

const LogLevel = Object.freeze({
  1: <Badge type="info" text={ t('000247') } />,
  2: <Badge type="warning" text={ t('000248') } />,
  3: <Badge type="danger" text={ t('000249') } />
});

export default LogLevel;