import HttpClient from "../../../services/HttpClient";

/**
 * Serviço responsável por operações relacionadas a produtos.
 */
const ProductService = {
  /**
   * Cria um novo produto no sistema.
   *
   * @async
   * @function createProduct
   * @param {Object} productData - Dados do produto a ser criado.
   * @param {string} productData.name - Nome do produto.
   * @param {string} productData.description - Descrição do produto.
   * @param {number} productData.stock - Quantidade em estoque.
   * @param {number} [productData.monthly] - Preço mensal.
   * @param {boolean} [productData.monthly_is_enabled] - Habilita preço mensal.
   * @param {number} [productData.quarterly] - Preço trimestral.
   * @param {boolean} [productData.quarterly_is_enabled] - Habilita preço trimestral.
   * @param {number} [productData.semi_annual] - Preço semestral.
   * @param {boolean} [productData.semi_annual_is_enabled] - Habilita preço semestral.
   * @param {number} [productData.annual] - Preço anual.
   * @param {boolean} [productData.annual_is_enabled] - Habilita preço anual.
   * @param {number} [productData.biennial] - Preço bienal.
   * @param {boolean} [productData.biennial_is_enabled] - Habilita preço bienal.
   * @param {number} [productData.triennial] - Preço trienal.
   * @param {boolean} [productData.triennial_is_enabled] - Habilita preço trienal.
   * @param {number} [productData.installation_fee] - Taxa de instalação.
   * @param {number} productData.price_type - Tipo de preço (1 a 3).
   * @param {number} [productData.recurrence_limit] - Limite de recorrência.
   * @param {number} [productData.single_price] - Preço único.
   * @param {number} productData.status - Status do produto (0 ou 1).
   * @param {number} [productData.generate_invoices_days_before_due_date] - Dias antes do vencimento para gerar faturas.
   * @param {number} [productData.days_until_first_invoice_due] - Dias até o primeiro vencimento da fatura.
   * @param {number} [productData.days_after_due_to_suspend] - Dias após o vencimento para suspender o produto.
   * @param {number} [productData.days_after_due_to_cancel] - Dias após o vencimento para cancelar o produto.
   * @param {number} [productData.activation_email_template_id] - ID do template de e-mail de ativação.
   * @param {number} [productData.suspension_email_template_id] - ID do template de e-mail de suspensão.
   * @param {number} [productData.cancellation_email_template_id] - ID do template de e-mail de cancelamento.
   * @param {string} [productData.url_create_webhook] - URL para criar webhook.
   * @param {number} [productData.create_webhook_type] - Tipo de webhook para criação.
   * @param {string} [productData.url_suspend_webhook] - URL para suspender webhook.
   * @param {number} [productData.suspend_webhook_type] - Tipo de webhook para suspensão.
   * @param {string} [productData.url_unsuspend_webhook] - URL para reativar webhook.
   * @param {number} [productData.unsuspend_webhook_type] - Tipo de webhook para reativação.
   * @param {string} [productData.url_cancel_webhook] - URL para cancelar webhook.
   * @param {number} [productData.cancel_webhook_type] - Tipo de webhook para cancelamento.
   * @returns {Promise<Object>} - Retorna os dados do produto criado.
   * @throws {Error} - Lança um erro se a criação do produto falhar.
   */
  createProduct: async (productData) => {
    try {
      const response = await HttpClient.post("/products", productData);
      return response.data;
    } catch (error) {
      console.error('Erro em ProductService.createProduct:', error);
      throw error;
    }
  },

  /**
   * Obtém os produtos de um cliente pelo ID do cliente.
   *
   * @async
   * @function getProductsByCustomerId
   * @param {number|string} customerId - ID do cliente cujos produtos serão consultados.
   * @returns {Promise<Object>} - Retorna um objeto com os dados dos produtos.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getProductsByCustomerId: async (customerId) => {
    try {
      const response = await HttpClient.get(`/products?customerId=${customerId}`);
      return response.data;
    } catch (error) {
      console.error('Erro em ProductService.getProductsByCustomerId:', error);
      throw error;
    }
  },

  /**
   * Obtém uma lista de produtos com base nos parâmetros fornecidos.
   *
   * @async
   * @function getProducts
   * @param {Object} params - Parâmetros para a consulta.
   * @param {number} params.limit - Número máximo de produtos a serem retornados por página.
   * @param {number} params.page - Número da página atual.
   * @param {string} params.orderBy - Campo pelo qual os resultados serão ordenados.
   * @param {string} params.orderDirection - Direção da ordenação ('asc' ou 'desc').
   * @returns {Promise<Object>} - Retorna um objeto contendo os dados dos produtos e informações de paginação.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getProducts: async ({ limit, page, orderBy, orderDirection }) => {
    let url = `/products`;
    const queryParams = [];

    if (limit != null) queryParams.push(`limit=${limit}`);
    if (page != null) queryParams.push(`page=${page}`);
    if (orderBy != null) queryParams.push(`orderBy=${orderBy}`);
    if (orderDirection != null) queryParams.push(`orderDirection=${orderDirection}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    try {
      const response = await HttpClient.get(url);
      return response.data;
    } catch (error) {
      console.error('Erro em ProductService.getProducts:', error);
      throw error;
    }
  },

  /**
   * Obtém os detalhes de um produto pelo ID.
   *
   * @async
   * @function getProductById
   * @param {number|string} productId - ID do produto a ser consultado.
   * @returns {Promise<Object>} - Retorna os dados do produto.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getProductById: async (productId) => {
    try {
      const response = await HttpClient.get(`/products/${productId}`);
      return response.data;
    } catch (error) {
      console.error('Erro em ProductService.getProductById:', error);
      throw error;
    }
  },

  /**
   * Atualiza os dados de um produto existente.
   *
   * @async
   * @function updateProduct
   * @param {number|string} productId - ID do produto a ser atualizado.
   * @param {Object} updateData - Dados a serem atualizados no produto.
   * @returns {Promise<Object>} - Retorna os dados do produto atualizado.
   * @throws {Error} - Lança um erro se a atualização falhar.
   */
  updateProduct: async (productId, updateData) => {
    try {
      const response = await HttpClient.put(`/products/${productId}`, updateData);
      return response.data;
    } catch (error) {
      console.error('Erro em ProductService.updateProduct:', error);
      throw error;
    }
  },

  /**
   * Remove um produto do sistema pelo ID.
   *
   * @async
   * @function deleteProduct
   * @param {number|string} productId - ID do produto a ser removido.
   * @returns {Promise<Object>} - Retorna a confirmação da remoção.
   * @throws {Error} - Lança um erro se a remoção falhar.
   */
  deleteProduct: async (productId) => {
    try {
      const response = await HttpClient.delete(`/products/${productId}`);
      return response.data;
    } catch (error) {
      console.error('Erro em ProductService.deleteProduct:', error);
      throw error;
    }
  }
};

export default ProductService;
