import Lucide from "../components/Base/Lucide";
import {
  Outlet,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams
} from "react-router-dom";
import { Tabs } from "antd";
import Badge from "../components/Badge";
import Header from "../pages/Header";
import { useEffect, useState } from "react";
import CustomerService from "../pages/Customers/Services/CustomerService";
import { toast } from "react-toastify";
// import AvatarCNPJ from "@/assets/images/Avatar.png";
import EditPhotoModal from "../pages/Sumary/Modals/EditPhotoModal";
import useCurrentPathReactRouterDom from "../utils/hooks/useCurrentPathReactRouterDom";

const subPagesRoutes = [
  "",
  "/profile",
  "/orders",
  "/invoices",
  "/tickets",
  "/notes",
  "/emails",
  "/logs",
];

const navActiveNumber = {
  0: ["/customers/:customer_id"],
  1: ["/customers/:customer_id/profile"],
  2: [
    "/customers/:customer_id/orders",
    "/customers/:customer_id/orders/:order_id",
  ],
  3: ["/customers/:customer_id/faturas", "/customers/:customer_id/faturas/:idFatura"],
  4: ["/customers/:customer_id/tickets"],
  5: ["/customers/:customer_id/notas"],
  6: ["/customers/:customer_id/e-mails"],
  7: ["/customers/:customer_id/logs"],
};

const BaseSecondary = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({});
  const currentPath = useCurrentPathReactRouterDom();
  const [photoModal, setPhotoModal] = useState(false);
  const { customer_id } = useParams();

  function getNavActiveNumber(pathname) {
    for (const key in navActiveNumber) {
      for (const route of navActiveNumber[key]) {
        console.log(key);
        if (pathname === route) return key;
      }
    }
  }

  useEffect(() => {
    (async () => {
      try {
        const res = await CustomerService.findCustomer(customer_id);
        setCustomer(res.data);
      } catch (error) {
        // navigate("/customers");
        toast.error("Cliente não encontrado!");
      }
    })();
  }, []);

  return (
    <div>
      <Header menuType="admin" />

      <section className="bg-[#17191E] min-h-[130px] px-5 ">
        <div className="container h-full pt-[15px]">
          <div className="h-full px-5">
            <div className="flex gap-2 flex items-center">
              <span className="text-[#5E6278] font-inter font-semibold text-[13px] leading-[14px] ">
                Clientes
              </span>
              <span className="text-[#5E6278] font-inter font-semibold text-[13px] leading-[14px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M0.893444 9.66664C0.761028 9.66741 0.631381 9.62873 0.521035 9.55553C0.410689 9.48233 0.324641 9.37793 0.273864 9.25563C0.223086 9.13334 0.209878 8.99869 0.235925 8.86886C0.261971 8.73903 0.326091 8.61989 0.420111 8.52664L3.90011 4.99998L0.420111 1.55331C0.357952 1.49115 0.308644 1.41736 0.275004 1.33614C0.241364 1.25493 0.224049 1.16788 0.224049 1.07998C0.224049 0.992072 0.241364 0.905026 0.275004 0.823812C0.308644 0.742597 0.357952 0.668804 0.420111 0.606645C0.48227 0.544486 0.556063 0.495179 0.637278 0.461538C0.718492 0.427898 0.805538 0.410583 0.893444 0.410583C0.98135 0.410583 1.0684 0.427898 1.14961 0.461538C1.23082 0.495179 1.30462 0.544486 1.36678 0.606645L5.33344 4.55998C5.45761 4.68489 5.52731 4.85385 5.52731 5.02998C5.52731 5.2061 5.45761 5.37507 5.33344 5.49998L1.38678 9.44664C1.32445 9.51571 1.24835 9.57096 1.16339 9.60885C1.07843 9.64674 0.986473 9.66642 0.893444 9.66664Z"
                    fill="#5E6278"
                  />
                </svg>
              </span>
              <span className="text-[#5E6278] font-inter font-semibold text-[13px] leading-[14px] ">
                Sumário
              </span>
              <span className="text-[#5E6278] font-inter font-semibold text-[13px] leading-[14px]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="6"
                  height="10"
                  viewBox="0 0 6 10"
                  fill="none"
                >
                  <path
                    d="M0.893444 9.66664C0.761028 9.66741 0.631381 9.62873 0.521035 9.55553C0.410689 9.48233 0.324641 9.37793 0.273864 9.25563C0.223086 9.13334 0.209878 8.99869 0.235925 8.86886C0.261971 8.73903 0.326091 8.61989 0.420111 8.52664L3.90011 4.99998L0.420111 1.55331C0.357952 1.49115 0.308644 1.41736 0.275004 1.33614C0.241364 1.25493 0.224049 1.16788 0.224049 1.07998C0.224049 0.992072 0.241364 0.905026 0.275004 0.823812C0.308644 0.742597 0.357952 0.668804 0.420111 0.606645C0.48227 0.544486 0.556063 0.495179 0.637278 0.461538C0.718492 0.427898 0.805538 0.410583 0.893444 0.410583C0.98135 0.410583 1.0684 0.427898 1.14961 0.461538C1.23082 0.495179 1.30462 0.544486 1.36678 0.606645L5.33344 4.55998C5.45761 4.68489 5.52731 4.85385 5.52731 5.02998C5.52731 5.2061 5.45761 5.37507 5.33344 5.49998L1.38678 9.44664C1.32445 9.51571 1.24835 9.57096 1.16339 9.60885C1.07843 9.64674 0.986473 9.66642 0.893444 9.66664Z"
                    fill="#5E6278"
                  />
                </svg>
              </span>
              <span className="text-white font-inter text-[13px] font-semibold leading-[14px]">
                  Visão Geral
                </span>
            </div>
          </div>
        </div>
      </section>

      <div className="container px-5 ">
        <main className="translate-y-[-74px] min-h-[100px] rounded-[10px]">
          <div className="font-nunito bg-white rounded-xl p-5 pb-0 mb-4 shadow-custom-1">
            <section className="flex flex-col md:flex-row justify-center md:justify-start items-center gap-4 xl:flex-row xl:justify-start">
              <div
                className="relative group cursor-pointer"
                onClick={() => setPhotoModal(true)}
              >
                {customer?.photo_name ? (
                  <img
                    src={`${
                      import.meta.env.VITE_REACT_APP_API_URL
                    }/images/${customer.photo_name}`}
                    className="h-[60px] rounded-xl object-cover w-[60px]"
                  />
                ) : (
                  <div className="h-[60px] rounded-xl object-cover w-[60px] bg-slate-200 flex items-center justify-center">
                    <Lucide
                      icon={customer.type === "PJ" ? "Building" : "User"}
                      className="w-10 h-10 text-slate-400"
                    />
                  </div>
                )}
                <div className="transition-all opacity-0 group-hover:opacity-100 absolute rounded-xl inset-0 w-100 h-100 bg-black/30 z-9999 flex items-center justify-center">
                  <Lucide
                    icon="Pencil"
                    className="w-8 h-8 text-slate-400 text-white"
                  />
                </div>
              </div>

              <div className="flex flex-col gap-[5px]">
                <div className="flex items-center">
                  <h6 className="text-[#181C32] text-[20px] mr-[10px] font-bold">
                    {customer?.name}
                  </h6>
                  <Badge
                    type="success"
                    text={`#${customer?.id}`}
                    className="h-[26px]"
                  />
                </div>

                <div className="text-[#A1A5B7] flex flex-col gap-3 md:flex-row">
                  <p className="flex items-center gap-1 text-[14px]">
                    <Lucide icon="User" className="w-5 h-5" />
                    Cliente
                  </p>
                  <p className="flex items-center gap-1 text-[14px]">
                    <Lucide icon="MapPin" className="w-5 h-5" />
                    {customer?.address?.city
                      ? customer?.address?.city
                      : "Não informado"}
                  </p>
                  <p className="flex items-center gap-1 text-[14px]">
                    <Lucide icon="Mail" className="w-5 h-5" />
                    {customer?.email}
                  </p>
                </div>
              </div>
            </section>
            <nav className="block gap-2 overflow-hidden mt-4">
              <Tabs
                defaultActiveKey={getNavActiveNumber(currentPath)}
                items={[
                  {
                    key: "0",
                    label: "Visão geral",
                  },
                  {
                    key: "1",
                    label: "Perfil",
                  },
                  {
                    key: "2",
                    label: "Produtos/Serviços",
                  },
                  {
                    key: "3",
                    label: "Faturas",
                  },
                  {
                    key: "4",
                    label: "Tickets",
                  },
                  {
                    key: "5",
                    label: "Notas",
                  },
                  {
                    key: "6",
                    label: "E-mails",
                  },
                  {
                    key: "7",
                    label: "Logs",
                  },
                ]}
                className="p-0 mt-4"
                onChange={(id) => {
                  navigate("/customers/" + customer_id + subPagesRoutes[+id]);
                }}
              />
            </nav>
          </div>

          <Outlet context={{ customer, setCustomer, customer_id }} />
        </main>
      </div>

      <EditPhotoModal
        customer_id={customer_id}
        setCustomer={setCustomer}
        customer={customer}
        isModalOpen={photoModal}
        closeModal={() => setPhotoModal(false)}
      />
    </div>
  );
};

export const useCustomer = () => {
  return useOutletContext();
};

export default BaseSecondary;
