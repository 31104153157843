import Lucide from "../../../components/Base/Lucide";
import Table from "../../../components/Base/Table";
import { useNavigate } from "react-router-dom";
import Cog from "../../../assets/images/cog2.svg";
import clsx from "clsx";
import MenuFloat from "../../../components/MenuFloat";
import { useEffect, useState } from "react";
import { useCustomer } from "../../../layout/BaseSecondary";
import {
  formatDate,
  getPositionsMenuFloat,
} from "../../../utils/helpers";
import ListBase from "../../../layout/ListBase";
import { t } from "i18next";
import LogsModal from "./components/LogsModal";
import LogService from "../../../pages/Logs/Services/LogService";
import LogLevel from "../../../pages/Logs/Enums/LogLevel";

/**
 * Logs Component
 *
 * This component displays a list of logs associated with a customer.
 */
const Logs = () => {
  // Hooks
  const [isLogsModalActive, setLogsModalActive] = useState(false);
  const navigate = useNavigate();
  const [menuFloatActive, setMenuFloatActive] = useState(false);
  const [logId, setLogId] = useState(null);
  const [positions, setPositions] = useState({ x: 0, y: 0 });  
  const { customer_id } = useCustomer();
  const [logs, setLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  // Close the menu float
  const closeMenuFloat = () => {
    setLogId(null);
    setMenuFloatActive(false);
  };

  // Handle page change in pagination
  const getOrdersLogsOnHandlePage = async (newPage) => {
    const logs = await LogService.getLogs({
      limit: limit,
      page: page,
      customer_id: customer_id,
    });
    setLogs(logs);
  };

  // Handle click event for menu float
  const handleClick = (e, idProductOrder) => {
    setLogId(idProductOrder);
    setPositions(getPositionsMenuFloat(e, 20));
    setMenuFloatActive(true);
  };

  // Fetch orders logs on component mount
  useEffect(() => {
    const fetchLogs = async () => {
      const logs = await LogService.getLogs({
        limit: limit,
        page: page,
        customer_id: customer_id,
      });
      setLogs(logs);
      setIsLoading(false);
    };

    fetchLogs();
  }, [customer_id]);

  return (
    <main>
      <section className="md:min-w-[460px] bg-white rounded-xl font-nunito shadow-custom-1">
        <ListBase
          filters={<></>}
          getContentListOnPageChange={getOrdersLogsOnHandlePage}
          isFilterActive={false}
          setIsLoading={setIsLoading}
          tableEmptyMsg={ t('000250') }
          title="Logs"
          headerType="B"
          isLoading={isLoading}
          table={
            <Table hover={true}>
              <Table.Thead>
                <Table.Tr className="bg-[#F9F9F9] h-[35px] pb-2">
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold rounded-l">
                    { t('000101') }
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    { t('000251') }
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    { t('000252') }
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    { t('000253') }
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    { t('000254') }
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    { t('000255') }
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito text-center font-semibold rounded-r">
                    { t('000108') }
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>
                {logs.data && logs.data.map((log) => (
                  <Table.Tr
                    key={log.log_id}
                    className="intro-x"
                    onClick={(e) => {
                      e.stopPropagation();
                      setLogsModalActive(true);
                    }}
                  >
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {log.log_id}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {log.message}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {LogLevel[log.level]}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {log.user_id}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {log.customer_id ?? 'N/A'}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {formatDate(log.created_at, "DD/MM/YYYY")}
                    </Table.Td>
                    <Table.Td
                      className={clsx(
                        "w-38 text-center relative z-50 dark:bg-darkmode-600"
                      )}
                    >
                      <button
                        className="hover:scale-105 hover:rotate-45 transition-all duration-300 ease-in-out"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(e, log.log_id);
                        }}
                      >
                        <img src={Cog} alt="Configuração" />
                      </button>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>

          }
          contentList={logs}
          setPage={setPage}
          actionsMenu={
            <>
              <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
                <p className="text-slate-400/90 dark:placeholder:text-slate-500/80">
                  {t("000099")}
                </p>
                <Lucide
                  icon={ t('000099') }
                  className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                />
              </div>
            </>
          }
        />
      </section>

      <MenuFloat
        active={menuFloatActive}
        closeMenuFloat={closeMenuFloat}
        positions={positions}
      >
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={(e) => {
            closeMenuFloat();
            e.stopPropagation();
            setLogsModalActive(true);
          }}
        >
          <Lucide icon="Eye" className="w-4 h-4 mr-2" /> { t('000256') }
        </li>
      </MenuFloat>

      <LogsModal
        open={isLogsModalActive}
        closeModal={() => setLogsModalActive(false)}
      />
    </main>
  );
};

export default Logs;
