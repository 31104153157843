import HttpClient from "../../../services/HttpClient";

/**
 * Serviço responsável por operações relacionadas a clientes.
 */
const CustomerService = {
  /**
   * Obtém os detalhes de um cliente pelo ID.
   *
   * @async
   * @function getCustomerById
   * @param {number|string} customerId - ID do cliente a ser consultado.
   * @returns {Promise<Object>} - Retorna um objeto com os dados do cliente.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getCustomerById: async (customerId) => {
    try {
      const response = await HttpClient.get(`/customers/${customerId}`);
      return response.data;
    } catch (error) {
      console.error('Erro em CustomerService.getCustomerById:', error);
      throw error;
    }
  },

  /**
   * Obtém uma lista de clientes com base nos parâmetros fornecidos.
   *
   * @async
   * @function getCustomers
   * @param {Object} params - Parâmetros para a consulta.
   * @param {number} params.limit - Número máximo de clientes a serem retornados por página.
   * @param {number} params.page - Número da página atual.
   * @param {string} params.orderBy - Campo pelo qual os resultados serão ordenados.
   * @param {string} params.orderDirection - Direção da ordenação ('asc' ou 'desc').
   * @param {Object} [params.filters] - Filtros opcionais para a consulta (nome, email, etc.).
   * @returns {Promise<Object>} - Retorna um objeto contendo os dados dos clientes e informações de paginação.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getCustomers: async ({ limit, page, orderBy, orderDirection }) => {
    let url = `/customers`;
    const queryParams = [];

    if (limit != null) queryParams.push(`limit=${limit}`);
    if (page != null) queryParams.push(`page=${page}`);
    if (orderBy != null) queryParams.push(`orderBy=${orderBy}`);
    if (orderDirection != null) queryParams.push(`orderDirection=${orderDirection}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    try {
      const response = await HttpClient.get(url);
      return response.data;
    } catch (error) {
      console.error('Erro em CustomerService.getCustomers:', error);
      throw error;
    }
  },

  /**
   * Cria um novo cliente no sistema.
   *
   * @async
   * @function createCustomer
   * @param {Object} customerData - Dados do cliente a ser criado.
   * @param {string} customerData.name - Nome do cliente.
   * @param {string} customerData.email - E-mail do cliente.
   * @param {string} [customerData.business_name] - Nome da empresa do cliente (se aplicável).
   * @param {string} [customerData.cpf_cnpj] - CPF ou CNPJ do cliente.
   * @returns {Promise<Object>} - Retorna os dados do cliente criado.
   * @throws {Error} - Lança um erro se a criação do cliente falhar.
   */
  createCustomer: async (customerData) => {
    try {
      const response = await HttpClient.post("/customers", customerData);
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Cria um novo endereço para um cliente existente.
   *
   * @async
   * @function createAddress
   * @param {number|string} customerId - ID do cliente para o qual o endereço será adicionado.
   * @param {Object} addressData - Dados do endereço a ser criado.
   * @param {string} addressData.street - Rua do endereço.
   * @param {string} addressData.number - Número do endereço.
   * @param {string} addressData.city - Cidade do endereço.
   * @param {string} addressData.state - Estado do endereço.
   * @param {string} addressData.zipcode - CEP do endereço.
   * @returns {Promise<Object>} - Retorna os dados do endereço criado.
   * @throws {Error} - Lança um erro se a criação do endereço falhar.
   */
  createAddress: async (customerId, addressData) => {
    try {
      const response = await HttpClient.post(
        `/customers/${customerId}/address`,
        addressData
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  /**
   * Exclui um cliente existente pelo ID.
   *
   * @async
   * @function deleteCustomer
   * @param {number|string} customerId - ID do cliente a ser excluído.
   * @returns {Promise<Object>} - Retorna os dados da resposta após a exclusão.
   * @throws {Error} - Lança um erro se a exclusão falhar.
   */
  deleteCustomer: async (customerId) => {
    try {
      // Faz a requisição DELETE para excluir o cliente especificado
      const response = await HttpClient.delete(`/customers/${customerId}`);
      return response.data; // Retorna os dados da resposta
    } catch (error) {
      console.error('Erro em CustomerService.deleteCustomer:', error);
      throw error;
    }
  },
};

export default CustomerService;
