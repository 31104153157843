import HttpClient from "../../../services/HttpClient";

/**
 * Serviço responsável por operações relacionadas a tickets.
 */
const TicketService = {
  /**
   * Obtém os detalhes de um ticket pelo ID.
   *
   * @async
   * @function getTicketById
   * @param {number|string} ticketId - ID do ticket a ser consultado.
   * @returns {Promise<Object>} - Retorna um objeto com os dados do ticket.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getTicketById: async (ticket_id) => {

    try {
      const response = await HttpClient.get(`/tickets/${ticket_id}`);
      return response.data;
    } catch (error) {
      console.error('Erro em TicketService.getTicketById:', error);
      throw error;
    }
  },

  /**
   * Obtém uma lista de tickets com base nos parâmetros fornecidos.
   *
   * @async
   * @function getTickets
   * @param {Object} params - Parâmetros para a consulta.
   * @param {number} params.limit - Número máximo de tickets a serem retornados por página.
   * @param {number} params.page - Número da página atual.
   * @param {string} params.orderBy - Campo pelo qual os resultados serão ordenados.
   * @param {string} params.orderDirection - Direção da ordenação ('asc' ou 'desc').
   * @param {Object} [params.filters] - Filtros opcionais para a consulta (ex.: status, prioridade, etc.).
   * @returns {Promise<Object>} - Retorna um objeto contendo os dados dos tickets e informações de paginação.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getTickets: async ({ limit, page, orderBy, orderDirection, filters }) => {
    let filterUrl = '';

    if (filters && Object.keys(filters).length > 0) {
      filterUrl =
        '&' +
        Object.keys(filters)
          .reduce((url, key) => {
            if (filters[key] !== '') {
              return (url += `${key}=${encodeURIComponent(filters[key])}&`);
            }
            return url;
          }, '')
          .slice(0, -1);
    }

    const url = `/tickets?limit=${limit}&page=${page}&orderBy=${orderBy}&orderDirection=${orderDirection}${filterUrl}`;

    try {
      const response = await HttpClient.get(url);
      return response.data;
    } catch (error) {
      console.error('Erro em TicketService.getTickets:', error);
      throw error;
    }
  },

  /**
   * Obtém as mensagens de um ticket específico com base nos parâmetros fornecidos.
   *
   * @async
   * @function getTicketMessages
   * @param {Object} params - Parâmetros para a consulta.
   * @param {number|string} params.ticket_id - ID do ticket a ser consultado.
   * @param {number} params.limit - Número máximo de mensagens a serem retornadas por página.
   * @param {number} params.page - Número da página atual.
   * @param {string} params.orderBy - Campo pelo qual os resultados serão ordenados.
   * @param {string} params.orderDirection - Direção da ordenação ('asc' ou 'desc').
   * @returns {Promise<Object>} - Retorna um objeto contendo os dados das mensagens e informações de paginação.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getTicketMessages: async ({ ticket_id, limit, page, orderBy, orderDirection }) => {
    let url = `/tickets/${ticket_id}/messages`;
    const queryParams = [];

    if (limit != null) queryParams.push(`limit=${limit}`);
    if (page != null) queryParams.push(`page=${page}`);
    if (orderBy != null) queryParams.push(`orderBy=${orderBy}`);
    if (orderDirection != null) queryParams.push(`orderDirection=${orderDirection}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    try {
      const response = await HttpClient.get(url);
      return response.data;
    } catch (error) {
      console.error('Erro em TicketService.getTicketMessages:', error);
      throw error;
    }
  },  

  /**
   * Obtém uma lista de tickets associados a um cliente específico com base nos parâmetros fornecidos.
   *
   * @async
   * @function getTicketsByCustomerId
   * @param {Object} params - Parâmetros para a consulta.
   * @param {number} params.limit - Número máximo de tickets a serem retornados por página.
   * @param {number} params.page - Número da página atual.
   * @param {string} params.orderBy - Campo pelo qual os resultados serão ordenados.
   * @param {string} params.orderDirection - Direção da ordenação ('asc' ou 'desc').
   * @param {number|string} customerId - ID do cliente ao qual os tickets estão associados.
   * @param {Object} [params.filters] - Filtros opcionais para a consulta (ex.: status, prioridade, etc.).
   * @returns {Promise<Object>} - Retorna um objeto contendo os dados dos tickets e informações de paginação.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getTicketsByCustomerId: async ({ limit, page, orderBy, orderDirection, customerId, filters }) => {
    let filterUrl = '';

    if (filters && Object.keys(filters).length > 0) {
      filterUrl =
        '&' +
        Object.keys(filters)
          .reduce((url, key) => {
            if (filters[key] !== '') {
              return (url += `${key}=${encodeURIComponent(filters[key])}&`);
            }
            return url;
          }, '')
          .slice(0, -1);
    }

    const url = `/tickets?limit=${limit}&page=${page}&orderBy=${orderBy}&orderDirection=${orderDirection}&customerId=${customerId}${filterUrl}`;

    try {
      const response = await HttpClient.get(url);
      return response.data;
    } catch (error) {
      console.error('Erro em TicketService.getTicketsByCustomerId:', error);
      throw error;
    }
  },
};

export default TicketService;
