import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import HttpClient from '../../../services/HttpClient';
import TokenService from '../../../services/TokenService';
import { Menu } from '@headlessui/react'; // Certifique-se de que está importando o Menu correto
import FormSelect from '../../Base/Form/FormSelect'; // Certifique-se de que o caminho está correto
import { ChevronDownIcon } from '@heroicons/react/solid'; // Ícone para indicar o dropdown

/**
 * Função para mudar o idioma
 */
const changeLanguage = async (lng) => {
  i18n.changeLanguage(lng);

  const token = TokenService.getAccessToken();
  const userId = TokenService.getUserId();

  if (token && userId) {
    try {
      // Faz a chamada PUT para atualizar o idioma do usuário
      await HttpClient.put(`/users/${userId}`, {
        language: lng,
      });
      console.log(`Idioma atualizado para: ${lng}`);
      TokenService.setLanguage(lng);
      window.location.reload();
    } catch (error) {
      console.error('Erro ao atualizar o idioma:', error);
    }
  } else {
    // Se não houver token ou userId, ainda atualiza o idioma localmente
    TokenService.setLanguage(lng);
    window.location.reload();
  }
};


export const LanguageDropdown = () => {
  const { t } = useTranslation();
  const language = TokenService.getLanguage() || i18n.language;

  // Função para mudar o idioma
  const changeLanguage = async (lng) => {
    i18n.changeLanguage(lng);

    const token = TokenService.getAccessToken();
    const userId = TokenService.getUserId();

    if (token && userId) {
      try {
        // Faz a chamada PUT para atualizar o idioma do usuário
        await HttpClient.put(`/users/${userId}`, {
          language: lng,
        });
        console.log(`Idioma atualizado para: ${lng}`);
        TokenService.setLanguage(lng);
        window.location.reload();
        // Forçar re-renderização sem recarregar a página
      } catch (error) {
        console.error('Erro ao atualizar o idioma:', error);
      }
    } else {
      // Se não houver token ou userId, ainda atualiza o idioma localmente
      TokenService.setLanguage(lng);
      window.location.reload();
    }
  };

  // Objeto para mapear códigos de idioma para nomes e bandeiras
  const languages = [
    { code: 'en', name: t('English'), flag: '🇺🇸' },
    { code: 'es', name: t('Español'), flag: '🇪🇸' },
    { code: 'pt-br', name: t('Português'), flag: '🇧🇷' },
  ];

  // Obter o idioma atual
  const currentLanguage = languages.find((lang) => lang.code === language) || languages[0];

  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex items-centertext-sm">
        {/* {currentLanguage.flag}  */}
        <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1 text-gray-400" />
        {currentLanguage.name}
        
      </Menu.Button>

      <Menu.Items
        className="absolute right-0 mt-2 w-40 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none"
      >
        {languages.map((lang) => (
          <Menu.Item key={lang.code}>
            {({ active }) => (
              <button
                className={`${
                  active ? 'bg-gray-100' : ''
                } flex items-center w-full px-4 py-2 text-sm text-gray-700`}
                onClick={() => changeLanguage(lang.code)}
              >
                {lang.flag} {lang.name}
              </button>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

// coloca o dropdown aqui

/**
 * Componente para selecionar o idioma da aplicação.
 */
export const LanguageSelector = () => {
  const { t } = useTranslation();
  const language = TokenService.getLanguage() || i18n.language;

  return (
    <div className="fixed bottom-0 right-0 p-4">
      <FormSelect
        onChange={(e) => changeLanguage(e.target.value)}
        defaultValue={language}
      >
        <option value="en">🇺🇸 {t('English')}</option>
        <option value="es">🇪🇸 {t('Español')}</option>
        <option value="pt-br">🇧🇷 {t('Português')}</option>
      </FormSelect>
    </div>
  );
};
