// Importar módulos e componentes necessários
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Badge from "../../../components/Badge";
import Lucide from "../../../components/Base/Lucide";
import Table from "../../../components/Base/Table";
import Button from "../../../components/Base/Button";
import { FormCheck } from "../../../components/Base/Form";
import MenuFloat from "../../../components/MenuFloat";
import InvoiceService from "../../../pages/Invoices/Services/InvoiceService";
import { formatDate, formatToBRL, getPositionsMenuFloat } from "../../../utils/helpers";
import ListBase from "../../../layout/ListBase";
import Cog from "../../../assets/images/cog2.svg";

/**
 * Componente Invoices
 *
 * Este componente exibe uma lista de faturas associadas a um cliente.
 */
const Invoices = () => {
  // Hooks e estados
  const [positions, setPositions] = useState({ x: 0, y: 0 });
  const { customer_id } = useParams();
  const [invoices, setInvoices] = useState([]); // Lista de faturas
  const [isLoading, setIsLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(null); // Estado para erros
  const navigate = useNavigate();
  const [invoiceId, setInvoiceId] = useState(null); // ID da fatura selecionada
  const [menuFloatActive, setMenuFloatActive] = useState(false); // Estado do menu flutuante
  const { t } = useTranslation();

  // Função auxiliar para buscar faturas
  const fetchInvoices = async (pageNumber = 1) => {
    setIsLoading(true);
    try {
      const response = await InvoiceService.getInvoicesByCustomerId(customer_id);
      setInvoices(response); // Ajuste conforme o formato da resposta
    } catch (error) {
      console.error("Erro ao carregar faturas:", error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Função para fechar o menu flutuante
  const closeMenuFloat = () => {
    setInvoiceId(null);
    setMenuFloatActive(false);
  };

  // Função para lidar com o clique no menu flutuante
  const handleClick = (e, idInvoice) => {
    setInvoiceId(idInvoice);
    setPositions(getPositionsMenuFloat(e, 20));
    setMenuFloatActive(true);
  };

  // Buscar faturas ao montar o componente
  useEffect(() => {
    fetchInvoices();
  }, [customer_id]);

  // Lidar com a mudança de página na paginação
  const contentListOnPageChange = (newPage) => {
    fetchInvoices(newPage);
  };

  // Função auxiliar para determinar o tipo do Badge
  const getTypeBadge = (status) => {
    switch (status) {
      case "Não paga":
        return "warning";
      case "Paga":
        return "success";
      case "Cancelada":
        return "danger";
      case "Estornada":
        return "suspended";
      default:
        return "info";
    }
  };

  return (
    <main>
      <section className="md:min-w-[460px] bg-white rounded-xl font-nunito shadow-custom-1">
        <ListBase
          title="Faturas"
          headerType="B"
          filters={<></>}
          getContentListOnPageChange={contentListOnPageChange}
          isFilterActive={false}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          contentList={invoices}
          tableEmptyMsg="Não há nenhuma fatura associada a este cliente."
          table={
            <Table hover={true}>
              <Table.Thead>
                <Table.Tr className="bg-[#F9F9F9] h-[35px] pb-2">
                  <Table.Th className="border-b-0 whitespace-nowrap rounded-l">
                    <FormCheck.Input type="checkbox" />
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    N° da Fatura
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Data da Geração
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Vencimento da Fatura
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Data de Pagamento
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Total
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Método de Pagamento
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Status
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito text-center font-semibold rounded-r">
                    Ações
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              {!isLoading && (
                <Table.Tbody>
                  {invoices.data && invoices.data.map((invoice) => (
                    <Table.Tr
                      key={invoice.id}
                      className="intro-x"
                      onClick={() => {
                        navigate(`/clientes/${customer_id}/faturas/${invoice.id}`);
                      }}
                    >
                      <Table.Td className="w-10 whitespace-nowrap border-x-0 shadow-[5px_3px_5px_#00000005] dark:bg-darkmode-600">
                        <FormCheck.Input type="checkbox" />
                      </Table.Td>
                      <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                        {invoice.id}
                      </Table.Td>
                      <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                        {formatDate(invoice.created_at, "DD/MM/YYYY")}
                      </Table.Td>
                      <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                        {formatDate(invoice.due, "DD/MM/YYYY")}
                      </Table.Td>
                      <Table.Td className="dark:bg-darkmode-600 whitespace-nowrap font-nunito text-[#7E8299] text-[14px] font-normal">
                        {formatDate(invoice.paid_at, "DD/MM/YYYY")}
                      </Table.Td>
                      <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                        {/* {formatToBRL(
                          invoice.invoices_items.reduce(
                            (acc, item) => acc + Number(item.price),
                            0
                          )
                        )} */}
                      </Table.Td>
                      <Table.Td className="w-20 dark:bg-darkmode-600 text-[#7E8299] font-normal">
                        {invoice.payment_method}
                      </Table.Td>
                      <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                        <Badge type={getTypeBadge(invoice.status)} text={invoice.status} />
                      </Table.Td>
                      <Table.Td className="w-38 dark:bg-darkmode-600">
                        <button
                          className="hover:scale-105 hover:rotate-45 transition-all duration-300 ease-in-out"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClick(e, invoice.id);
                          }}
                        >
                          <img src={Cog} alt="Configuração" />
                        </button>
                      </Table.Td>
                    </Table.Tr>
                  ))}
                </Table.Tbody>
              )}
            </Table>
          }
          actionsMenu={
            <>
              <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
                <p className="text-slate-400/90 dark:placeholder:text-slate-500/80">
                  {t("000099")}
                </p>
                <Lucide
                  icon="Search"
                  className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                />
              </div>
              <Button variant="primary" className="font-nunito text-[14px]" onClick={() => {
                navigate(`/invoices/create?customer_id=${customer_id}`);
              }}>
                {t("000233")}
              </Button>
            </>
          }
        />
      </section>

      <MenuFloat
        active={menuFloatActive}
        closeMenuFloat={closeMenuFloat}
        positions={positions}
      >
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={() => {
            // Implementar ação para baixar fatura
            closeMenuFloat();
            alert('Baixar fatura');
          }}
        >
          <Lucide icon="Download" className="w-4 h-4 mr-2" />
          Baixar fatura
        </li>
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={() => {
            // Implementar ação para reabrir fatura
            closeMenuFloat();
            alert('Reabrir fatura');
          }}
        >
          <Lucide icon="ArrowLeft" className="w-4 h-4 mr-2" />
          Reabrir Fatura
        </li>
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={() => {
            // Implementar ação para cancelar fatura
            closeMenuFloat();
            alert('Cancelar fatura');
          }}
        >
          <Lucide icon="X" className="w-4 h-4 mr-2" />
          Cancelar Fatura
        </li>
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={() => {
            // Implementar ação para deletar fatura
            closeMenuFloat();
            alert('Deletar fatura');
          }}
        >
          <Lucide icon="Trash" className="w-4 h-4 mr-2" />
          Deletar Fatura
        </li>
      </MenuFloat>
    </main>
  );
};

export default Invoices;
