import { useEffect, useRef } from 'react';
import { useIsomorphicLayoutEffect } from '../hooks/useIsomorphicLayoutEffect';

function useEventListener(eventName, handler, element, options) {
  // Cria uma referência que armazena o handler
  const savedHandler = useRef(handler);

  useIsomorphicLayoutEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(() => {
    // Define o alvo de escuta
    const targetElement = element?.current ?? window;

    if (!(targetElement && targetElement.addEventListener)) return;

    // Cria um listener de eventos que chama a função handler armazenada na ref
    const listener = (event) => {
      savedHandler.current(event);
    };

    targetElement.addEventListener(eventName, listener, options);

    // Remove o listener de evento no cleanup
    return () => {
      targetElement.removeEventListener(eventName, listener, options);
    };
  }, [eventName, element, options]);
}

export { useEventListener };
