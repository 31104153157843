import HttpClient from "../../../../services/HttpClient";

/**
 * Serviço responsável por operações relacionadas à fila de e-mails.
 */
const EmailService = {
  /**
   * Lista e-mails na fila com paginação.
   *
   * @async
   * @function listEmails
   * @param {Object} params Parâmetros para consulta dos e-mails.
   * @param {number} params.limit Número máximo de registros por página.
   * @param {number} params.page Número da página atual.
   * @param {string} [params.orderBy] Campo para ordenar os resultados.
   * @param {string} [params.orderDirection] Direção da ordenação ('asc' ou 'desc').
   * @param {string} [params.status] Filtra por status do e-mail ('pending', 'sent', etc.).
   * @returns {Promise<Object>} Retorna os dados dos e-mails e informações de paginação.
   * @throws {Error} Lança um erro se a requisição falhar.
   */
  listEmails: async ({ limit, page, orderBy, orderDirection, status, customer_id }) => {
    let url = "/email_queues";
    const queryParams = [];

    if (limit != null) queryParams.push(`limit=${limit}`);
    if (page != null) queryParams.push(`page=${page}`);
    if (orderBy) queryParams.push(`orderBy=${orderBy}`);
    if (orderDirection) queryParams.push(`orderDirection=${orderDirection}`);
    if (status) queryParams.push(`status=${status}`);
    if (customer_id != null) queryParams.push(`customerId=${customer_id}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join("&")}`;
    }

    try {
      const response = await HttpClient.get(url);
      return response.data;
    } catch (error) {
      console.error("Erro em EmailService.listEmails:", error);
      throw error;
    }
  },

  /**
   * Obtém os detalhes de um e-mail específico.
   *
   * @async
   * @function getEmailById
   * @param {number} emailId ID do e-mail.
   * @returns {Promise<Object>} Retorna os dados do e-mail.
   * @throws {Error} Lança um erro se a requisição falhar.
   */
  getEmailById: async (emailId) => {
    try {
      const response = await HttpClient.get(`/email_queues/${emailId}`);
      return response.data;
    } catch (error) {
      console.error("Erro em EmailService.getEmailById:", error);
      throw error;
    }
  },
};

export default EmailService;
