import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import Badge from "../../components/Badge";
import Button from "../../components/Base/Button";
import { FormCheck } from "../../components/Base/Form";
import Lucide from "../../components/Base/Lucide";
import BrlCurrencyComponent from "../../components/Form/BrlCurrencyComponent";
import SelectBox from "../../components/Form/SelectBox";
import LoadingIcon from "../../components/Base/LoadingIcon";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import clsx from "clsx";
import { formatToBRL } from "../../utils/helpers";
import CustomerSelect from "../../pages/Customers/components/CustomerSelect";
import ProductService from "../../pages/Products/Services/ProductService";

// Ciclos periódicos padronizados
export const periodicals = ["monthly", "quarterly", "semiannual", "yearly", "biennial", "triennial"];
export const periodicals_br = ["Mensal", "Trimestral", "Semestral", "Anual", "Bienal", "Trienal"];

// Dados fake para teste
const fakeCustomers = [
  { id: 1, name: "Cliente 1", payment_method_id: 1 },
  { id: 2, name: "Cliente 2", payment_method_id: 2 },
  { id: 3, name: "Cliente 3", payment_method_id: 1 },
];

const fakeCoupons = [
  { id: 1, name: "Desconto 10%", code: "DESC10" },
  { id: 2, name: "Desconto 20%", code: "DESC20" },
];

const fakeProducts = [
  {
    product_id: 1,
    name: "Produto 1",
    group_id: 1,
    type_payment: "recurrence",
    price: {
      price_id: 1,
      price_type: 1,

      monthly_is_enabled: true,
      monthly: 100,
      monthly_installation_fee: 10,

      quarterly_is_enabled: true,
      quarterly: 280,
      quarterly_installation_fee: 20,

      semiannual_is_enabled: false,
      semiannual: 200,
      semiannual_installation_fee: 10,

      yearly_is_enabled: true,
      yearly: 1000,
      yearly_installation_fee: 50,

      biennial_is_enabled: false,
      biennial: 300,
      biennial_installation_fee: 10,

      triennial_is_enabled: false,
      triennial: 10,
      triennial_installation_fee: 10,
    },
  },
  {
    product_id: 2,
    name: "Produto 2",
    group_id: 2,
    type_payment: "one_time",
    price: {
      price_id: 1,
      price_type: 1,

      monthly_is_enabled: true,
      monthly: 100,
      monthly_installation_fee: 10,

      quarterly_is_enabled: true,
      quarterly: 280,
      quarterly_installation_fee: 20,

      semiannual_is_enabled: false,
      semiannual: 200,
      semiannual_installation_fee: 10,

      yearly_is_enabled: true,
      yearly: 1000,
      yearly_installation_fee: 50,

      biennial_is_enabled: false,
      biennial: 300,
      biennial_installation_fee: 10,

      triennial_is_enabled: false,
      triennial: 10,
      triennial_installation_fee: 10,
    },
  },
  {
    product_id: 3,
    name: "Produto Grátis",
    group_id: 2,
    type_payment: "free",
    price: {
      price_id: 1,
      price_type: 1,

      monthly_is_enabled: true,
      monthly: 100,
      monthly_installation_fee: 10,

      quarterly_is_enabled: true,
      quarterly: 280,
      quarterly_installation_fee: 20,

      semiannual_is_enabled: false,
      semiannual: 200,
      semiannual_installation_fee: 10,

      yearly_is_enabled: true,
      yearly: 1000,
      yearly_installation_fee: 50,

      biennial_is_enabled: false,
      biennial: 300,
      biennial_installation_fee: 10,

      triennial_is_enabled: false,
      triennial: 10,
      triennial_installation_fee: 10,
    },
  },
];

const fakeProductGroups = [
  { id: 1, name: "Grupo 1" },
  { id: 2, name: "Grupo 2" },
];

const fakePaymentMethods = [
  { id: 1, description: "Cartão de Crédito" },
  { id: 2, description: "Boleto Bancário" },
];

const CreateOrder = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const [products, setProducts] = useState([]);
  const [productGroup, setProductGroup] = useState([]);
  const [productGroupById, setProductGroupById] = useState({});
  const [productHoverId, setProductHoverId] = useState(null);
  const [productsById, setProductsById] = useState({});
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [isGetCustomers, setIsGetCustomers] = useState(false);

  const { search } = useLocation();
  const { cliente_id } = queryString.parse(search);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      customer: { label: null, value: null },
      coupon_id: null,
      generate_invoice: true,
      send_email: false,
      items: [
        {
          id: crypto.randomUUID(),
          product_id: "",
          qty: 1,
          price: "",
          overwrite: false,
          payment_cycle: "",
        },
      ],
    },
    validationSchema: Yup.object().shape({
      customer: Yup.object().shape({
        label: Yup.string().required("O cliente é obrigatório"),
        value: Yup.string().required("O cliente é obrigatório"),
      }),
      items: Yup.array()
        .of(
          Yup.object().shape({
            product_id: Yup.string().required("Produto é obrigatório"),
            payment_cycle: Yup.string()
              .oneOf(["free", "one_time", "monthly", "quarterly", "semiannual", "yearly", "biennial", "triennial"])
              .required("Tipo de pagamento é obrigátorio"),
            price: Yup.number()
              .when("payment_cycle", {
                is: (value) => value !== "free",
                then: (schema) => schema.required("O preço é obrigatório"),
                otherwise: (schema) => schema.nullable(),
              }),
          })
        )
        .min(1, "Pelo menos um produto deve ser adicionado"),
    }),
    onSubmit: async (values) => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        formik.resetForm();
        navigate("/pedidos", { replace: true });
        Swal.fire({
          title: "Pedido criado com sucesso!",
          icon: "success",
          confirmButtonColor: "#3E97FF",
        });
      } catch (error) {
        console.log(error);
        toast.error("Erro ao criar o pedido.");
      }
    },
  });

  useEffect(() => {
    (async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 1000));

        // Set clientes
        setCustomers(fakeCustomers.map((c) => ({ label: c.name, value: c.id })));

        // Set cupons
        setCoupons(fakeCoupons);

        // Fetch products
        const productResponse = await ProductService.getProducts({
          limit: 100,
          page: 1,
          orderBy: "name",
          orderDirection: "asc",
        });

        const fetchedProducts = productResponse.data || fakeProducts; // Caso não haja dados reais, usar fake

        const processedProducts = fetchedProducts.map((p) => {
          p.select_options = [];

          if (p.type_payment === "free") {
            p.select_options.push({
              key: "free",
              value: 0,
              setup: 0,
              label: "Grátis",
            });
          } else if (p.type_payment === "one_time") {
            // para one_time, usar monthly como base
            p.select_options.push({
              label: "Única vez",
              key: "one_time",
              value: p.price.monthly,
              setup: p.price.monthly_installation_fee,
            });
          } else {
            if (p.price.monthly_is_enabled) {
              p.select_options.push({
                label: "Mensal",
                value: p.price.monthly,
                setup: p.price.monthly_installation_fee,
                key: "monthly",
              });
            }
            if (p.price.quarterly_is_enabled) {
              p.select_options.push({
                label: "Trimestral",
                value: p.price.quarterly,
                setup: p.price.quarterly_installation_fee,
                key: "quarterly",
              });
            }
            if (p.price.semiannual_is_enabled) {
              p.select_options.push({
                label: "Semestral",
                value: p.price.semiannual,
                setup: p.price.semiannual_installation_fee,
                key: "semiannual",
              });
            }
            if (p.price.yearly_is_enabled) {
              p.select_options.push({
                label: "Anual",
                value: p.price.yearly,
                setup: p.price.yearly_installation_fee,
                key: "yearly",
              });
            }
            if (p.price.biennial_is_enabled) {
              p.select_options.push({
                label: "Bienal",
                value: p.price.biennial,
                setup: p.price.biennial_installation_fee,
                key: "biennial",
              });
            }
            if (p.price.triennial_is_enabled) {
              p.select_options.push({
                label: "Trienal",
                value: p.price.triennial,
                setup: p.price.triennial_installation_fee,
                key: "triennial",
              });
            }
          }
          return p;
        });

        setProducts(processedProducts);

        setProductGroup(fakeProductGroups);

        setProductGroupById(
          fakeProductGroups.reduce((acc, g) => {
            acc[g.id] = g;
            return acc;
          }, {})
        );

        setProductsById(
          fakeProducts.reduce((acc, p) => {
            acc[p.product_id] = p;
            return acc;
          }, {})
        );

        setPaymentMethods(fakePaymentMethods);

        if (cliente_id) {
          const customerFind = fakeCustomers.find((c) => c.id === parseInt(cliente_id));
          if (customerFind) {
            formik.setFieldValue("customer", {
              label: customerFind.name,
              value: customerFind.id,
            });
            setCustomer(customerFind);
          } else {
            toast.error("Cliente não encontrado!");
          }
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Cálculo do total
  const [total_price, total_setup_fee] = formik.values.items.reduce(
    (acc, item) => {
      const { product_id, payment_cycle } = item;
      if (product_id && payment_cycle) {
        const product = products.find((prod) => prod.product_id == product_id);
        if (product) {
          let idx_setup_fee = "";
          if (payment_cycle === "one_time") {
            idx_setup_fee = "monthly_installation_fee";
          } else if (payment_cycle === "free") {
            idx_setup_fee = "free";
          } else {
            idx_setup_fee = payment_cycle + "_installation_fee";
          }

          const priceVal = Number(item.price || 0);
          const setupVal = idx_setup_fee === "free" ? 0 : Number(product.price[idx_setup_fee] || 0);
          return [acc[0] + priceVal, acc[1] + setupVal];
        }
      }
      return acc;
    },
    [0, 0]
  );

  return isLoading ? (
    <div className="h-96 relative flex flex-col items-center justify-center">
      <LoadingIcon icon="three-dots" className="w-8 h-8" />
    </div>
  ) : (
    <form
      className="grid grid-cols-12 gap-8"
      onSubmit={(e) => {
        e.preventDefault();
        if (!formik.values.items.length)
          toast.warning("Você precisa adicionar um produto ao pedido.");
        formik.handleSubmit(e);
      }}
    >
      <section className="col-span-12 lg:col-span-6 w-full">
        <div className="flex items-center justify-between mb-5">
          <h6 className="text-lg font-medium">Detalhes do pedido</h6>
        </div>

        <div className="flex flex-col w-full gap-8">
          <div className="flex flex-col gap-3">
            <div className="relative">
              <CustomerSelect
                value={formik.values.customer}
                onChange={(value) => {
                  formik.setFieldValue("customer", value);
                  const found = fakeCustomers.find((c) => c.id === value?.value);
                  if (found) setCustomer(found);
                }}
                error={
                  formik.touched.customer?.label && formik.errors.customer?.label
                    ? formik.errors.customer?.label
                    : formik.touched.customer?.value && formik.errors.customer?.value
                }
              />
            </div>

            <SelectBox
              label="Método de Pagamento"
              placeholder="Selecione um cliente para visualizar seu método de pagamento"
              name="payment_method_id"
              disabled
              value={
                customer?.payment_method_id
                  ? {
                      label: fakePaymentMethods.find((pm) => pm.id == customer?.payment_method_id)
                        ?.description,
                      value: customer?.payment_method_id,
                    }
                  : undefined
              }
              onChange={(value) => {
                formik.setFieldValue("payment_method_id", value);
              }}
              containerClassName="col-span-12 lg:col-span-6"
              error={(formik.touched.payment_method_id || undefined) && formik.errors.payment_method_id}
              allowClear
              options={[
                ...paymentMethods.map((p) => ({ label: p.description, value: p.id })),
              ]}
            />

            <div className="flex col-span-12 gap-5">
              <FormCheck className="mt-2 mr-1 mb-2">
                <FormCheck.Input
                  id="checkbox-switch-generate-invoice"
                  type="checkbox"
                  className="h-6 w-6"
                  checked={formik.values.generate_invoice}
                  name="generate_invoice"
                  onChange={(e) => formik.setFieldValue("generate_invoice", !!e.target.checked)}
                />
                <FormCheck.Label htmlFor="checkbox-switch-generate-invoice">
                  Gerar fatura
                </FormCheck.Label>
              </FormCheck>

              <FormCheck className="mt-2 mr-1 mb-2">
                <FormCheck.Input
                  id="checkbox-switch-send-email"
                  type="checkbox"
                  className="h-6 w-6"
                  checked={formik.values.send_email}
                  name="send_email"
                  onChange={(e) => formik.setFieldValue("send_email", !!e.target.checked)}
                />
                <FormCheck.Label htmlFor="checkbox-switch-send-email">
                  Enviar e-mail
                </FormCheck.Label>
              </FormCheck>
            </div>
          </div>

          <div className="col-span-6">
            <section className="flex flex-col col-span-6">
              <div className="mb-5">
                <h6 className="text-lg font-medium">Produtos/Serviços</h6>
              </div>
            </section>

            <ul className="flex flex-col col-span-6">
              {formik.values.items.map((item, idx) => (
                <li
                  onMouseOver={() => setProductHoverId(item.product_id)}
                  onMouseLeave={() => setProductHoverId(null)}
                  key={item.id}
                  className={clsx([
                    "border-dashed border-2 hover:border-primary transition-all rounded-xl p-4 relative mb-4",
                    idx !== 0 && "mt-2",
                  ])}
                >
                  <div className="flex flex-col gap-3">
                    <SelectBox
                      label="Produto / Serviço:"
                      labelClass="min-w-[130px] mb-0"
                      name={`product_id_${idx}`}
                      errorClass="ml-[140px] absolute translate-y-6"
                      animation="intro-x"
                      value={formik.values.items[idx].product_id}
                      onChange={(value) => {
                        formik.setFieldValue(`items[${idx}].payment_cycle`, "");
                        formik.setFieldValue(`items[${idx}].price`, "");
                        formik.setFieldValue(`items[${idx}].overwrite`, false);
                        formik.setFieldValue(`items[${idx}].product_id`, value);
                      }}
                      containerClassName="col-span-12 lg:col-span-6 flex items-center gap-2 relative"
                      error={
                        formik.touched.items &&
                        formik.touched.items[idx] &&
                        formik.touched.items[idx].product_id &&
                        formik.errors.items &&
                        formik.errors.items[idx] &&
                        formik.errors.items[idx].product_id
                      }
                      allowClear
                      options={[
                        { label: "Selecione um produto/serviço", value: "" },
                        ...products.map((c) => ({ label: c.name, value: c.product_id })),
                      ]}
                    />

                    {formik.values.items[idx].product_id && (
                      <SelectBox
                        label="Ciclo de Pagamento:"
                        name={`payment_cycle_${idx}`}
                        animation="intro-x"
                        labelClass="min-w-[130px] mb-0"
                        value={formik.values.items[idx].payment_cycle}
                        onChange={(value) => {
                          formik.setFieldValue(`items[${idx}].payment_cycle`, value);

                          if (value !== "free" && value !== "") {
                            const productSelected = products.find(
                              (prod) => prod.product_id === formik.values.items[idx].product_id
                            );
                            const selectedOpt = productSelected?.select_options.find(
                              (opt) => opt.key === value
                            );
                            formik.setFieldValue(`items[${idx}].price`, Number(selectedOpt?.value || 0));
                          } else {
                            formik.setFieldValue(`items[${idx}].price`, 0);
                          }
                        }}
                        containerClassName="col-span-12 lg:col-span-6 flex items-center gap-2"
                        error={
                          formik.touched.items &&
                          formik.touched.items[idx] &&
                          formik.touched.items[idx].payment_cycle &&
                          formik.errors.items &&
                          formik.errors.items[idx] &&
                          formik.errors.items[idx].payment_cycle
                        }
                        allowClear
                        options={[
                          { label: "Selecione uma opção", value: "" },
                          ...(products
                            .find((prod) => prod.product_id == formik.values.items[idx].product_id)
                            ?.select_options.map((opt) => ({
                              label: opt.label,
                              value: opt.key,
                            })) || []),
                        ]}
                      />
                    )}

                    {formik.values.items[idx].product_id &&
                      formik.values.items[idx].payment_cycle &&
                      formik.values.items[idx].payment_cycle !== "free" && (
                        <div>
                          <BrlCurrencyComponent
                            name=""
                            containerClassName="col-span-6 flex items-center gap-2"
                            label="Preço:"
                            disabled={!formik.values.items[idx].overwrite}
                            labelClass="min-w-[130px] mb-0"
                            value={formik.values.items[idx].price}
                            handleChange={(e, value) => {
                              formik.setFieldValue(`items[${idx}].price`, Number(value));
                            }}
                          />
                          <FormCheck className="mr-2 ml-[140px] mt-3">
                            <FormCheck.Input
                              id={`overwrite_${idx}`}
                              type="checkbox"
                              value={formik.values.items[idx].overwrite}
                              name="overwrite"
                              onChange={(e) =>
                                formik.setFieldValue(`items[${idx}].overwrite`, !!e.target.checked)
                              }
                              className="h-4 w-4"
                            />
                            <FormCheck.Label
                              htmlFor={`overwrite_${idx}`}
                              className="text-xs ml-1"
                            >
                              Sobrescrever
                            </FormCheck.Label>
                          </FormCheck>
                        </div>
                      )}
                  </div>
                  <div
                    className="absolute w-10 h-10 rounded-full bg-white z-[9999] -end-5 border-[2px] cursor-pointer hover:text-red-400 hover:border-red-400 transition-all -top-5 flex items-center justify-center text-slate-400 hover:scale-110"
                    onClick={() => {
                      const new_items = [...formik.values.items];
                      new_items.splice(idx, 1);
                      formik.setFieldValue("items", new_items);
                    }}
                  >
                    <Lucide icon="Trash" />
                  </div>
                </li>
              ))}

              <li
                className="border-dashed border-2 rounded-xl p-4 h-[182px] flex items-center justify-center text-gray-400 hover:border-primary hover:text-primary transition-all cursor-pointer"
                onClick={() => {
                  formik.setFieldValue("items", [
                    ...formik.values.items,
                    {
                      id: crypto.randomUUID(),
                      product_id: "",
                      qty: 1,
                      price: "",
                      overwrite: false,
                      payment_cycle: "",
                    },
                  ]);
                }}
              >
                <div className="flex flex-col items-center justify-center">
                  <Lucide icon="PlusCircle" className="w-10 h-10" />
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>

      <div className="col-span-12 lg:col-span-6">
        <section className="flex flex-col col-span-6">
          <div className="mb-5 flex justify-between items-center">
            <h6 className="text-lg font-medium">Resumo do pedido</h6>
          </div>
        </section>

        <div className="col-span-6 box p-5">
          <ul className="flex flex-col gap-4 rounded-md">
            {formik.values.items.map((p, idx) => {
              let product_name = "Não definido";
              let group_name = "Não definido";
              let setup_fee = 0;

              if (p.product_id) {
                const product = productsById[+p.product_id];
                if (product) {
                  product_name = product.name;
                  group_name = productGroupById[product.group_id]?.name || "Não definido";
                  if (p.payment_cycle === "one_time") {
                    setup_fee = product.price.monthly_installation_fee || 0;
                  } else if (p.payment_cycle !== "free" && p.payment_cycle) {
                    setup_fee = product.price[p.payment_cycle + "_installation_fee"] || 0;
                  }
                }
              }

              let badges = null;
              if (p.payment_cycle === "free") {
                badges = <Badge type="dark" text="Grátis" className="mr-2" />;
              } else if (p.payment_cycle === "one_time") {
                badges = <Badge type="success" text="Única vez" className="mr-2" />;
              } else if (p.payment_cycle) {
                const idxPeriod = periodicals.indexOf(p.payment_cycle);
                if (idxPeriod !== -1) {
                  badges = (
                    <>
                      <Badge type="info" text="Recorrente" className="mr-2" />
                      <Badge type="success" text={periodicals_br[idxPeriod]} className="mr-2" />
                    </>
                  );
                }
              }

              let priceInfo = "";
              if (p.payment_cycle === "free") {
                priceInfo = "R$ 0";
              } else if (p.payment_cycle) {
                const valor = p.price ? formatToBRL(p.price) : "R$ 0";
                const setupTxt = setup_fee ? " + " + formatToBRL(setup_fee) + " Taxa de configuração" : "";
                priceInfo = valor + setupTxt;
              }

              return (
                <li
                  key={p.product_id + "_resume"}
                  className={clsx([
                    "relative transiton-all",
                    idx !== 0 && "border-t pt-3",
                    productHoverId === p.product_id && "text-primary",
                  ])}
                >
                  <div className="flex items-center justify-between text-[15px] font-medium relative z-20">
                    <h6 className="m-0 mr-1">
                      {group_name} - {product_name}
                    </h6>
                    <span className="m-0 text-end">{priceInfo}</span>
                  </div>
                  <div className="flex items-center justify-between mt-2 relative z-20">
                    <div className="flex items-center">{badges}</div>
                  </div>
                </li>
              );
            })}
          </ul>

          <div className="pt-5 mt-5 border-t">
            <div className="flex">
              <div className="mr-auto">Subtotal</div>
              <div className="font-medium">{formatToBRL(total_price)}</div>
            </div>
            <div className="flex mt-4">
              <div className="mr-auto">Taxa de Setup</div>
              <div className="font-medium">{formatToBRL(total_setup_fee)}</div>
            </div>
            <div className="flex pt-4 mt-4 border-t border-slate-200/60 dark:border-darkmode-400">
              <div className="mr-auto text-base font-medium">Total</div>
              <div className="text-base font-medium">
                {formatToBRL(total_price + total_setup_fee)}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-end items-end mt-4 mr-1">
          <Button type="submit" variant="primary">
            Enviar pedido
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CreateOrder;
