// import FotoPerfil from "../../../assets/images/v3_0282261 1.png";
import Logo from "../../assets/images/logo.svg";
// import Button from "../../../components/Base/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Menu1 from "../TopBar";
import { Menu, Popover } from "../../components/Base/Headless";
import MobileMenu from "../../components/MobileMenu";
import { toast } from "react-toastify";
import useAuth from "../../utils/hooks/useAuth";
import { useEffect, useState, Fragment, useRef } from "react";
import Lucide from "../../components/Base/Lucide";
// import logoUrl from "../../../assets/images/logo.png";
// import Breadcrumb from "../../../components/Base/Breadcrumb";
import { FormInput } from "../../components/Base/Form";
import clsx from "clsx";
// import { Transition } from "@headlessui/react";
import { useTranslation } from 'react-i18next';
import { LanguageDropdown } from "../../components/Generic/Language";
import AuthService from "../../services/AuthService";


const Header = ({ menuType }) => {
  const { t } = useTranslation();
  const { auth, logout } = useAuth();
  const searchInputRef = useRef(null);
  const navigate = useNavigate();
  const [searchDropdown, setSearchDropdown] = useState(false);

  const showSearchDropdown = () => {
    if (searchInputRef.current) searchInputRef.current.focus();
    setSearchDropdown(true);
  };

  const hideSearchDropdown = () => {
    setSearchDropdown(false);
  };

  return (
    <header className="bg-[#17191E] h-[59.2px] xl:min-h-[81px] border-b-[1px] border-[#3F4254] ">
      <div className="container flex items-center justify-between h-full p-5">
        <div className="mr-[60px] flex items-center gap-4">
          <MobileMenu menuType={menuType} />

          <Link to="/home">
            <img
              src={Logo}
              alt=""
              className="min-w-[144px] max-w-[144px] object-cover"
            />
          </Link>
        </div>

        <div className="flex justify-end items-center xl:justify-between w-full">
          <Menu1 menuType={menuType} />

          <div className="flex items-center gap-4">
            <div className="relative">
              <div className="hidden search sm:block">
                <FormInput
                  ref={searchInputRef}
                  type="text"
                  className={clsx([
                    "border-transparent w-0 shadow-none rounded-full bg-transparent p-0 transition-all duration-300 ease-in-out dark:bg-darkmode-400/70",
                    searchDropdown && "border-transparent w-56 p-2 pl-4 bg-slate-200",
                  ])}
                  placeholder={ t("000099") }
                  onBlur={hideSearchDropdown}
                />

                <button
                  className="btn"
                  onClick={showSearchDropdown}
                  disabled={searchDropdown}
                >
                  <Lucide
                    icon="Search"
                    className={clsx([
                      "absolute inset-y-0 right-0 w-5 h-5 my-auto text-white/70 dark:text-slate-500 hover:scale-125 transition duration-300 cursor-pointer",
                      searchDropdown && "mr-3 text-slate-600 hover:scale-1 cursor-auto",
                    ])}
                  />
                </button>
              </div>
              <a className="relative text-white/70 sm:hidden " href="">
                <Lucide icon="Search" className="w-5 h-5 dark:text-slate-500 " />
              </a>
            </div>

            <Popover className=" ">
              <Popover.Button
                className={clsx([
                  "relative text-white/70 outline-none block before:content-[''] before:w-[8px] before:h-[8px] before:rounded-full before:absolute before:top-[-2px] before:right-0 before:bg-danger hover:scale-125 transition-all",
                  searchDropdown && "hidden",
                ])}
              >
                <Lucide icon="Bell" className="w-5 h-5 dark:text-slate-500" />
              </Popover.Button>
              <Popover.Panel className="w-[280px] sm:w-[350px] p-5 mt-2">
                <div className="mb-5 font-medium">{ t("000098") }</div>
                {/* Conteúdo de notificações aqui */}
              </Popover.Panel>
            </Popover>

            <Menu>
              <Menu.Button
                className={clsx([
                  "flex items-center justify-center hover:scale-110 image-fit transition-all border-0 p-0 min-h-[38px] min-w-[38px] rounded-full bg-slate-700",
                  searchDropdown && "hidden",
                ])}
              >
                
                {/* {auth.photo_name ? (
                  <img
                    src={`http://localhost/images/${auth.photo_name ? auth.photo_name : FotoPerfil}`}
                    className="h-[38px] w-[38px] rounded-full"
                    alt=""
                  />
                ) : (
                  <Lucide icon="User" className="text-slate-500"></Lucide>
                )} */}

                <Lucide icon="User" className="text-slate-500"></Lucide>
              </Menu.Button>
              <Menu.Items className="w-56 mt-px relative before:block before:absolute before:bg-black before:inset-0 before:rounded-md before:z-[-1] text-dark">
                <Menu.Header className="font-normal">
                  <div className="font-medium ">João Marteloo</div>
                  <div className="text-xs mt-0.5 dark:text-slate-500">
                    Construtor
                  </div>
                </Menu.Header>
                <Menu.Divider className="bg-dark/[0.08]" />
                {/* <Menu.Item className="hover:bg-dark/5">
                  <Lucide icon="User" className="w-4 h-4 mr-2" /> Usuários
                </Menu.Item>
                <Menu.Item
                  className="hover:bg-dark/5"
                  onClick={() => navigate("/configuracoes/cupons")}
                >
                  <Lucide icon="Percent" className="w-4 h-4 mr-2" /> Cupons
                </Menu.Item>
                <Menu.Item className="hover:bg-dark/5">
                  <Lucide icon="Lock" className="w-4 h-4 mr-2" /> Grupo de Permissões
                </Menu.Item>
                <Menu.Item className="hover:bg-dark/5">
                  <Lucide icon="Mail" className="w-4 h-4 mr-2" /> Templates de E-mails
                </Menu.Item>
                <Menu.Item className="hover:bg-dark/5">
                  <Lucide icon="Square" className="w-4 h-4 mr-2" /> Módulos
                </Menu.Item> */}

                <Menu.Item className="hover:bg-dark/5">
                  <Lucide icon="CircleUser" className="w-4 h-4 mr-2" /> { t("000096") }
                </Menu.Item>

                <Menu.Divider className="bg-dark/[0.08]" />
                <LanguageDropdown />

                <Menu.Divider className="bg-dark/[0.08]" />

                <Menu.Item
                  className="hover:bg-dark/5"
                  onClick={() => {
                    AuthService.logout();
                    navigate('/login', { replace: true });
                  }}
                >
                  <Lucide icon="LogOut" className="w-4 h-4 mr-2" /> { t("000097") }
                </Menu.Item>
              </Menu.Items>
            </Menu>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
