// import Badge from "@/components/Bagde";
import Button from "../../../../components/Base/Button";
import { FormCheck, FormLabel, FormTextarea } from "../../../../components/Base/Form";
// import Lucide from "@/components/Base/Lucide";
import Modal from "../../../../components/Base/Modal";
// import Table from "@/components/Base/Table";
// import axios from "axios";
// import clsx from "clsx";
// import { useState } from "react";
// import { Link } from "react-router-dom";

interface IProductsOrderModal {
  open: boolean;
  closeModal: () => void;
}

const FooterModal = ({ closeModal }: any) => {
  return (
    <div className="flex items-center justify-end gap-2">
      <Button
        type="submit"
        form="customerForm"
        variant="outline-primary"
        size="sm"
        onClick={() => closeModal()}
        // className="text-[13px] font-semibold leading-4"
      >
        {/* <Lucide icon="X" className="mr-1  w-4" /> */}
        Adicionar
      </Button>
      <Button
        type="submit"
        form="customerForm"
        variant="outline-secondary"
        size="sm"
        onClick={() => closeModal()}
        // className="text-[13px] font-semibold leading-4"
      >
        {/* <Lucide icon="X" className="mr-1  w-4" /> */}
        Fechar
      </Button>
    </div>
  );
};

const NoteModal = ({ open, closeModal }: IProductsOrderModal) => {


  return (
    <Modal
      open={open}
      title="Nota"
      width={500}
      closeModal={() => {
        // formik.resetForm();
        closeModal();
      }}
      footer={<FooterModal closeModal={closeModal} />}
    >
      <section>
        <div className="">
          <form action="">
            <FormLabel>Texto</FormLabel>
            <FormTextarea className="min-h-[150px] max-h-[350px]"/>
          </form>
        </div>
      </section>
    </Modal>
  );
};

export default NoteModal;
