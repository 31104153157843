import Badge from "../../../components/Badge";
import Lucide from "../../../components/Base/Lucide";
import Table from "../../../components/Base/Table";
import { useNavigate, useParams } from "react-router-dom";
import Cog from "../../../assets/images/cog2.svg";
import Button from "../../../components/Base/Button";
import clsx from "clsx";
import MenuFloat from "../../../components/MenuFloat";
import { useEffect, useState } from "react";
import { useCustomer } from "../../../layout/BaseSecondary";
import OrderService from "../../Orders/Services/OrderService";
import {
  formatDate,
  formatToBRL,
  getPositionsMenuFloat,
} from "../../../utils/helpers";
import ListBase from "../../../layout/ListBase";
import { t } from "i18next";

const types_payment = {
  free: "Gratuito",
  one_time: "Único",
  monthly: "Mensal",
  quarterly: "Trimestral",
  semiannual: "Semestral",
  yearly: "Anual",
  biennial: "Bienal",
  triennially: "Trienal",
};

const orderStatusBadge = {
  Pendente: <Badge type="warning" text="Pendente" />,
  Ativo: <Badge type="success" text="Ativo" />,
  Completo: <Badge type="info" text="Completo" />,
  Suspenso: <Badge type="dark" text="Suspenso" />,
  Cancelado: <Badge type="danger" text="Cancelado" />,
};

const Orders = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    from: 0,
    to: 0,
  });

  const navigate = useNavigate();
  const [menuFloatActive, setMenuFloatActive] = useState(false);
  const [positions, setPositions] = useState({ x: 0, y: 0 });
  const [orderId, setOrderId] = useState(null);
  const { customer_id } = useParams();

  const closeMenuFloat = () => {
    setOrderId(null);
    setMenuFloatActive(false);
  };

  const getOrdersOnPageChange = async (newPage) => {
    setCurrentPage(newPage);
    setIsLoading(true);
    try {
      const response = await OrderService.getOrdersByCustomerId(customer_id, {
        page: newPage,
        limit: pagination.per_page,
      });
      setOrders(response.data);
      setPagination({
        total: response.total,
        per_page: response.per_page,
        current_page: response.current_page,
        last_page: response.last_page,
        from: response.from,
        to: response.to,
      });
    } catch (error) {
      console.error(
        "Erro ao buscar os pedidos na página:",
        newPage,
        error
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleClick = (e, id) => {
    setOrderId(id);
    setPositions(getPositionsMenuFloat(e, 20));
    setMenuFloatActive(true);
  };

  useEffect(() => {
    const fetchOrders = async () => {
      setIsLoading(true);
      try {
        const response = await OrderService.getOrdersByCustomerId(customer_id, {
          page: currentPage,
          limit: pagination.per_page,
        });
        setOrders(response.data);
        setPagination({
          total: response.total,
          per_page: response.per_page,
          current_page: response.current_page,
          last_page: response.last_page,
          from: response.from,
          to: response.to,
        });
      } catch (error) {
        console.error("Erro ao buscar os pedidos:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [customer_id, currentPage]);

  return (
    <main>
      <section className="md:min-w-[460px] bg-white rounded-xl font-nunito shadow-custom-1">
        <ListBase
          filters={<></>}
          getContentListOnPageChange={getOrdersOnPageChange}
          isFilterActive={false}
          setIsLoading={setIsLoading}
          tableEmptyMsg="Não há nenhum pedido associado a este cliente."
          title="Pedidos"
          headerType="B"
          isLoading={isLoading}
          table={
            <Table hover={true}>
              <Table.Thead>
                <Table.Tr className="bg-[#F9F9F9] h-[35px] pb-2">
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold rounded-l">
                    ID
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Nome
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Valor
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Ciclo de pagamento
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Criado em
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Vencimento
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito text-center font-semibold rounded-r">
                    Ações
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>
                {orders.map((order) => (
                  <Table.Tr
                    key={order.id}
                    className="intro-x"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/clientes/${customer_id}/pedidos/${order.id}`
                      );
                    }}
                  >
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {order.id}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      <div className="flex items-center gap-2">
                        {order.name} {orderStatusBadge[order.status]}
                      </div>
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {formatToBRL(+order.price)}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {types_payment[order.payment_cycle]}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {formatDate(order.created_at, "DD/MM/YYYY")}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {formatDate(order.next_due_date, "DD/MM/YYYY")}
                    </Table.Td>
                    <Table.Td
                      className={clsx(
                        "w-38 text-center relative z-50 dark:bg-darkmode-600"
                      )}
                    >
                      <button
                        className="hover:scale-105 hover:rotate-45 transition-all duration-300 ease-in-out"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(e, order.id);
                        }}
                      >
                        <img src={Cog} alt="Configuração" />
                      </button>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          }
          contentList={orders}
          pagination={pagination}
          actionsMenu={
            <>
              <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
                <p className="text-slate-400/90 dark:placeholder:text-slate-500/80">
                  {t("000099")}
                </p>
                <Lucide
                  icon="Search"
                  className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                />
              </div>

              <Button variant="primary" className="font-nunito text-[14px]">
                {t("000220")}
              </Button>
            </>
          }
        />
      </section>

      <MenuFloat
        active={menuFloatActive}
        closeMenuFloat={closeMenuFloat}
        positions={positions}
      >
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={() => {
            closeMenuFloat();
            navigate(
              `/clientes/${customer_id}/pedidos/${orderId}`
            );
          }}
        >
          <Lucide icon="Eye" className="w-4 h-4 mr-2" /> Detalhes
        </li>
      </MenuFloat>
    </main>
  );
};

export default Orders;