class TokenService {
  static getAccessToken() {
    return localStorage.getItem("access_token");
  }

  static setAccessToken(access_token) {
    localStorage.setItem("access_token", access_token);
  }

  static getName() {
    return localStorage.getItem("name");
  }

  static setName(name) {
    localStorage.setItem("name", name);
  }

  static getUserId() {
    return localStorage.getItem("user_id");
  }

  static setUserId(user_id) {
    localStorage.setItem("user_id", user_id);
  }

  static getLastName() {
    return localStorage.getItem("last_name");
  }

  static setLastName(last_name) {
    localStorage.setItem("last_name", last_name);
  }

  static getLanguage() {
    return localStorage.getItem("language");
  }
  
  static setLanguage(language) {
    localStorage.setItem("language", language);
  }

  static removeAccessToken() {
    localStorage.removeItem("access_token");
    localStorage.removeItem("name");
    localStorage.removeItem("user_id");
    localStorage.removeItem("last_name");
    localStorage.removeItem("language");
  }
}

export default TokenService;
