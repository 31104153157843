// import Badge from "@/components/Bagde";
import Button from "../../../../components/Base/Button";
// import { FormCheck } from "@/components/Base/Form";
import Lucide from "../../../../components/Base/Lucide";
import Modal from "../../../../components/Base/Modal";
// import Table from "@/components/Base/Table";
// import axios from "axios";
// import clsx from "clsx";
// import { Link } from "react-router-dom";

interface IProductsOrderModal {
  open: boolean;
  closeModal: () => void;
}

const FooterModal = ({ closeModal }: any) => {
  return (
    <div className="flex items-center justify-end gap-2">
       <Button
        type="submit"
        form="customerForm"
        variant="outline-secondary"
        size="sm"
        onClick={() => closeModal()}
        // className="text-[13px] font-semibold leading-4"
      >
        <Lucide icon="X" className="mr-1  w-4" />
        Fechar
      </Button>
    </div>
  );
};

const LogsModal = ({ open, closeModal }: IProductsOrderModal) => {
  return (
    <Modal
      open={open}
      title="Detalhes do E-mail"
      width={1200}
      closeModal={() => {
        // formik.resetForm();
        closeModal();
      }}
      footer={<FooterModal closeModal={closeModal} />}
    >
      <section>
        <div className="">
          <ul className="grid grid-cols-12 font-nunito font-medium text-sm leading-[18px] text-[#A1A5B7] overflow-hidden gap-[12px] md:gap-0">
            <li className="flex flex-col gap-[12px] col-span-12">
              <li className="flex">
                <span className="min-w-[182px] ">Data e Hora:</span>
                <span className="text-[#3F4254]">31/07/2001 12:11:32</span>
              </li>
              <li className="flex">
                <span className="min-w-[182px] ">Assunto:</span>
                <span className="text-[#3F4254]">Reunião de Equipe</span>
              </li>
              <li className="flex">
                <span className="min-w-[182px] ">Remetente:</span>
                <span className="text-[#3F4254]"> joao@example.com</span>
              </li>

              <li className="flex">
                <span className="min-w-[182px] ">Destinatários:</span>
                <span className="text-[#3F4254]">
                  joao@example.com, joao@example.com, joao@example.com
                </span>
              </li>
              <li>
                <span className="min-w-[182px] mb-3 block">Corpo:</span>
                <div style={{ backgroundColor: "#f0f0f0", padding: "20px" }}>
                  <h2 style={{ color: "#333" }}>Assunto do E-mail</h2>
                  <p>Olá,</p>
                  <p>Este é um exemplo de corpo de e-mail em HTML.</p>
                  <p>Atenciosamente Seu Nome</p>
                </div>
              </li>
            </li>
          </ul>
        </div>
      </section>
    </Modal>
  );
};

export default LogsModal;
