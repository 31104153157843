import React from "react";

/**
 * Inicializa o CKEditor.
 *
 * @param {HTMLDivElement & { CKEditor?: any }} el - Elemento DOM onde o CKEditor será inicializado.
 * @param {any} editorBuild - A construção/instância do editor CKEditor.
 * @param {Object} options - Opções de inicialização.
 * @param {Object} options.props - Propriedades do componente.
 * @param {React.MutableRefObject<string>} options.cacheData - Referência mutável para armazenar dados em cache.
 */
const init = async (el, editorBuild, { props, cacheData }) => {
  // Dados iniciais
  cacheData.current = props.value;
  props.config.initialData = props.value;

  // Inicializa o CKEditor
  const editor = await editorBuild.create(el, props.config);

  // Anexa a instância do CKEditor ao elemento
  el.CKEditor = editor;

  // Define o estado inicial de desabilitado, se necessário
  if (props.disabled) {
    editor.enableReadOnlyMode("ckeditor");
  }

  // Evento de mudança de dados
  editor.model.document.on("change:data", () => {
    const data = editor.getData();
    cacheData.current = data;
    props.onChange(data);
  });

  // Evento de foco
  editor.editing.view.document.on("focus", (evt) => {
    if (props.onFocus) {
      props.onFocus(evt, editor);
    }
  });

  // Evento de perda de foco
  editor.editing.view.document.on("blur", (evt) => {
    if (props.onBlur) {
      props.onBlur(evt, editor);
    }
  });

  // Evento de pronto
  if (props.onReady) {
    props.onReady(editor);
  }
};

/**
 * Atualiza os dados do CKEditor se houver alterações.
 *
 * @param {HTMLDivElement & { CKEditor?: any }} el - Elemento DOM do CKEditor.
 * @param {Object} options - Opções para atualização.
 * @param {Object} options.props - Propriedades do componente.
 * @param {React.MutableRefObject<string>} options.cacheData - Referência mutável para armazenar dados em cache.
 */
const updateData = (el, { props, cacheData }) => {
  if (cacheData.current !== props.value) {
    el.CKEditor.setData(props.value);
  }
};

export { init, updateData };
