import Lucide from "../../../components/Base/Lucide";
import Table from "../../../components/Base/Table";
import { useNavigate, useParams } from "react-router-dom";
import Cog from "../../../assets/images/cog2.svg";
import Button from "../../../components/Base/Button";
import { useEffect, useState } from "react";
import NoteService from "./Services/NoteService"; // Importe o NoteService
import ListBase from "../../../layout/ListBase";
import { t } from "i18next";
import clsx from "clsx";
import NoteModal from "./components/NoteModal";
import MenuFloat from "../../../components/MenuFloat";
import {
  getPositionsMenuFloat,
} from "../../../utils/helpers";

/**
 * Componente Notes
 *
 * Este componente exibe uma lista de notas associadas a um cliente.
 */
const Notes = () => {

  const [isNotesModalActive, setNotesModalActive] = useState(false);
  const [menuFloatActive, setMenuFloatActive] = useState(false);
  const [logId, setLogId] = useState(null);
  const [positions, setPositions] = useState({ x: 0, y: 0 });  
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  // Hooks
  const { customer_id } = useParams();
  const [notesData, setNotesData] = useState({
    data: [],
    pagination: {},
  });
  const [isLoading, setIsLoading] = useState(false);

   // Close the menu float
   const closeMenuFloat = () => {
    setLogId(null);
    setMenuFloatActive(false);
  };

  // Handle click event for menu float
  const handleClick = (e, idProductOrder) => {
    setLogId(idProductOrder);
    setPositions(getPositionsMenuFloat(e, 20));
    setMenuFloatActive(true);
  };

  // Função para lidar com a mudança de página na paginação
  const contentListOnPageChange = async (page) => {
    setIsLoading(true);
    try {
      const notes = await NoteService.getNotes({
        limit: limit, // Defina o limite conforme necessário
        page: page,
        customer_id: customer_id,
      });
      setNotesData(notes);
    } catch (error) {
      console.error(t('000257'), error);
    }
    setIsLoading(false);
  };

  // Busca as notas quando o componente é montado
  useEffect(() => {
    const fetchNotes = async () => {
      setIsLoading(true);
      try {
        const notes = await NoteService.getNotes({
          limit: limit,
          page: page,
          customer_id: customer_id,
        });
        setNotesData(notes);
      } catch (error) {
        console.error(t('000257'), error);
      }
      setIsLoading(false);
    };

    fetchNotes();
  }, [customer_id]);

  return (
    <main>
      <section className="md:min-w-[460px] bg-white rounded-xl font-nunito shadow-custom-1">
        <ListBase
          filters={<></>}
          getContentListOnPageChange={contentListOnPageChange}
          isFilterActive={false}
          setIsLoading={setIsLoading}
          tableEmptyMsg={ t('000258') }
          title={ t('000259') }
          headerType="B"
          isLoading={isLoading}
          table={
            <Table hover={true}>
              <Table.Thead className="">
                <Table.Tr className="bg-[#F9F9F9] h-[35px] pb-2">
                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold rounded-l">
                    { t('000255') }
                  </Table.Th>

                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold">
                    { t('000260') }
                  </Table.Th>

                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold">
                    { t('000261') }
                  </Table.Th>

                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold">
                    { t('000262') }
                  </Table.Th>

                  <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito text-center whitespace-nowrap font-semibold rounded-r">
                    { t('000108') }
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody className="before:content-['@'] before:block before:leading-[8px] before:indent-0 before:opacity-0">
                {notesData.data && notesData.data.length > 0 ? (
                  notesData.data.map((note) => (
                    <Table.Tr
                      key={note.note_id}
                      className="intro-x"
                      onClick={(e) => {
                        e.stopPropagation();
                        setNotesModalActive(true);
                      }}
                    >
                      <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                        {new Date(note.created_at).toLocaleString()}
                      </Table.Td>
                      <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] whitespace-nowrap last:rounded-r-[0.6rem] dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                        {note.content}
                      </Table.Td>
                      <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] whitespace-nowrap last:rounded-r-[0.6rem] dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                        {note.user_name || "N/A"}
                      </Table.Td>

                      <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] dark:bg-darkmode-600 whitespace-nowrap font-nunito text-[#7E8299] text-[14px] font-normal">
                        {new Date(note.updated_at).toLocaleString()}
                      </Table.Td>

                      <Table.Td
                        className={clsx([
                          "w-38 text-center relative z-50 rounded-l-none rounded-r-none first:rounded-l-[0.6rem] last:rounded-r-[0.6rem] dark:bg-darkmode-600",
                        ])}
                      >
                        <button
                          className="hover:scale-105 hover:rotate-45 transition-all duration-300 ease-in-out"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleClick(e, note.note_id);
                          }}
                        >
                          <img src={Cog} alt="" className="text-danger" />
                        </button>
                      </Table.Td>
                    </Table.Tr>
                  ))
                ) : (
                  <Table.Tr>
                    <Table.Td colSpan="5" className="text-center">
                      { t('000263') }
                    </Table.Td>
                  </Table.Tr>
                )}
              </Table.Tbody>
            </Table>
          }
          contentList={notesData}
          setPage={setPage}
          actionsMenu={
            <>
              {/* Campo de busca */}
              <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
                <p className="text-slate-400/90 dark:placeholder:text-slate-500/80">
                  {t("000099")}
                </p>
                <Lucide
                  icon="Search"
                  className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                />
              </div>

              {/* Botão de ação */}
              <Button 
                variant="primary" 
                className="font-nunito text-[14px]"
                onClick={(e) => {
                  e.stopPropagation();
                  setNotesModalActive(true);
                }}>
                {t("000234")}
              </Button>
            </>
          }
        />
      </section>

      <MenuFloat
        active={menuFloatActive}
        closeMenuFloat={closeMenuFloat}
        positions={positions}
      >
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={(e) => {
            closeMenuFloat();
            e.stopPropagation();
            setNotesModalActive(true);
          }}
        >
          <Lucide icon="Eye" className="w-4 h-4 mr-2" /> { t('000256') }
        </li>
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={(e) => {
            closeMenuFloat();
            e.stopPropagation();
            setNotesModalActive(true);
          }}
        >
          <Lucide icon="Eye" className="w-4 h-4 mr-2" /> { t('000264') }
        </li>
      </MenuFloat>

      <NoteModal
        open={isNotesModalActive}
        closeModal={() => setNotesModalActive(false)}
      />
    </main>
  );
};

export default Notes;
