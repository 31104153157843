import Badge from "../../../components/Badge";
import Button from "../../..//components/Base/Button";
// import { FormCheck } from "@/components/Base/Form";
// import Lucide from "@/components/Base/Lucide";
import Modal from "../../../components/Base/Modal";
import Table from "../../../components/Base/Table";
import ProductsOrderModal from "../../../pages/Orders/Modals/ProductsOrderModal";
import axios from "axios";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Cog from "../../../assets/images/cog2.svg";
import { useEffect, useState } from "react";
import OrderService from "../../../pages/Orders/Services/OrderService";
import { formatCurrency, formatDate, formatToBRL } from "../../../utils/helpers";
// import { formatTimeStr } from "antd/es/statistic/utils";
import fakerData from "../../../utils/faker";

// import { useCustomer } from "../../../layout/BaseSecondary";

// Removida a interface IProductsOrderModal

const getTypeBadge = (type) => {
  switch (type) {
    case "Não paga":
      return "warning";
    case "Paga":
      return "success";
    case "Cancelada":
      return "danger";
    case "Estornada":
      return "info";
  }
};

const FooterModal = ({ closeModal }) => {
  return (
    <div className="flex items-center justify-end gap-2">
      <Button
        type="submit"
        form="customerForm"
        variant="success"
        size="sm"
        className="text-white"
      >
        {/* <Lucide icon="Check" className="mr-1 " /> */}
        Aceitar pedido
      </Button>
      <Button
        type="submit"
        form="customerForm"
        variant="danger"
        size="sm"
        // className="text-[13px] font-semibold leading-4"
      >
        {/* <Lucide icon="Trash" className="mr-1 w-4" /> */}
        Deletar pedido
      </Button>
      <Button
        type="submit"
        form="customerForm"
        variant="dark"
        size="sm"
        className={clsx(["cursor-not-allowed opacity-40"])}
      >
        {/* <Lucide icon="X" className="mr-1 w-4" /> */}
        Cancelar pedido
      </Button>
    </div>
  );
};

const OrdersSumaryModal = ({ open, closeModal, idCustomer }) => {
  const [customerOrders, setCustomerOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ordersCustomerModal, setOrdersCustomerModal] = useState({
    active: false,
    id: null,
  });

  const { customer, customer_id } = fakerData[0].customers[0];

  useEffect(() => {
    (async () => {
      console.log(idCustomer);
      if (idCustomer) {
        setIsLoading(true);

        const res = await OrderService.customerOrders(idCustomer);

        setCustomerOrders(res.data);

        setIsLoading(false);
      }
    })();
  }, [idCustomer]);

  return (
    <Modal
      open={open}
      title={`Pedidos do cliente - #${customer_id} / ${customer?.name}`}
      width={1200}
      isLoading={isLoading}
      closeModal={() => {
        // formik.resetForm();
        closeModal();
      }}
      footer={null}
    >
      <section>
        <section className="overflow-x-scroll xl:overflow-hidden">
          <Table hover={true} className="bord ">
            <Table.Thead className="">
              <Table.Tr className="bg-[#F9F9F9] h-[35px] pb-2 rounded-2xl">
                <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito  whitespace-nowrap font-semibold rounded-l">
                  ID
                </Table.Th>
                <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito  whitespace-nowrap font-semibold">
                  Data e horário
                </Table.Th>
                <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito  whitespace-nowrap font-semibold">
                  Total
                </Table.Th>
                <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito   whitespace-nowrap font-semibold">
                  Método de Pagamento
                </Table.Th>
                <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito  whitespace-nowrap font-semibold">
                  Qtd. de Produtos/Serviços
                </Table.Th>
                <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito  whitespace-nowrap font-semibold">
                  Status do pagamento
                </Table.Th>
                <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito  whitespace-nowrap font-semibold">
                  Ações
                </Table.Th>
              </Table.Tr>
            </Table.Thead>

            <Table.Tbody className="before:content-['@'] before:block before:leading-[8px] before:indent-0 before:opacity-0 max-h-[200px] overflow-y-scroll">
              {customerOrders?.map((o, idx) => (
                <Table.Tr
                  key={o.id}
                  className="intro-x"
                  onClick={() =>
                    setOrdersCustomerModal({ active: true, id: o.id })
                  }
                >
                  <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                    {o.id}
                  </Table.Td>

                  <Table.Td className="whitespace-nowrap dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                    {formatDate(o.created_at, "DD/MM/YYYY HH[h]mm[m]")}
                  </Table.Td>

                  <Table.Td className="whitespace-nowrap dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                    {formatToBRL(+o.total_price)}
                  </Table.Td>

                  <Table.Td className="whitespace-nowrap dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                    {o.payment_method}
                  </Table.Td>

                  <Table.Td className="whitespace-nowrap dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                    {o.qty_products}
                  </Table.Td>

                  <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                    <Badge
                      type={getTypeBadge(o.payment_status)}
                      text={o.payment_status}
                    />
                  </Table.Td>

                  <Table.Td
                    className={clsx([
                      "w-38 rounded-l-none rounded-r-none first:rounded-l-[0.6rem] text-end last:rounded-r-[0.6rem] dark:bg-darkmode-600",
                      "td-btn",
                    ])}
                  >
                    <div className="flex items-center justify-center">
                      <button
                        className="hover:scale-105 hover:rotate-45 transiton-all duration-300 ease-in-out p-0"
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        <img src={Cog} alt="" className="text-danger" />
                      </button>
                    </div>
                  </Table.Td>
                </Table.Tr>
              ))}
            </Table.Tbody>
          </Table>
        </section>
      </section>

      <ProductsOrderModal
        idOrder={ordersCustomerModal.id}
        closeModal={() =>
          setOrdersCustomerModal({ active: false, id: null })
        }
        open={ordersCustomerModal.active}
      />
    </Modal>
  );
};

export default OrdersSumaryModal;
