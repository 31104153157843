import React, { useEffect, useState } from "react";
import Button from "../../components/Base/Button";
import { FormCheck, FormLabel, FormTextarea } from "../../components/Base/Form";
import Lucide from "../../components/Base/Lucide";
import Table from "../../components/Base/Table";
import BrlCurrencyComponent from "../../components/Form/BrlCurrencyComponent";
import Input from "../../components/Form/InputBox";
import SelectBox from "../../components/Form/SelectBox";
import { useWindowSize } from "../../hooks/useWindowSize";
import ProductGroupService from "./Services/ProductGroupService";
import ProductService from "./Services/ProductService";
import { Steps } from "antd";
import axios from "axios";
import { useFormik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import * as Yup from "yup";

export const periodicals = [
  "monthly",
  "quarterly",
  "semiannual",
  "yearly",
  "biennial",
  "triennially",
];

export const periodicals_actives = [
  "monthly",
  "quarterly",
  "semiannual",
  "yearly",
];

const base = {
  name: "",
  // group_id: null,
  available_for_sale: false,
  description: "",
  recurrence_limit: 0,

  price_type: "",

  ...periodicals.reduce((acc, periodical) => {
    return {
      ...acc,
      [periodical]: null,
      [`${periodical}_setup_fee`]: null,
      [`${periodical}_enable`]: false,
    };
  }, {}),

  days_generate_invoices_before_due: "",
  days_first_invoice_due: "",
  days_suspend_product_after_due: "",
  days_cancel_product_after_due: "",

  url_create_webhook: "",
  create_webhook_type: "",
  url_suspend_webhook: "",
  suspend_webhook_type: "",
  url_unsuspend_webhook: "",
  unsuspend_webhook_type: "",
  url_cancel_webhook: "",
  cancel_webhook_type: "",
  url_completing_webhook: "",
  completing_webhook_type: "",
};

const ManageProductService = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [menuActive, setMenuActive] = useState(0);
  const [mustRedirectSubmit, setMustRedirectSubmit] = useState({
    value: false,
  });

  const [realInitialValues, setRealInitialValues] = useState(base);
  const [productGroups, setProductGroups] = useState([]);
  const { width = 0 } = useWindowSize();

  // Buscar grupos de produtos e dados do produto (se for edição)
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Buscar grupos de produtos
        const pgRes = await ProductGroupService.list();
        setProductGroups(
          pgRes.data.map((pg) => ({ value: pg.id, label: pg.name }))
        );

        // Se for edição, buscar os dados do produto
        if (id) {
          const productRes = await ProductService.getProductById(id);
          const productData = productRes;

          if (productData) {
            // Mapear os dados recebidos para os valores do formulário
            const mappedData = { ...base };

            Object.keys(productData).forEach((key) => {
              if (key in mappedData) {
                mappedData[key] = productData[key];
              }
            });

            setRealInitialValues(mappedData);
            formik.setValues(mappedData);
          }
        }
      } catch (error) {
        console.error("Erro ao buscar dados do produto ou grupos:", error);
        toast.error("Ocorreu um erro inesperado!");
        navigate("/products/create");
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const formik = useFormik({
    initialValues: realInitialValues,
    enableReinitialize: true, // Permite re-inicializar o formulário quando realInitialValues muda
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required("Nome do produto é obrigatório")
        .max(255),
      // group_id: Yup.number().required("O grupo é obrigatório"),
      description: Yup.string().max(512).nullable(),
      price_type: Yup.mixed()
        .oneOf(["free", "one_time", "recurrence"])
        .required("Tipo de pagamento é obrigatório"),
      ...periodicals.reduce((acc, periodical) => {
        return {
          ...acc,
          [periodical]: Yup.number()
            .min(0)
            .nullable()
            .when(`${periodical}_enable`, {
              is: true,
              then: () => Yup.number().min(0.01).required("Campo obrigatório"),
            }),
          [`${periodical}_setup_fee`]: Yup.number()
            .min(0)
            .nullable()
            .when(`${periodical}_enable`, {
              is: true,
              then: () => Yup.number().min(0).required("Campo obrigatório"),
            }),
          [`${periodical}_enable`]: Yup.boolean().nullable(),
        };
      }, {}),
      days_generate_invoices_before_due: Yup.number()
        .min(0)
        .max(29)
        .nullable()
        .when("price_type", {
          is: (value) => value === "one_time" || value === "recurrence",
          then: () =>
            Yup.number()
              .min(0)
              .max(29)
              .required("Este campo é obrigatório"),
        }),
      days_first_invoice_due: Yup.number()
        .min(0)
        .max(29)
        .nullable()
        .when("price_type", {
          is: (value) => value === "one_time" || value === "recurrence",
          then: () =>
            Yup.number()
              .min(0)
              .max(29)
              .required("Este campo é obrigatório"),
        }),
      days_cancel_product_after_due: Yup.number()
        .min(0)
        .max(29)
        .nullable()
        .when("price_type", {
          is: (value) => value === "one_time" || value === "recurrence",
          then: () =>
            Yup.number()
              .min(0)
              .max(29)
              .required("Este campo é obrigatório"),
        }),
      days_suspend_product_after_due: Yup.number()
        .min(0)
        .max(29)
        .nullable()
        .when("price_type", {
          is: (value) => value === "one_time" || value === "recurrence",
          then: () =>
            Yup.number()
              .min(0)
              .max(29)
              .required("Este campo é obrigatório"),
        }),
      recurrence_limit: Yup.number()
        .min(0)
        .when("price_type", {
          is: "recurrence",
          then: () => Yup.number().min(0).nullable(),
          otherwise: () => Yup.number().min(0).required(),
        }),
      available_for_sale: Yup.boolean().required(),
      // Campos de WebHook
      url_create_webhook: Yup.string().url("URL inválida").nullable(),
      create_webhook_type: Yup.number()
        .oneOf([1, 2], "Selecione um método válido")
        .nullable(),
      url_suspend_webhook: Yup.string().url("URL inválida").nullable(),
      suspend_webhook_type: Yup.number()
        .oneOf([1, 2], "Selecione um método válido")
        .nullable(),
      url_unsuspend_webhook: Yup.string().url("URL inválida").nullable(),
      unsuspend_webhook_type: Yup.number()
        .oneOf([1, 2], "Selecione um método válido")
        .nullable(),
      url_cancel_webhook: Yup.string().url("URL inválida").nullable(),
      cancel_webhook_type: Yup.number()
        .oneOf([1, 2], "Selecione um método válido")
        .nullable(),
      url_completing_webhook: Yup.string().url("URL inválida").nullable(),
      completing_webhook_type: Yup.number()
        .oneOf([1, 2], "Selecione um método válido")
        .nullable(),
      // Campos de E-mails
      activation_email_template_id: Yup.number()
        .nullable()
        .oneOf(
          productGroups.map((pg) => pg.value),
          "Selecione um template válido"
        ),
      suspension_email_template_id: Yup.number()
        .nullable()
        .oneOf(
          productGroups.map((pg) => pg.value),
          "Selecione um template válido"
        ),
      cancellation_email_template_id: Yup.number()
        .nullable()
        .oneOf(
          productGroups.map((pg) => pg.value),
          "Selecione um template válido"
        ),
    }),
    onSubmit: async (values) => {
      const keys = Object.keys(realInitialValues);
      const body = {};

      keys.forEach((key) => {
        if (realInitialValues[key] !== values[key] || key === "available_for_sale") {

          if(key === "available_for_sale"){
            if(values[key]){
              body["status"] = 1;
            } else if(!values[key]){
              body["status"] = 0;
            } 
          } else if(key === "price_type"){
            if(values[key] === "free"){
              body[key] = 0;
            } else if(values[key] === "one_time"){
              body[key] = 1;
            } else if(values[key] === "recurrence"){
              body[key] = 2;
            } else {
              body[key] = values[key];
            } 
          } else {
            body[key] = values[key];
          }
        }
      });

      try {
        body["stock"] = 0;
        body["recurrence_limit"] = 0;
        body["create_webhook_type"] = 1;
        body["suspend_webhook_type"] = 1;
        body["unsuspend_webhook_type"] = 1;
        body["cancel_webhook_type"] = 1;
        console.log("Dados enviados:", body);
        const res = id
          ? await ProductService.updateProduct(id, body)
          : await ProductService.createProduct(body);

        if (res.error) {
          formik.setErrors(res);
          if (res.error?.data.message) {
            toast.error(res.error.data.message);
          }
        } else {
          formik.resetForm();
          navigate("/products/create", { replace: true });

          Swal.fire({
            title: id ? "Produto atualizado com sucesso!" : "Produto criado com sucesso!",
            text: "Você foi redirecionado para a tela listagem de produtos.",
            icon: "success",
            confirmButtonColor: "#3E97FF",
          });
        }
      } catch (error) {
        console.log("Erro ao submeter formulário:", error);
        if (axios.isAxiosError(error) && error.response) {
          const responseData = error.response.data;
          if (responseData && responseData.errors) {
            formik.setErrors(responseData.errors);
          }
        } else {
          toast.error("Ocorreu um erro inesperado!");
          console.error(error);
        }
      }
    },
  });

  const findErrorFormik = (formikErros, values) => {
    return formikErros.find((erro) => values.includes(erro));
  };

  useEffect(() => {
    if (formik.errors && mustRedirectSubmit.value) {
      const formikErros = Object.keys(formik.errors);

      if (
        findErrorFormik(formikErros, [
          "name",
          // "group_id",
          "available_for_sale",
        ])
      ) {
        setMustRedirectSubmit({ value: false });
        setMenuActive(0);
        return;
      }

      if (
        findErrorFormik(formikErros, [
          "recurrence_limit",
          "price_type",
          "monthly",
          "monthly_setup_fee",
        ])
      ) {
        setMustRedirectSubmit({ value: false });
        setMenuActive(1);
        return;
      }

      if (
        findErrorFormik(formikErros, [
          "days_generate_invoices_before_due",
          "days_suspend_product_after_due",
          "days_cancel_product_after_due",
          "days_first_invoice_due",
        ])
      ) {
        setMustRedirectSubmit({ value: false });
        setMenuActive(2);
        return;
      }

      // Adicione mais condições conforme necessário para outras seções

      setMustRedirectSubmit({ value: false });
    }
  }, [formik.errors, mustRedirectSubmit]);

  const getStatusMenuFrom = (menu, namesActiveMenuErro) => {
    if (
      Object.keys(formik.errors).some((el) =>
        namesActiveMenuErro.includes(el)
      ) &&
      Object.keys(formik.touched).some((el) => namesActiveMenuErro.includes(el))
    )
      return "error";

    if (menuActive === menu) return "process";

    if (menuActive > menu) return "finish";

    return "wait";
  };

  const set_periodicals_null = () => {
    periodicals.forEach((periodical) => {
      formik.setFieldValue(periodical, null);
      formik.setFieldValue(`${periodical}_setup_fee`, null);
      formik.setFieldValue(`${periodical}_enable`, false);
    });
  };

  return (
    <div className="">
      <section className="overflow-x-scroll xl:overflow-hidden">
        <div className="flex flex-col lg:flex-row gap-10 my-5 relative ">
          <div className="flex items-center justify-center pl-1 rounded-t-xl">
            <Steps
              direction={width > 1000 ? "vertical" : "horizontal"}
              current={menuActive}
              className="lg:w-[250px] mx-auto"
              size="small"
              items={[
                {
                  onClick: () => setMenuActive(0),
                  status: getStatusMenuFrom(0, [
                    "name",
                    // "group_id",
                    "available_for_sale",
                  ]),
                  className: "cursor-pointer",
                  title: "Detalhes",
                  description: "Especificações do Produto",
                },
                {
                  onClick: () => setMenuActive(1),
                  className: "cursor-pointer",
                  status: getStatusMenuFrom(1, [
                    "recurrence_limit",
                    "price_type",
                    "monthly",
                    "monthly_setup_fee",
                  ]),
                  title: "Preço",
                  description: "Custos, valores, tarifas",
                },
                {
                  onClick: () => setMenuActive(2),
                  status: getStatusMenuFrom(2, [
                    "days_generate_invoices_before_due",
                    "days_suspend_product_after_due",
                    "days_cancel_product_after_due",
                    "days_first_invoice_due",
                  ]),
                  className: "cursor-pointer",
                  title: "Faturas",
                  description: "Configurações financeiras",
                },
                {
                  onClick: () => setMenuActive(3),
                  status: getStatusMenuFrom(3, [
                    "activation_email_template_id",
                    "suspension_email_template_id",
                    "cancellation_email_template_id",
                  ]),
                  className: "cursor-pointer",
                  title: "E-mails",
                  description: "Correspondência eletrônica",
                },
                {
                  onClick: () => setMenuActive(4),
                  status: getStatusMenuFrom(4, [
                    "create_webhook_type",
                    "suspend_webhook_type",
                    "unsuspend_webhook_type",
                    "cancel_webhook_type",
                    "completing_webhook_type",
                  ]),
                  className: "cursor-pointer",
                  title: "WebHook",
                  description: "Integração web",
                },
              ]}
            />
          </div>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              formik.handleSubmit(e);
              setMustRedirectSubmit({ value: true });
            }}
            className="flex xl:gap-8 grid grid-cols-12 w-full h-[440px] overflow-y-auto pl-1 pr-1 scroll-smooth"
            id="add-product-form"
          >
            {menuActive === 0 && (
              <section className="col-span-12" id="0_section">
                <div className="flex items-center mb-4 justify-between">
                  <h6 className="text-xl font-bold font-nunito ">
                    Detalhes
                  </h6>

                  <Button
                    variant="outline-primary"
                    size="sm"
                    as={Link}
                    to="/products/create"
                    className="w-24"
                  >
                    Voltar
                  </Button>
                </div>
                <div className="flex col-span-12 gap-4 grid grid-cols-12">
                  <div className="grid grid-cols-6 col-span-12 lg:col-span-6 gap-4 max-h-[210px]">
                    <Input
                      name="name"
                      label="Nome"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                      error={
                        (formik.touched.name || undefined) && formik.errors.name
                      }
                      containerClassName="col-span-12"
                    />
                    {/* <SelectBox
                      label="Grupo"
                      name="group_id"
                      value={formik.values.group_id}
                      onChange={(value) =>
                        formik.setFieldValue("group_id", value)
                      }
                      containerClassName="col-span-12"
                      error={
                        (formik.touched.group_id || undefined) &&
                        formik.errors.group_id
                      }
                      allowClear
                      options={[
                        { value: "", label: "Selecione um grupo" },
                        ...productGroups,
                      ]}
                    /> */}

                    <div className="col-span-6">
                      <FormLabel className="font-nunito text-[14px] font-semibold">
                        Disponível para venda
                      </FormLabel>
                      <div className="flex flex-col sm:flex-row">
                        <FormCheck className="mr-5">
                          <FormCheck.Input
                            id="available_for_sale_true"
                            type="radio"
                            checked={formik.values.available_for_sale}
                            onChange={() =>
                              formik.setFieldValue("available_for_sale", true)
                            }
                            name="available_for_sale"
                          />
                          <FormCheck.Label htmlFor="available_for_sale_true">
                            Sim
                          </FormCheck.Label>
                        </FormCheck>
                        <FormCheck className="mt-2 mr-2 sm:mt-0">
                          <FormCheck.Input
                            id="available_for_sale_false"
                            type="radio"
                            checked={!formik.values.available_for_sale}
                            onChange={() =>
                              formik.setFieldValue("available_for_sale", false)
                            }
                            name="available_for_sale"
                          />
                          <FormCheck.Label htmlFor="available_for_sale_false">
                            Não
                          </FormCheck.Label>
                        </FormCheck>
                      </div>
                      <div>
                        <p className="text-danger">
                          {(formik.touched.available_for_sale || undefined) &&
                            formik.errors.available_for_sale}
                        </p>
                      </div>
                    </div>

                    <div className="col-span-12">
                      <FormLabel className="font-nunito text-[14px] font-semibold">
                        Descrição
                      </FormLabel>
                      <FormTextarea
                        className="min-h-[102px] max-h-[210px] col-span-10"
                        value={formik.values.description}
                        onChange={(e) =>
                          formik.setFieldValue("description", e.target.value)
                        }
                      />
                      <p className="text-danger">
                        {(formik.touched.description || undefined) &&
                          formik.errors.description}
                      </p>
                    </div>
                  </div>
                </div>
              </section>
            )}

            {menuActive === 1 && (
              <section className="col-span-12" id="1_section">
                <div>
                  <h6 className="text-xl font-bold font-nunito mb-4">Preço</h6>
                </div>

                <div className="grid grid-cols-12 col-span-6 gap-4">
                  <SelectBox
                    label="Tipo de Pagamento"
                    name="price_type"
                    value={formik.values.price_type}
                    onChange={(value) => {
                      formik.setFieldValue("price_type", value);

                      if (!id) {
                        set_periodicals_null();

                        if (value === "free") {
                          periodicals.forEach((periodical) => {
                            formik.setFieldValue(`${periodical}_enable`, false);
                            formik.setFieldValue(`${periodical}`, null);
                            formik.setFieldValue(`${periodical}_setup_fee`, null);
                          });
                        } else if (value === "one_time") {
                          formik.setFieldValue("monthly_enable", true);
                          formik.setFieldValue("monthly", 0);
                          formik.setFieldValue("monthly_setup_fee", 0);
                        } else if (value === "recurrence") {
                          periodicals.forEach((periodical) => {
                            formik.setFieldValue(`${periodical}_enable`, true);
                            formik.setFieldValue(`${periodical}`, 0);
                            formik.setFieldValue(`${periodical}_setup_fee`, 0);
                          });
                        }
                      }
                    }}
                    containerClassName="col-span-12 lg:col-span-6"
                    error={
                      (formik.touched.price_type || undefined) &&
                      formik.errors.price_type
                    }
                    allowClear
                    options={[
                      { label: "Selecione uma opção", value: "" },
                      { label: "Gratuito", value: "free" },
                      { label: "Única Vez", value: "one_time" },
                      { label: "Recorrente", value: "recurrence" },
                    ]}
                  />
                  <div className="col-span-6"></div>
                  {formik.values.price_type === "recurrence" && (
                    <Input
                      name="recurrence_limit"
                      label="Limite para ciclos recorrentes"
                      type="number"
                      onChange={(e) => {
                        formik.setFieldValue(
                          "recurrence_limit",
                          Number(e.target.value)
                        );
                      }}
                      value={formik.values.recurrence_limit}
                      error={
                        (formik.touched.recurrence_limit ||
                          undefined) &&
                        formik.errors.recurrence_limit
                      }
                      containerClassName="col-span-6"
                    />
                  )}
                  {formik.values.price_type === "one_time" && (
                    <section className="overflow-y-auto xl:overflow-hidden grid col-span-12">
                      <Table className="w-fit">
                        <Table.Thead>
                          <Table.Tr className="bg-[#F9F9F9] h-[35px] pb-2">
                            <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold rounded-l text-center">
                              {/* Cabeçalho vazio ou título */}
                            </Table.Th>

                            <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold w-full">
                              Uma vez
                            </Table.Th>
                          </Table.Tr>
                        </Table.Thead>

                        <Table.Tbody>
                          <Table.Tr
                            className="intro-x border-b"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] whitespace-nowrap last:rounded-r-[0.6rem] dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                              Taxa de Setup
                            </Table.Td>

                            <Table.Td className="rounded-l-none rounded-r-none last:rounded-r-[0.6rem] dark:bg-darkmode-600 whitespace-nowrap font-nunito text-[#7E8299] text-[14px] font-normal px-2">
                              <BrlCurrencyComponent
                                name="monthly_setup_fee"
                                containerClassName="w-[90px]"
                                value={formik.values.monthly_setup_fee}
                                handleChange={(e, value) => {
                                  formik.setFieldValue("monthly_setup_fee", value);
                                }}
                              />
                              {formik.errors.monthly_setup_fee && (
                                <p className="text-xs text-danger mt-1">
                                  {formik.errors.monthly_setup_fee}
                                </p>
                              )}
                            </Table.Td>
                          </Table.Tr>
                          <Table.Tr
                            className="intro-x border-b"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] whitespace-nowrap last:rounded-r-[0.6rem] dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                              Preço
                            </Table.Td>

                            <Table.Td className="rounded-l-none rounded-r-none last:rounded-r-[0.6rem] dark:bg-darkmode-600 whitespace-nowrap font-nunito text-[#7E8299] text-[14px] font-normal px-2">
                              <BrlCurrencyComponent
                                name="monthly"
                                containerClassName="w-[90px] block mx-auto"
                                value={formik.values.monthly}
                                handleChange={(e, value) => {
                                  formik.setFieldValue("monthly", value);
                                }}
                              />
                              {formik.errors.monthly && (
                                <p className="text-xs text-danger mt-1">
                                  {formik.errors.monthly}
                                </p>
                              )}
                            </Table.Td>
                          </Table.Tr>
                        </Table.Tbody>
                      </Table>
                    </section>
                  )}
                  {formik.values.price_type === "recurrence" && (
                    <section className="overflow-auto grid col-span-12">
                      <Table>
                        <Table.Thead>
                          <Table.Tr className="bg-[#F9F9F9] h-[35px] pb-2">
                            <Table.Th className="color-[#67759A] text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold rounded-l text-center">
                              {/* Cabeçalho vazio ou título */}
                            </Table.Th>

                            {periodicals.map((periodical) => (
                              <Table.Th
                                key={periodical}
                                className="color-[#67759A] text-[#67759A] text-[14px] font-nunito whitespace-nowrap font-semibold text-center"
                              >
                                {periodical.charAt(0).toUpperCase() + periodical.slice(1)}
                              </Table.Th>
                            ))}
                          </Table.Tr>
                        </Table.Thead>

                        <Table.Tbody>
                          <Table.Tr
                            className="intro-x border-b"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] whitespace-nowrap last:rounded-r-[0.6rem] dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                              Taxa de Setup
                            </Table.Td>

                            {periodicals.map((periodical) => {
                              const setupFeeName = `${periodical}_setup_fee`;

                              if (!formik.values[`${periodical}_enable`])
                                return (
                                  <td key={setupFeeName} className="min-w-[90px] p-2">
                                    <div className="min-w-[90px] min-h-[38px]"></div>
                                  </td>
                                );

                              return (
                                <Table.Td
                                  key={setupFeeName}
                                  className="rounded-l-none rounded-r-none dark:bg-darkmode-600 whitespace-nowrap font-nunito text-[#7E8299] text-[14px] font-normal text-center px-2"
                                >
                                  <BrlCurrencyComponent
                                    name={setupFeeName}
                                    containerClassName="w-[90px] block mx-auto"
                                    value={formik.values[setupFeeName]}
                                    handleChange={(e, value) => {
                                      formik.setFieldValue(setupFeeName, value);
                                    }}
                                  />
                                  {formik.errors[setupFeeName] && (
                                    <p className="text-xs text-danger mt-1">
                                      {formik.errors[setupFeeName]}
                                    </p>
                                  )}
                                </Table.Td>
                              );
                            })}
                          </Table.Tr>
                          <Table.Tr
                            className="intro-x border-b"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] whitespace-nowrap last:rounded-r-[0.6rem] dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                              Preço
                            </Table.Td>

                            {periodicals.map((periodical) => {
                              if (!formik.values[`${periodical}_enable`])
                                return (
                                  <td key={periodical} className="min-w-[90px] p-2">
                                    <div className="min-w-[90px] min-h-[38px]"></div>
                                  </td>
                                );

                              return (
                                <Table.Td
                                  key={periodical}
                                  className="rounded-l-none rounded-r-none dark:bg-darkmode-600 whitespace-nowrap font-nunito text-[#7E8299] text-[14px] font-normal text-center px-2"
                                >
                                  <BrlCurrencyComponent
                                    name={periodical}
                                    containerClassName="w-[90px] block mx-auto"
                                    value={formik.values[periodical]}
                                    handleChange={(e, value) => {
                                      formik.setFieldValue(periodical, value);
                                    }}
                                  />
                                  {formik.errors[periodical] && (
                                    <p className="text-xs text-danger mt-1">
                                      {formik.errors[periodical]}
                                    </p>
                                  )}
                                </Table.Td>
                              );
                            })}
                          </Table.Tr>
                          <Table.Tr
                            className="intro-x border-b"
                            onClick={(e) => {
                              e.stopPropagation();
                            }}
                          >
                            <Table.Td className="rounded-l-none rounded-r-none first:rounded-l-[0.6rem] whitespace-nowrap last:rounded-r-[0.6rem] dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                              Disponível
                            </Table.Td>

                            {periodicals.map((periodical) => {
                              const enableName = `${periodical}_enable`;

                              return (
                                <Table.Td
                                  key={enableName}
                                  className="rounded-l-none rounded-r-none dark:bg-darkmode-600 whitespace-nowrap font-nunito text-[#7E8299] text-[14px] font-normal"
                                >
                                  <FormCheck className="mt-2 sm:mt-0 text-center">
                                    <FormCheck.Input
                                      id={enableName}
                                      type="checkbox"
                                      className="mx-auto"
                                      checked={formik.values[enableName]}
                                      onChange={(e) =>
                                        formik.setFieldValue(enableName, e.target.checked)
                                      }
                                      name={enableName}
                                    />
                                  </FormCheck>
                                </Table.Td>
                              );
                            })}
                          </Table.Tr>
                        </Table.Tbody>
                      </Table>
                    </section>
                  )}
                </div>
              </section>
            )}

            {menuActive === 2 && (
              <section className="col-span-12" id="2_section">
                <div>
                  <h6 className="text-xl font-bold font-nunito mb-4">
                    Faturas
                  </h6>
                </div>
                {formik.values.price_type === "free" ? (
                  <div className="h-[350px] text-primary flex items-center justify-center flex-col">
                    <Lucide
                      icon="AlertTriangle"
                      className="w-8 h-8 mb-1"
                    />
                    <p className="font-nunito text-md text-center">
                      As opções de faturas estão disponíveis apenas para{" "}
                      <br />
                      produtos pagos. Se for esse o caso,{" "}
                      <button
                        onClick={() => setMenuActive(1)}
                        className="btn text-slate-700 font-bold"
                      >
                        clique aqui
                      </button>{" "}
                      para fazer a alteração.
                    </p>
                  </div>
                ) : (
                  <div className="grid grid-cols-12 col-span-12 gap-4">
                    <SelectBox
                      label="Gerar faturas quantos dias antes do vencimento"
                      name="days_generate_invoices_before_due"
                      showSearch
                      value={formik.values.days_generate_invoices_before_due}
                      onChange={(value) =>
                        formik.setFieldValue(
                          "days_generate_invoices_before_due",
                          value
                        )
                      }
                      containerClassName="col-span-6"
                      error={
                        (formik.touched.days_generate_invoices_before_due ||
                          undefined) &&
                        formik.errors.days_generate_invoices_before_due
                      }
                      allowClear
                      options={[
                        { value: "", label: "Selecione uma opção" },
                        ...Array.from({ length: 31 }, (_, i) => ({
                          label: `${i}`,
                          value: `${i}`,
                        })),
                      ]}
                    />
                    <div className="col-span-6"></div>
                    <SelectBox
                      label="Após gerar o pedido, a 1ª fatura vencerá em quantos dias"
                      name="days_first_invoice_due"
                      showSearch
                      value={formik.values.days_first_invoice_due}
                      onChange={(value) =>
                        formik.setFieldValue("days_first_invoice_due", value)
                      }
                      containerClassName="col-span-6"
                      error={
                        (formik.touched.days_first_invoice_due || undefined) &&
                        formik.errors.days_first_invoice_due
                      }
                      allowClear
                      options={[
                        { value: "", label: "Selecione uma opção" },
                        { label: "Não suspender", value: "not_suspend" },
                        ...Array.from({ length: 31 }, (_, i) => ({
                          label: `${i}`,
                          value: `${i}`,
                        })),
                      ]}
                    />
                    <div className="col-span-6"></div>
                    <SelectBox
                      label="Informe quantos dias após o vencimento para suspendermos o produto."
                      name="days_suspend_product_after_due"
                      showSearch
                      value={formik.values.days_suspend_product_after_due}
                      onChange={(value) =>
                        formik.setFieldValue(
                          "days_suspend_product_after_due",
                          value
                        )
                      }
                      containerClassName="col-span-6"
                      error={
                        (formik.touched.days_suspend_product_after_due ||
                          undefined) &&
                        formik.errors.days_suspend_product_after_due
                      }
                      allowClear
                      options={[
                        { value: "", label: "Selecione uma opção" },
                        { label: "Não cancelar", value: "not_cancel" },
                        ...Array.from({ length: 31 }, (_, i) => ({
                          label: `${i}`,
                          value: `${i}`,
                        })),
                      ]}
                    />
                    <div className="col-span-6"></div>
                    <SelectBox
                      label="Informe quantos dias após o vencimento para cancelarmos o produto."
                      name="days_cancel_product_after_due"
                      showSearch
                      value={formik.values.days_cancel_product_after_due}
                      onChange={(value) =>
                        formik.setFieldValue(
                          "days_cancel_product_after_due",
                          value
                        )
                      }
                      containerClassName="col-span-6"
                      error={
                        (formik.touched.days_cancel_product_after_due ||
                          undefined) &&
                        formik.errors.days_cancel_product_after_due
                      }
                      allowClear
                      options={[
                        { value: "", label: "Selecione uma opção" },
                        { label: "Não cancelar", value: "not_cancel" },
                        ...Array.from({ length: 31 }, (_, i) => ({
                          label: `${i}`,
                          value: `${i}`,
                        })),
                      ]}
                    />
                  </div>
                )}
              </section>
            )}

            {menuActive === 3 && (
              <section className="col-span-12" id="3_section">
                <div>
                  <h6 className="text-xl font-bold font-nunito mb-4">
                    E-mails
                  </h6>
                </div>
                <div className="grid grid-cols-12 col-span-12 gap-4">
                  <SelectBox
                    label="E-mail de ativação do produto/serviço"
                    name="activation_email_template_id"
                    value={formik.values.activation_email_template_id}
                    onChange={(value) =>
                      formik.setFieldValue("activation_email_template_id", value)
                    }
                    containerClassName="col-span-12"
                    error={
                      (formik.touched.activation_email_template_id || undefined) &&
                      formik.errors.activation_email_template_id
                    }
                    allowClear
                    options={[
                      { value: "", label: "Selecione uma opção" },
                      // Adicione opções de templates de e-mail aqui
                      // Exemplo:
                      // { value: 1, label: "Template 1" },
                      // { value: 2, label: "Template 2" },
                    ]}
                  />
                  <div className="col-span-6"></div>
                  <SelectBox
                    label="E-mail de suspensão do produto/serviço"
                    name="suspension_email_template_id"
                    value={formik.values.suspension_email_template_id}
                    onChange={(value) =>
                      formik.setFieldValue("suspension_email_template_id", value)
                    }
                    containerClassName="col-span-12"
                    error={
                      (formik.touched.suspension_email_template_id || undefined) &&
                      formik.errors.suspension_email_template_id
                    }
                    allowClear
                    options={[
                      { value: "", label: "Selecione uma opção" },
                      // Adicione opções de templates de e-mail aqui
                    ]}
                  />
                  <div className="col-span-6"></div>
                  <SelectBox
                    label="E-mail de cancelamento do produto/serviço"
                    name="cancellation_email_template_id"
                    value={formik.values.cancellation_email_template_id}
                    onChange={(value) =>
                      formik.setFieldValue("cancellation_email_template_id", value)
                    }
                    containerClassName="col-span-12"
                    error={
                      (formik.touched.cancellation_email_template_id || undefined) &&
                      formik.errors.cancellation_email_template_id
                    }
                    allowClear
                    options={[
                      { value: "", label: "Selecione uma opção" },
                      // Adicione opções de templates de e-mail aqui
                    ]}
                  />
                </div>
              </section>
            )}

            {menuActive === 4 && (
              <section className="col-span-12 pb-5" id="4_section">
                <div>
                  <h6 className="text-xl font-bold font-nunito mb-4">
                    WebHook
                  </h6>
                </div>
                <div className="grid grid-cols-12 col-span-12 md:gap-4">
                  {/* WebHook Criar Produto */}
                  <div className="grid grid-cols-12 col-span-12 gap-10 md:gap-4">
                    <SelectBox
                      label="Método"
                      name="create_webhook_type"
                      value={formik.values.create_webhook_type}
                      onChange={(value) =>
                        formik.setFieldValue("create_webhook_type", value)
                      }
                      containerClassName="col-span-6 md:col-span-2 h-[30px]"
                      error={
                        (formik.touched.create_webhook_type || undefined) &&
                        formik.errors.create_webhook_type
                      }
                      allowClear
                      options={[
                        { value: "", label: "Selecione uma opção" },
                        { label: "GET", value: 1 },
                        { label: "POST", value: 2 },
                        { label: "PUT", value: 3 },
                        { label: "DELETE", value: 4 },
                      ]}
                    />
                    <Input
                      name="url_create_webhook"
                      label="Executar URL ao criar produto/serviço"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.url_create_webhook}
                      error={
                        (formik.touched.url_create_webhook || undefined) &&
                        formik.errors.url_create_webhook
                      }
                      containerClassName="col-span-12 md:col-span-10"
                    />
                  </div>

                  {/* WebHook Suspender Produto */}
                  <div className="grid grid-cols-12 col-span-12 gap-10 md:gap-4">
                    <SelectBox
                      label="Método"
                      name="suspend_webhook_type"
                      value={formik.values.suspend_webhook_type}
                      onChange={(value) =>
                        formik.setFieldValue("suspend_webhook_type", value)
                      }
                      containerClassName="col-span-6 md:col-span-2 h-[30px]"
                      error={
                        (formik.touched.suspend_webhook_type || undefined) &&
                        formik.errors.suspend_webhook_type
                      }
                      allowClear
                      options={[
                        { value: "", label: "Selecione uma opção" },
                        { label: "GET", value: 1 },
                        { label: "POST", value: 2 },
                        { label: "PUT", value: 3 },
                        { label: "DELETE", value: 4 },
                      ]}
                    />
                    <Input
                      name="url_suspend_webhook"
                      label="Executar URL ao suspender produto/serviço"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.url_suspend_webhook}
                      error={
                        (formik.touched.url_suspend_webhook || undefined) &&
                        formik.errors.url_suspend_webhook
                      }
                      containerClassName="col-span-12 md:col-span-10"
                    />
                  </div>

                  {/* WebHook Reativar Produto */}
                  <div className="grid grid-cols-12 col-span-12 gap-10 md:gap-4">
                    <SelectBox
                      label="Método"
                      name="unsuspend_webhook_type"
                      value={formik.values.unsuspend_webhook_type}
                      onChange={(value) =>
                        formik.setFieldValue("unsuspend_webhook_type", value)
                      }
                      containerClassName="col-span-6 md:col-span-2 h-[30px]"
                      error={
                        (formik.touched.unsuspend_webhook_type || undefined) &&
                        formik.errors.unsuspend_webhook_type
                      }
                      allowClear
                      options={[
                        { value: "", label: "Selecione uma opção" },
                        { label: "GET", value: 1 },
                        { label: "POST", value: 2 },
                        { label: "PUT", value: 3 },
                        { label: "DELETE", value: 4 },
                      ]}
                    />
                    <Input
                      name="url_unsuspend_webhook"
                      label="Executar URL ao reativar produto/serviço"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.url_unsuspend_webhook}
                      error={
                        (formik.touched.url_unsuspend_webhook || undefined) &&
                        formik.errors.url_unsuspend_webhook
                      }
                      containerClassName="col-span-12 md:col-span-10"
                    />
                  </div>

                  {/* WebHook Cancelar Produto */}
                  <div className="grid grid-cols-12 col-span-12 gap-10 md:gap-4">
                    <SelectBox
                      label="Método"
                      name="cancel_webhook_type"
                      value={formik.values.cancel_webhook_type}
                      onChange={(value) =>
                        formik.setFieldValue("cancel_webhook_type", value)
                      }
                      containerClassName="col-span-6 md:col-span-2 h-[30px]"
                      error={
                        (formik.touched.cancel_webhook_type || undefined) &&
                        formik.errors.cancel_webhook_type
                      }
                      allowClear
                      options={[
                        { value: "", label: "Selecione uma opção" },
                        { label: "GET", value: 1 },
                        { label: "POST", value: 2 },
                        { label: "PUT", value: 3 },
                        { label: "DELETE", value: 4 },
                      ]}
                    />
                    <Input
                      name="url_cancel_webhook"
                      label="Executar URL ao cancelar produto/serviço"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.url_cancel_webhook}
                      error={
                        (formik.touched.url_cancel_webhook || undefined) &&
                        formik.errors.url_cancel_webhook
                      }
                      containerClassName="col-span-12 md:col-span-10"
                    />
                  </div>

                  {/* WebHook Completar Produto */}
                  <div className="grid grid-cols-12 col-span-12 gap-10 md:gap-4">
                    <SelectBox
                      label="Método"
                      name="completing_webhook_type"
                      value={formik.values.completing_webhook_type}
                      onChange={(value) =>
                        formik.setFieldValue("completing_webhook_type", value)
                      }
                      containerClassName="col-span-6 md:col-span-2 h-[30px]"
                      error={
                        (formik.touched.completing_webhook_type || undefined) &&
                        formik.errors.completing_webhook_type
                      }
                      allowClear
                      options={[
                        { value: "", label: "Selecione uma opção" },
                        { label: "GET", value: 1 },
                        { label: "POST", value: 2 },
                        { label: "PUT", value: 3 },
                        { label: "DELETE", value: 4 },
                      ]}
                    />
                    <Input
                      name="url_completing_webhook"
                      label="Executar URL ao Completar produto/serviço"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.url_completing_webhook}
                      error={
                        (formik.touched.url_completing_webhook || undefined) &&
                        formik.errors.url_completing_webhook
                      }
                      containerClassName="col-span-12 md:col-span-10"
                    />
                  </div>
                </div>
              </section>
            )}
          </form>
        </div>
        <div className="flex gap-3 w-full items-end justify-end pb-1 pr-1">
          {menuActive > 0 && (
            <Button
              type="button"
              variant="outline-primary"
              onClick={() => setMenuActive((old) => old - 1)}
            >
              Retornar
            </Button>
          )}

          {menuActive < 4 && (
            <Button
              type="button"
              variant="outline-primary"
              onClick={() => setMenuActive((old) => old + 1)}
            >
              Avançar
            </Button>
          )}

          {menuActive === 4 && (
            <Button type="submit" variant="primary" form="add-product-form">
              {id ? "Salvar" : "Cadastrar"}
            </Button>
          )}
        </div>
      </section>
    </div>
  );
};

export default ManageProductService;
