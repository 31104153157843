import Lucide from "../../../components/Base/Lucide";
import Check from "../../../assets/images/check.svg";
import ArrowBack from "../../../assets/images/arrow-back.svg";
import CrossCircle from "../../../assets/images/cross-circle.svg";
import Wallet from "../../../assets/images/wallet.svg";
import Alert2 from "../../../assets/images/alert2.svg";
import SelectBox from "../../../components/Form/SelectBox";
import Table from "../../../components/Base/Table";
import _ from "lodash";
import fakerData from "../../../utils/faker";
import Badge from "../../../components/Badge";
import { Link, useNavigate, useParams } from "react-router-dom";
import AlertSVG from "../../../assets/images/alert.png";
import Star from "../../../assets/images/Star_icon_stylized.svg.png";
import Button from "../../../components/Base/Button";
import { useState, useEffect } from "react";
import { formatCnpjCpf, formatDate, formatPhoneNumber } from "../../../utils/helpers";
import Swal from "sweetalert2";
import CustomerService from "../../Customers/Services/CustomerService";
// import { useCustomer } from "../../layout/BaseSecondary";
import OrdersSumaryModal from "../Modals/OrdersSumaryModal";
import { useTranslation } from 'react-i18next';
import EmailService from "../Emails/Services/EmailService";

const Overview = () => {
  const navigate = useNavigate();
  const { customer_id } = useParams();
  const [customer, setCustomer] = useState(null);
  const [emails, setEmails] = useState([]); // Estado para armazenar os e-mails

  const [ordersCustomerSumaryModal, setOrdersCustomerSumaryModal] = useState({
    active: false,
    id: null,
  });
  const { t } = useTranslation();

  useEffect(() => {
    const fetchCustomer = async () => {
      try {
        const response = await CustomerService.getCustomerById(customer_id);
        setCustomer(response.data);
      } catch (error) {
        console.error("Erro ao buscar cliente:", error);
      }
    };

    const fetchEmails = async () => {
      try {
        const response = await EmailService.listEmails({
          limit: 5,
          page: 1,
          customer_id: customer_id,
        });
        setEmails(response.data || []); // Ajuste para lidar com estrutura da API
      } catch (error) {
        console.error("Erro ao buscar e-mails:", error);
      }
    };

    fetchCustomer();
    fetchEmails();
  }, [customer_id]);

  return (
    <>
      <div className="flex flex-col lg:flex-row gap-5">
      <section className="md:min-w-[460px] bg-white rounded-xl font-nunito intro-x shadow-custom-1">
          <div className="flex flex-wrap items-center justify-between py-4 gap-3 xl:gap-0 border-b sm:py-0 sm:h-[58px] px-5">
            <h6 className="text-[18px] font-semibold text-[#3F4254]">
            {t('000222')}
            </h6>
            <Button
              variant="soft-secondary"
              size="sm"
              as={Link}
              to={`/customers/${customer?.customer_id}/profile`}
              className="font-nunito text-xs font-semibold"
            >
              <Lucide icon="Pencil" className="mr-1 w-4 rounded" />
              Editar dados
            </Button>
          </div>
          <div className="p-5">
            <ul className="flex flex-col gap-[12px] font-nunito font-medium leading-[18px] text-[14px] text-[#A1A5B7] overflow-hidden">
              <li className="flex">
                <span className="min-w-[132px]">Nome: </span>
                <span className="text-[#3F4254]">{customer?.name}</span>
              </li>
              <li className="flex">
                <span className="min-w-[132px] ">Empresa: </span>
                <span className="text-[#3F4254]">{customer?.fantasy_name}</span>
              </li>
              <li className="flex">
                <span className="min-w-[132px] ">CPF / CNPJ: </span>
                <span className="text-[#3F4254]">
                  {formatCnpjCpf(customer?.taxpayer_id)}
                </span>
              </li>
              <li className="flex">
                <span className="min-w-[132px] ">E-mail</span>
                <span className="text-[#3F4254]">{customer?.email}</span>
              </li>
              <li className="flex">
                <span className="min-w-[132px] ">CEP: </span>
                <span className="text-[#3F4254]">
                  {customer?.addresses[0]?.zip_code}
                </span>
              </li>
              <li className="flex">
                <span className="min-w-[132px] ">Endereço: </span>
                <span className="text-[#3F4254]">
                  {customer?.addresses[0]
                    ? `${customer.addresses[0].street || ""}, ${customer.addresses[0].city || ""}`
                    : ""}
                </span>
              </li>
              <li className="flex">
                <span className="min-w-[132px] ">Telefone: </span>
                <span className="text-[#3F4254]">
                  {formatPhoneNumber(customer?.phonenumber_primary)}
                </span>
              </li>
              <li className="flex">
                <span className="min-w-[132px] ">Data do cadastro: </span>
                <span className="text-[#3F4254]">
                  {formatDate(customer?.created_at, "DD/MM/YYYY HH[h]mm[m]")}
                </span>
              </li>
              <li className="flex">
                <span className="min-w-[132px] ">Status: </span>
                <Badge text={customer?.status} type="success" />
              </li>
            </ul>
          </div>
        </section>

        <section className="w-full flex flex-col gap-5 intro-x">
          <section className="bg-white w-full rounded-xl font-nunito shadow-custom-1">
            <div className="flex flex-wrap items-center justify-between py-4 gap-3 xl:gap-0 border-b sm:py-0 sm:h-[58px] px-5">
              <h6 className="text-[18px]  font-semibold text-[#3F4254]">
                Produtos/Serviços
              </h6>
              <Button
                variant="soft-secondary"
                className="font-nunito text-xs"
                size="sm"
              >
                <Lucide icon="Eye" className="mr-2"></Lucide>
                Visualizar Produtos/Serviços
              </Button>
            </div>
            <div className="p-5">
              <div className="flex flex-col md:flex-row md:flex-col xl:flex-row  gap-4">
                <div className="w-full flex flex-col gap-5">
                  <div className="flex items-center border border-dashed  px-2 gap-[10px] h-[60px]">
                    <div>
                      <img src={Star} alt="" className="w-[32px]" />
                    </div>
                    <div className="flex flex-col gap-[4px] font-inter font-semibold">
                      <h6 className="text-[#181C32] text-[16px]  leading-[16px]">
                        {customer?.products && customer?.products.length}
                      </h6>
                      <p className="leading-[12px] text-[12px] text-[#A1A5B7]">
                        Produtos Ativos
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <Button
                      variant="soft-secondary"
                      size="sm"
                      className="font-nunito text-xs font-semibold w-full"
                      onClick={() =>
                        navigate(`/orders/create?customer_id=${customer?.customer_id}`)
                      }
                    >
                      <Lucide icon="Plus" className="mr-1 rounded " />
                      Criar pedido
                    </Button>
                    <Button
                      variant="soft-secondary"
                      size="sm"
                      onClick={() => {
                        setOrdersCustomerSumaryModal({
                          active: true,
                          id: customer?.customer_id,
                        });
                      }}
                      className="font-nunito text-xs font-semibold w-full"
                    >
                      <Lucide icon="Eye" className="mr-1 rounded" />
                      Visualizar pedidos
                    </Button>
                  </div>
                </div>
                <div className="w-full flex flex-col gap-5">
                  <div className="flex items-center border border-dashed  px-2 gap-[10px]  h-[60px]">
                    <div>
                      <img src={AlertSVG} alt="" className="w-[38px] " />
                    </div>
                    <div className="flex flex-col gap-[4px] font-inter font-semibold">
                      <h6 className="text-[#181C32] text-[16px]  leading-[16px]">
                        0
                      </h6>
                      <p className="leading-[12px] text-[12px] text-[#A1A5B7]">
                        Tickets Abertos
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <Button
                      variant="soft-secondary"
                      size="sm"
                      className="font-nunito text-xs font-semibold w-full"
                      as={Link}
                      to="/tickets/create"
                    >
                      <Lucide icon="Plus" className="mr-1 rounded" />
                      Abrir ticket
                    </Button>

                    <Button
                      variant="soft-secondary"
                      size="sm"
                      className="font-nunito text-xs font-semibold w-full"
                    >
                      <Lucide icon="Eye" className="mr-1 rounded" />
                      Visualizar tickets
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="bg-white w-full h-full rounded-xl font-nunito shadow-custom-1">
            <div className="flex justify-between items-center border-b h-[58px] px-5">
              <h6 className="text-[18px] font-semibold text-[#3F4254]">
                Faturamento
              </h6>
            </div>

            <div className="p-5 grid grid-cols-12 gap-4 pt-7">
              <div className="col-span-12 md:col-span-8 lg:col-span-6 xl:col-span-8">
                <div className="grid grid-cols-12 gap-[17px] font-inter">
                  <div className="flex items-center border border-dashed  p-2 gap-[10px] col-span-12 xl:col-span-6 h-[60px]">
                    <div>
                      <img src={Check} alt="" className="w-[32px]" />
                    </div>
                    <div className="flex flex-col gap-[4px] font-inter font-semibold">
                      <h6 className="text-[#181C32] text-[16px]  leading-[16px]">
                        R$ 0
                      </h6>
                      <p className="leading-[12px] text-[12px] text-[#A1A5B7] font-normal">
                        0 - Faturas pagas
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center border border-dashed  p-2 gap-[10px] col-span-12 xl:col-span-6 h-[60px]">
                    <div>
                      <img src={Alert2} alt="" className="w-[32px]" />
                    </div>
                    <div className="flex flex-col gap-[4px] font-inter font-semibold">
                      <h6 className="text-[#181C32] text-[16px]  leading-[16px]">
                        R$ 0
                      </h6>
                      <p className="leading-[12px] text-[12px] text-[#A1A5B7] font-normal">
                        0 - Faturas em aberto
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center border border-dashed  p-2 gap-[10px] col-span-12 xl:col-span-6 h-[60px]">
                    <div>
                      <img src={ArrowBack} alt="" className="w-[32px]" />
                    </div>
                    <div className="flex flex-col gap-[4px] font-inter font-semibold">
                      <h6 className="text-[#181C32] text-[16px]  leading-[16px]">
                        R$ 0
                      </h6>
                      <p className="leading-[12px] text-[12px] text-[#A1A5B7] font-normal">
                        0 - Faturas estornadas
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center border border-dashed  p-2 gap-[10px] col-span-12 xl:col-span-6 h-[60px]">
                    <div>
                      <img src={CrossCircle} alt="" className="w-[32px]" />
                    </div>
                    <div className="flex flex-col gap-[4px] font-inter font-semibold">
                      <h6 className="text-[#181C32] text-[16px]  leading-[16px]">
                        R$ 0
                      </h6>
                      <p className="leading-[12px] text-[12px] text-[#A1A5B7] font-normal">
                        0 - Faturas Canceladas
                      </p>
                    </div>
                  </div>
                </div>
                <div className="w-full mt-4">
                  <Button
                    variant="soft-secondary"
                    size="sm"
                    className="font-nunito text-xs font-semibold w-full"
                  >
                    <Lucide icon="RefreshCcw" className="mr-2 w-4" />
                    Forçar geração de faturas
                  </Button>
                </div>
              </div>

              <div className="col-span-12 md:col-span-4 lg:col-span-6 xl:col-span-4">
                <div className="flex items-center border border-dashed  p-2 gap-[10px] col-span-6  justify-center h-[calc(100%_-_47px)] ">
                  <div>
                    <img src={Wallet} alt="" className="w-[32px]" />
                  </div>
                  <div className="flex flex-col gap-[4px] font-inter font-semibold">
                    <h6 className="text-[#181C32] text-[16px]  leading-[16px]">
                      R$ 0
                    </h6>
                    <p className="leading-[12px] text-[12px] text-[#A1A5B7]">
                      Créditos do cliente
                    </p>
                  </div>
                </div>
                <div className="w-full mt-4">
                  <Button
                    variant="soft-secondary"
                    size="sm"
                    className="font-nunito text-xs font-semibold w-full"
                  >
                    <Lucide icon="Plus" className="mr-1" />
                    Adicionar Créditos
                  </Button>
                </div>
              </div>
            </div>
          </section>
        </section>
      </div>

      <div className="mt-5 flex flex-col xl:flex-row gap-5 font-nunito">
        <div className="flex flex-col md:flex-row xl:flex-col gap-5 ">
          <section className="bg-white w-full lg:max-w-[460px] xl:w-[460px] rounded-xl intro-x shadow-custom-1">
            <div className="flex justify-between items-center border-b h-[58px]  px-5">
              <h6 className="text-[18px] leading-[18px] font-semibold text-[#3F4254]">
                { t('000226') }
              </h6>
            </div>
            <div className="flex flex-col p-4 gap-4 p-5">
              <Button
                variant="soft-secondary"
                size="sm"
                className="font-nunito text-xs font-semibold w-full justify-start"
              >
                <Lucide icon="LogOut" className="mr-2 w-4" />
                { t('000226') }
              </Button>
              <Button
                variant="soft-secondary"
                size="sm"
                className="font-nunito text-xs font-semibold w-full justify-start"
                onClick={() => {
                  Swal.fire({
                    title: t('000228'),
                    text: t('000229'),
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3E97FF",
                    cancelButtonColor: "#d33",
                    cancelButtonText: t('000203'),
                    confirmButtonText: t('000230'),
                    preConfirm: async () => {
                      try {
                        Swal.showLoading();

                        await CustomerService.deleteCustomer(customer_id);
                      } catch (error) {
                        console.log(error);
                      }
                    },
                    allowOutsideClick: () => !Swal.isLoading(),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigate("/customers", { replace: true });
                      Swal.fire({
                        title: t('000231'),
                        text: t('000232'),
                        icon: "success",
                        confirmButtonColor: "#3E97FF",
                      });
                    }
                  });
                }}
              >
                <Lucide icon="Trash2" className="mr-2 w-4" />
                { t('000227') }
              </Button>
            </div>
          </section>

          <section className="bg-white w-full xl:max-w-[460px] rounded-xl intro-x shadow-custom-1">
            <div className="flex justify-between items-center border-b h-[58px] p-5">
              <h6 className="text-[18px] leading-[18px] font-semibold text-[#3F4254]">
                Enviar e-mail
              </h6>
            </div>
            <div className="flex flex-col p-4 gap-4 p-5">
              <SelectBox name="model-email"></SelectBox>
              <div className="text-end">
                <Button
                  variant="soft-secondary"
                  size="sm"
                  className="font-nunito text-xs font-semibold"
                >
                  <Lucide icon="Send" className="mr-2 w-4" />
                  Enviar
                </Button>
              </div>
            </div>
          </section>
        </div>

        <section className="bg-white w-full rounded-xl intro-x shadow-custom-1">
          <div className="flex justify-between items-center border-b h-[58px]  px-5">
            <h6 className="text-[18px] leading-[18px] font-semibold text-[#3F4254]">
              Últimos E-mails
            </h6>
          </div>
          <div className="col-span-12 overflow-auto intro-y lg:overflow-visible p-5">
            <Table className="border-spacing-y-[10px] border-separate -mt-2 rounded-xl">
              <Table.Thead className="bg-[#F4F6FA] h-[34px] ">
                <Table.Tr className="font-nunito text-xs font-semibold leading-[18px] text-[#181C32] rounded-xl">
                  <Table.Th className="border-b-0 whitespace-nowrap rounded-l">
                    DATA E HORA
                  </Table.Th>
                  <Table.Th className="border-b-0 whitespace-nowrap">
                    ASSUNTO
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                
                    {emails.length > 0 ? (
                      emails.map((email, index) => (
                        <Table.Tr key={index} className="intro-x">
                          <Table.Td className="box rounded-l-none rounded-r-none border-x-0 border-dashed shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-nunito font-normal text-[14px] leading-[18px] text-[#3F4254]">
                            {formatDate(email.sent_at, "DD/MM/YYYY HH:mm")}
                          </Table.Td>
                          <Table.Td className="box rounded-l-none rounded-r-none border-x-0 shadow-[5px_3px_5px_#00000005] first:rounded-l-[0.6rem] first:border-l last:rounded-r-[0.6rem] last:border-r dark:bg-darkmode-600 font-nunito font-normal text-[14px] leading-[18px] text-[#3F4254]">
                            {email.subject}
                          </Table.Td>
                        </Table.Tr>
                      ))
                    ) : (
                      <Table.Tr>
                        <Table.Td
                          colSpan="3"
                          className="text-center font-nunito font-normal text-[14px] leading-[18px] text-[#A1A5B7]"
                        >
                          Nenhum e-mail encontrado.
                        </Table.Td>
                      </Table.Tr>
                    )}
                  </Table.Tbody>
                
            </Table>
          </div>
        </section>
      </div>

      <OrdersSumaryModal
        idCustomer={ordersCustomerSumaryModal.id}
        closeModal={() =>
          setOrdersCustomerSumaryModal({ active: false, id: null })
        }
        open={ordersCustomerSumaryModal.active}
      />
    </>
  );
};

export default Overview;
