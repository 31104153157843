import HttpClient from "../../../services/HttpClient";

/**
 * Serviço responsável por operações relacionadas a faturas.
 */
const InvoiceService = {
  /**
   * Obtém as faturas de um cliente pelo ID do cliente.
   *
   * @async
   * @function getInvoicesByCustomerId
   * @param {number|string} customerId - ID do cliente cujas faturas serão consultadas.
   * @returns {Promise<Object>} - Retorna um objeto com os dados das faturas.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getInvoicesByCustomerId: async (customerId) => {
    try {
      const response = await HttpClient.get(`/invoices?customerId=${customerId}`);
      return response.data;
    } catch (error) {
      console.error('Erro em InvoiceService.getInvoicesByCustomerId:', error);
      throw error;
    }
  },

  /**
   * Obtém uma lista de faturas com base nos parâmetros fornecidos.
   *
   * @async
   * @function getInvoices
   * @param {Object} params - Parâmetros para a consulta.
   * @param {number} params.limit - Número máximo de faturas a serem retornadas por página.
   * @param {number} params.page - Número da página atual.
   * @param {string} params.orderBy - Campo pelo qual os resultados serão ordenados.
   * @param {string} params.orderDirection - Direção da ordenação ('asc' ou 'desc').
   * @returns {Promise<Object>} - Retorna um objeto contendo os dados das faturas e informações de paginação.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getInvoices: async ({ limit, page, orderBy, orderDirection }) => {
    let url = `/invoices`;
    const queryParams = [];

    if (limit != null) queryParams.push(`limit=${limit}`);
    if (page != null) queryParams.push(`page=${page}`);
    if (orderBy != null) queryParams.push(`orderBy=${orderBy}`);
    if (orderDirection != null) queryParams.push(`orderDirection=${orderDirection}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    try {
      const response = await HttpClient.get(url);
      return response.data;
    } catch (error) {
      console.error('Erro em InvoiceService.getInvoices:', error);
      throw error;
    }
  },

  /**
   * Cria uma nova fatura no sistema.
   *
   * @async
   * @function createInvoice
   * @param {Object} invoiceData - Dados da fatura a ser criada.
   * @returns {Promise<Object>} - Retorna os dados da fatura criada.
   * @throws {Error} - Lança um erro se a criação da fatura falhar.
   */
  createInvoice: async (invoiceData) => {
    try {
      const response = await HttpClient.post("/invoices", invoiceData);
      return response.data;
    } catch (error) {
      console.error('Erro em InvoiceService.createInvoice:', error);
      throw error;
    }
  }
};

export default InvoiceService;
