import React, { useState } from 'react';
import AsyncSelect from 'react-select/async';
import CustomerService from '../Services/CustomerService';
import CustomerStatus from '../Enums/CustomerStatus';

const CustomerSelect = () => {
  const [clienteSelecionado, setClienteSelecionado] = useState(null);

  const carregarOpcoes = (inputValue, callback) => {
    const fetchClientes = inputValue
      ? CustomerService.getCustomerByName(inputValue)
      : CustomerService.getCustomers({ limit: 10 });

    fetchClientes
      .then((response) => {
        const options = response.data.map((cliente) => ({
          value: cliente.customer_id,
          label: `${cliente.name} / ${cliente.taxpayer_id}`,
          cliente,
        }));
        callback(options);
      })
      .catch((error) => {
        console.error('Erro ao buscar clientes:', error);
        callback([]);
      });
  };

  const handleChange = (selecionado) => {
    setClienteSelecionado(selecionado ? selecionado.cliente : null);
  };

  return (
    <div className="col-span-12 mt-5">
      <label className="block font-nunito font-medium text-sm leading-[18px] text-[#A1A5B7] mb-2">
        Cliente/Empresa:
      </label>
      <AsyncSelect
        cacheOptions
        loadOptions={carregarOpcoes}
        defaultOptions
        onChange={handleChange}
        placeholder="Buscar cliente por nome"
        className="react-select-container mb-2"
        classNamePrefix="react-select"
        menuPortalTarget={document.body} // Renderiza o menu no topo da hierarquia DOM
        styles={{
          menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Configura z-index alto para o menu
        }}
      />
      {clienteSelecionado && (
        <div className="mt-2 text-[#3F4254]">
          {/* {clienteSelecionado.name} / {clienteSelecionado.taxpayer_id}{' '} */}
          { CustomerStatus[clienteSelecionado.status]}
        </div>
      )}
    </div>
  );
};

export default CustomerSelect;
