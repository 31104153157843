import * as Yup from "yup";
import Input from "../../../components/Base/Form/InputBox";
import Select from "../../../components/Base/Form/SelectBox";
import { useFormik } from "formik";
import { FormikEffect } from "../../../utils/FormikEffect";
import Modal from "../../../components/Base/Modal";
import CustomerService from "../Services/CustomerService"; // Importa o serviço de cadastro
import { toast } from "react-toastify";
import { extractNumbers } from "../../../utils/helpers";
import { FormCheck, FormLabel } from "../../../components/Base/Form";
import { validateCNPJ, validateCPF } from "../../../utils/validations";

import { useTranslation } from 'react-i18next';
import { t } from "i18next";

const PhysicalPerson = ({ formik }) => {
  return (
    <div className="flex col-span-12 gap-5 grid grid-cols-12">
      <Input
        name="name"
        label={t('000102')}
        onChange={formik.handleChange}
        value={formik.values.name}
        error={(formik.touched.name || undefined) && formik.errors.name}
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        label={t('000188')}
        name="last_name"
        onChange={formik.handleChange}
        value={formik.values.last_name}
        error={
          (formik.touched.last_name || undefined) && formik.errors.last_name
        }
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        label={t('000191')}
        mask={[
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ]}
        name="document"
        placeholder="000.000.000-00"
        value={formik.values.document}
        onChange={formik.handleChange}
        error={
          (formik.touched.document || undefined) && formik.errors.document
        }
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="email"
        label={t('000074')}
        type="email"
        placeholder="usuario@empresa.com"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={(formik.touched.email || undefined) && formik.errors.email}
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="date_of_birth"
        label={t("000189")}
        max={new Date().toISOString().split("T")[0]}
        type="date"
        value={formik.values.date_of_birth}
        onChange={formik.handleChange}
        error={
          (formik.touched.date_of_birth || undefined) &&
          formik.errors.date_of_birth
        }
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="tel"
        type="tel"
        label={t('000202')}
        placeholder="(00) 00000-0000"
        mask={[
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        value={formik.values.tel}
        onChange={formik.handleChange}
        error={(formik.touched.tel || undefined) && formik.errors.tel}
        containerClassName="col-span-12 sm:col-span-6"
      />
    </div>
  );
};

const LegalPerson = ({ formik }) => {
  return (
    <div className="flex col-span-12 gap-5 grid grid-cols-12">
      <Input
        name="company_name"
        label={t("000103")}
        onChange={formik.handleChange}
        value={formik.values.company_name}
        error={
          (formik.touched.company_name || undefined) &&
          formik.errors.company_name
        }
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="fantasy_name"
        label={t("000190")}
        onChange={formik.handleChange}
        value={formik.values.fantasy_name}
        error={
          (formik.touched.fantasy_name || undefined) &&
          formik.errors.fantasy_name
        }
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="document"
        label={t("000191")}
        mask={[
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "/",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ]}
        placeholder="00.000.000/0000-00"
        value={formik.values.document}
        onChange={formik.handleChange}
        error={
          (formik.touched.document || undefined) && formik.errors.document
        }
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="foundation_date"
        label={t("000192")}
        max={new Date().toISOString().split("T")[0]}
        type="date"
        value={formik.values.foundation_date}
        onChange={formik.handleChange}
        error={
          (formik.touched.foundation_date || undefined) &&
          formik.errors.foundation_date
        }
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="email"
        label={t("000074")}
        type="email"
        placeholder="usuario@empresa.com"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={(formik.touched.email || undefined) && formik.errors.email}
        containerClassName="col-span-12 sm:col-span-6"
      />
      <Input
        name="tel"
        type="tel"
        label={t("000193")}
        onChange={formik.handleChange}
        value={formik.values.tel}
        error={(formik.touched.tel || undefined) && formik.errors.tel}
        placeholder="(00) 00000-0000"
        mask={[
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        containerClassName="col-span-12 sm:col-span-6"
      />
    </div>
  );
};

const Address = ({ formik }) => {
  return (
    <div>
      <div className="flex col-span-12 gap-5 grid grid-cols-12">
        
      </div>
      <div className="flex col-span-12 gap-5 grid grid-cols-12">
      <Input
          name="zipCode"
          label={t("000194")}
          mask={[
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            /\d/,
            "-",
            /\d/,
            /\d/,
            /\d/,
          ]}
          placeholder="00000-000"
          value={formik.values.zipCode}
          onChange={formik.handleChange}
          error={(formik.touched.zipCode || undefined) && formik.errors.zipCode}
          containerClassName="col-span-12 sm:col-span-3"
        />
        <Input
          name="street"
          label={t("000195")}
          value={formik.values.street}
          onChange={formik.handleChange}
          error={(formik.touched.street || undefined) && formik.errors.street}
          containerClassName="col-span-12 sm:col-span-4"
        />
        <Input
          name="address_type"
          label={t("000196")}
          value={formik.values.address_type}
          onChange={formik.handleChange}
          error={
            (formik.touched.address_type || undefined) &&
            formik.errors.address_type
          }
          containerClassName="col-span-12 sm:col-span-3"
        />
        <Input
          name="number"
          label={t("000197")}
          value={formik.values.number}
          onChange={formik.handleChange}
          error={(formik.touched.number || undefined) && formik.errors.number}
          containerClassName="col-span-12 sm:col-span-2"
        />
      </div>
      <div className="flex col-span-12 gap-5 grid grid-cols-12">
        <Input
          name="neighborhood"
          label={t("000198")}
          value={formik.values.neighborhood}
          onChange={formik.handleChange}
          error={
            (formik.touched.neighborhood || undefined) &&
            formik.errors.neighborhood
          }
          containerClassName="col-span-12 sm:col-span-4"
        />
        <Select
          label={t("000199")}
          name="city"
          containerClassName="col-span-12 sm:col-span-4"
          allowClear
          value={formik.values.city}
          error={(formik.touched.city || undefined) && formik.errors.city}
          onChange={(value) => formik.setFieldValue("city", value)}
          options={[
            {
              value: "Mateus Leme",
              label: "Mateus Leme",
            },
            {
              value: "Betim",
              label: "Betim",
            },
            {
              value: "Contagem",
              label: "Contagem",
            },
            {
              value: "Belo Horizonte",
              label: "Belo Horizonte",
            },
          ]}
        />
        <Select
          label={("000200")}
          name="uf"
          value={formik.values.uf}
          onChange={(value) => formik.setFieldValue("uf", value)}
          containerClassName="col-span-12 sm:col-span-4"
          error={(formik.touched.uf || undefined) && formik.errors.uf}
          allowClear
          options={[
            { value: "", label: "Selecione uma opção" },
            { label: "AC", value: "AC" },
            { label: "AL", value: "AL" },
            { label: "AP", value: "AP" },
            { label: "AM", value: "AM" },
            { label: "BA", value: "BA" },
            { label: "CE", value: "CE" },
            { label: "DF", value: "DF" },
            { label: "ES", value: "ES" },
            { label: "GO", value: "GO" },
            { label: "MA", value: "MA" },
            { label: "MT", value: "MT" },
            { label: "MS", value: "MS" },
            { label: "MG", value: "MG" },
            { label: "PA", value: "PA" },
            { label: "PB", value: "PB" },
            { label: "PR", value: "PR" },
            { label: "PE", value: "PE" },
            { label: "PI", value: "PI" },
            { label: "RJ", value: "RJ" },
            { label: "RN", value: "RN" },
            { label: "RS", value: "RS" },
            { label: "RO", value: "RO" },
            { label: "RR", value: "RR" },
            { label: "SC", value: "SC" },
            { label: "SP", value: "SP" },
            { label: "SE", value: "SE" },
            { label: "TO", value: "TO" },
          ]}
        />
      </div>
    </div>
  );
};

const CreateCustomerModal = (props) => {
  const { setCustomers, isModalOpen, closeModal } = props;
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      type: "fisica",
      name: "",
      last_name: "",
      document: "",
      email: "",
      date_of_birth: "",
      foundation_date: "",
      tel: "",
      cell: "",
      company_name: "",
      fantasy_name: "",
      zipCode: "",
      street: "",
      number: "",
      address_type: "",
      neighborhood: "",
      uf: "",
      city: "",
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required("Tipo de pessoa é obrigatório"),
      name: Yup.string()
        .min(3, "Informe pelo menos 3 caracteres")
        .required(t('000017')),
      last_name: Yup.string().when("type", {
        is: "fisica",
        then: (schema) =>
          schema
            .min(3, "Informe pelo menos 3 caracteres")
            .required(t('000018')),
        otherwise: (schema) => schema.notRequired(),
      }),
      document: Yup.string()
        .required("CPF/CNPJ é obrigatório")
        .test("document", "Documento inválido", (value, context) => {
          if (!value) return false;
          return context.parent.type === "fisica"
            ? validateCPF(value)
            : validateCNPJ(value);
        }),
      email: Yup.string()
        .email("E-mail inválido")
        .required(t('000019')),
      company_name: Yup.string().when("type", {
        is: "juridica",
        then: (schema) => schema.required("Razão Social é obrigatória"),
        otherwise: (schema) => schema.notRequired(),
      }),
      fantasy_name: Yup.string().when("type", {
        is: "juridica",
        then: (schema) => schema.required("Nome Fantasia é obrigatório"),
        otherwise: (schema) => schema.notRequired(),
      }),
      date_of_birth: Yup.string().when("type", {
        is: "fisica",
        then: (schema) => schema.required(t("000148")),
        otherwise: (schema) => schema.notRequired(),
      }),
      foundation_date: Yup.string().when("type", {
        is: "juridica",
        then: (schema) => schema.required("Data de Fundação é obrigatória"),
        otherwise: (schema) => schema.notRequired(),
      }),
    }),
    

    onSubmit: async (values) => {
      try {
        // Dados para o cadastro do cliente
        const customerData = {
          name: values.name,
          last_name: values.last_name,
          email: values.email,
          type: values.type === "fisica" ? 1 : 2,
          taxpayer_id: extractNumbers(values.document),
          phonenumber_primary: extractNumbers(values.cell || values.tel),
          birthdate:
            values.type === "fisica" ? values.date_of_birth : undefined,
          company_name:
            values.type === "juridica" ? values.company_name : undefined,
          fantasy_name:
            values.type === "juridica" ? values.fantasy_name : undefined,
          foundation_date:
            values.type === "juridica" ? values.foundation_date : undefined,
        };

        // Remover campos undefined
        Object.keys(customerData).forEach(
          (key) => customerData[key] === undefined && delete customerData[key]
        );

        // Cadastrar o cliente
        const customerResponse = await CustomerService.createCustomer(
          customerData
        );

        const customer_id = customerResponse.data.customer_id;

        // Dados para o cadastro do endereço
        const addressData = {
          street: values.street,
          city: values.city,
          state: values.uf,
          zip_code: extractNumbers(values.zipCode),
          country: "Brasil",
          address_type: values.address_type,
          is_default: true,
        };

        // Cadastrar o endereço
        await CustomerService.createAddress(customer_id, addressData);

        setCustomers((oldCustomers) => {
          const customers = [...oldCustomers];
          customers.unshift({
            ...customerResponse,
          });
          return customers;
        });

        closeModal();
        toast.success(t("000001"));
        formik.resetForm();
      } catch (error) {
        if (error.response) {
          const responseData = error.response.data;
          if (responseData && responseData.errors) {
            formik.setErrors(responseData.errors);
          }
        } else {
          toast.error(t("000201"));
        }
      }
    },
  });

  return (
    <Modal
      open={isModalOpen}
      title={t('000187')}
      closeModal={() => {
        formik.resetForm();
        closeModal();
      }}
      isSubmitting={formik.isSubmitting}
      formId="customerForm"
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
        className="grid grid-cols-12 gap-5"
        id="customerForm"
      >
        <FormikEffect formik={formik} />
        <div className="mb-5 col-span-12">
          <div className="flex flex-col mt-3 sm:flex-row">
            <FormCheck className="mr-5">
              <FormCheck.Input
                id="radio-switch-4"
                type="radio"
                checked={formik.values.type === "fisica"}
                onChange={() => formik.setFieldValue("type", "fisica")}
                name="horizontal_radio_button"
                value="fisica"
              />
              <FormCheck.Label htmlFor="radio-switch-4">
                {t('000120')}
              </FormCheck.Label>
            </FormCheck>
            <FormCheck className="mt-2 mr-2 sm:mt-0">
              <FormCheck.Input
                id="radio-switch-5"
                type="radio"
                checked={formik.values.type === "juridica"}
                onChange={() => formik.setFieldValue("type", "juridica")}
                name="horizontal_radio_button"
                value="juridica"
              />
              <FormCheck.Label htmlFor="radio-switch-5">
                {t('000121')}
              </FormCheck.Label>
            </FormCheck>
          </div>
        </div>
        {formik.values.type === "fisica" ? (
          <PhysicalPerson formik={formik} />
        ) : (
          <LegalPerson formik={formik} />
        )}
        <p className="text-primary text-base font-semibold leading-[14px] my-2.5 tracking-[-0.32px] col-span-12">
          {t('000195')}
        </p>
        <div className="col-span-12 flex justify-end">
          <Address formik={formik} />
        </div>
      </form>
    </Modal>
  );
};

export default CreateCustomerModal;
