import HttpClient from "../../../services/HttpClient";

class PaymentMethodService {
  async list() {
    // return HttpClient.get("/payment-methods");

    const paymentMethods = [
        { id: '1', description: 'Cartão de Crédito' },
        { id: '2', description: 'Boleto Bancário' },
        { id: '3', description: 'Pix' },
        { id: '4', description: 'Transferência Bancária' },
        { id: '5', description: 'Débito Automático' },
      ];
  
      return { data: paymentMethods };
  }
}

export default new PaymentMethodService();
