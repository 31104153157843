// Import necessary modules and components
import Badge from "../../../components/Badge";
import Lucide from "../../../components/Base/Lucide";
import Table from "../../../components/Base/Table";
import { useNavigate, useParams } from "react-router-dom";
import Cog from "../../../assets/images/cog2.svg";
import clsx from "clsx";
import MenuFloat from "../../../components/MenuFloat";
import { useEffect, useState } from "react";
import { useCustomer } from "../../../layout/BaseSecondary";
import {
  formatDate,
  formatToBRL,
  getPositionsMenuFloat,
} from "../../../utils/helpers";
import ListBase from "../../../layout/ListBase";
import { t } from "i18next";
import EmailModal from "./components/EmailModal";
import EmailService from "./Services/EmailService";

// Email statuses with corresponding badges
const emailStatus = {
  pending: <Badge type="warning" text="Pendente" />,
  sent: <Badge type="success" text="Enviado" />,
  failed: <Badge type="danger" text="Falhou" />,
};

/**
 * Emails Component
 *
 * This component displays a list of emails associated with a customer.
 */
const Emails = () => {
  const [isEmailModalActive, setEmailModalActive] = useState(false);
  const navigate = useNavigate();
  const [menuFloatActive, setMenuFloatActive] = useState(false);
  const [positions, setPositions] = useState({ x: 0, y: 0 });
  const [emailId, setEmailId] = useState(null);
  const { customer_id } = useParams();
  const [emails, setEmails] = useState({
    data: [],
    current_page: 1,
    last_page: 1,
    total: 0,
  });
  const [isLoading, setIsLoading] = useState(false);

  // Close the menu float
  const closeMenuFloat = () => {
    setEmailId(null);
    setMenuFloatActive(false);
  };

  // Handle page change in pagination
  const getEmailsOnHandlePage = async (newPage) => {
    setIsLoading(true);
    try {
      const response = await EmailService.listEmails({
        page: newPage,
        limit: 10,
        orderBy: "id",
        orderDirection: "desc",
        customer_id,
      });

      setEmails({
        data: response.data,
        current_page: response.current_page,
        last_page: response.last_page,
        total: response.total,
      });
    } catch (error) {
      console.error("Erro ao buscar emails:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Handle click event for menu float
  const handleClick = (e, id) => {
    setEmailId(id);
    setPositions(getPositionsMenuFloat(e, 20));
    setMenuFloatActive(true);
  };

  // Fetch emails on component mount
  useEffect(() => {
    const fetchEmails = async () => {
      setIsLoading(true);
      try {
        const response = await EmailService.listEmails({
          page: 1,
          limit: 10,
          orderBy: "id",
          orderDirection: "desc",
          customer_id,
        });

        setEmails({
          data: response.data,
          current_page: response.current_page,
          last_page: response.last_page,
          total: response.total,
        });
      } catch (error) {
        console.error("Erro ao buscar emails:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmails();
  }, [customer_id]);

  return (
    <main>
      <section className="md:min-w-[460px] bg-white rounded-xl font-nunito shadow-custom-1">
        <ListBase
          filters={<></>}
          getContentListOnPageChange={getEmailsOnHandlePage}
          isFilterActive={false}
          setIsLoading={setIsLoading}
          tableEmptyMsg="Não há nenhum email associado a este cliente."
          title="Emails"
          headerType="B"
          isLoading={isLoading}
          table={
            <Table hover={true}>
              <Table.Thead>
                <Table.Tr className="bg-[#F9F9F9] h-[35px] pb-2">
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold rounded-l">
                    ID
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Assunto
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Destinatário
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Status
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito font-semibold">
                    Enviado em
                  </Table.Th>
                  <Table.Th className="text-[#67759A] text-[14px] font-nunito text-center font-semibold rounded-r">
                    Ações
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>

              <Table.Tbody>
                {emails.data.map((email) => (
                  <Table.Tr
                    key={email.id}
                    className="intro-x"
                    onClick={(e) => {
                      e.stopPropagation();
                      setEmailModalActive(true);
                    }}
                  >
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {email.id}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {email.subject}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[#7E8299] text-[14px] font-normal">
                      {email.recipient}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[14px] font-normal">
                      {emailStatus[email.status] || email.status}
                    </Table.Td>
                    <Table.Td className="dark:bg-darkmode-600 font-nunito text-[14px] font-normal">
                      {email.sent_at
                        ? formatDate(email.sent_at, "DD/MM/YYYY")
                        : "N/A"}
                    </Table.Td>
                    <Table.Td
                      className={clsx(
                        "w-38 text-center relative z-50 dark:bg-darkmode-600"
                      )}
                    >
                      <button
                        className="hover:scale-105 hover:rotate-45 transition-all duration-300 ease-in-out"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClick(e, email.id);
                        }}
                      >
                        <img src={Cog} alt="Configuração" />
                      </button>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            </Table>
          }
          contentList={{
            data: emails.data,
            pagination: {
              current_page: emails.current_page,
              last_page: emails.last_page,
              total: emails.total,
            },
          }}
          actionsMenu={
            <>
              <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
                <p className="text-slate-400/90 dark:placeholder:text-slate-500/80">
                  {t("000099")}
                </p>
                <Lucide
                  icon="Search"
                  className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
                />
              </div>
            </>
          }
        />
      </section>

      <MenuFloat
        active={menuFloatActive}
        closeMenuFloat={closeMenuFloat}
        positions={positions}
      >
        <li
          className="hover:bg-dark/10 cursor-pointer flex items-center p-2 transition duration-300 ease-in-out rounded-md dark:bg-darkmode-600 dark:hover:bg-darkmode-400"
          onClick={() => {
            closeMenuFloat();
            navigate(`/emails/${emailId}`);
          }}
        >
          <Lucide icon="Eye" className="w-4 h-4 mr-2" /> Detalhes
        </li>
      </MenuFloat>

      <EmailModal
        open={isEmailModalActive}
        closeModal={() => setEmailModalActive(false)}
      />
    </main>
  );
};

export default Emails;
