import axios from "axios";
import TokenService from "./TokenService";

class HttpClient {
  constructor(baseURL) {
    this.instance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json",
      },
    });

    this.setupInterceptors();
  }

  setupInterceptors() {
    this.instance.interceptors.request.use(
      async (config) => {
        const token = TokenService.getAccessToken();
        if (token) {
          config.headers["Authorization"] = `Bearer ${token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalConfig = error.config;

        if (originalConfig.url !== "/login" && error.response) {
          if (error.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            try {
              const rs = await this.instance.post("/refresh", {
                Authorization: 'Bearer ' + TokenService.getAccessToken(),
              });

              const { accessToken, user_id, name, last_name, language } = rs.data;
  
              TokenService.setAccessToken(accessToken);
              TokenService.setUserId(user_id);
              TokenService.setName(name);
              TokenService.setLastName(last_name);
              TokenService.setLanguage(language);

              return this.instance(originalConfig);
            } catch (_error) {
              window.location.href = "/login";
              return Promise.reject(_error);
            }
          }
        }

        return Promise.reject(error);
      }
    );
  }

  get(url, config) {
    return this.instance.get(url, config);
  }

  post(url, data, config) {
    return this.instance.post(url, data, config);
  }

  put(url, data, config) {
    return this.instance.put(url, data, config);
  }

  delete(url, config) {
    return this.instance.delete(url, config);
  }
}

export default new HttpClient(process.env.REACT_APP_BASE_URL);
