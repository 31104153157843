import { FormLabel } from "../../../components/Base/Form";
import IntlCurrencyInput from "react-intl-currency-input";
import { twMerge } from "tailwind-merge";

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};

const BrlCurrencyComponent = ({
  name,
  value,
  label,
  containerClassName,
  labelClass,
  disabled,
  handleChange,
}) => {
  const className = twMerge([
    "disabled:bg-slate-100  disabled:cursor-not-allowed dark:disabled:bg-darkmode-800/50 dark:disabled:border-transparent",
    "[&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50 [&[readonly]]:dark:border-transparent",
    "transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md placeholder:text-slate-400/90 focus:ring-4 focus:ring-primary focus:ring-opacity-20 focus:border-primary focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 dark:placeholder:text-slate-500/80",
    "text-xs py-1.5 px-2 shadow-none",
  ]);

  return (
    <div className={containerClassName}>
      {label ? (
        <FormLabel
          htmlFor={name}
          className={
            "font-nunito text-[14px] font-semibold mb-[6px] " + labelClass
          }
        >
          {label}
        </FormLabel>
      ) : null}

      <IntlCurrencyInput
        disabled={disabled}
        name={name}
        className={className}
        currency="BRL"
        config={currencyConfig}
        value={value}
        onChange={handleChange}
      />
    </div>
  );
};

export default BrlCurrencyComponent;
