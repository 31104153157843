import { FormLabel } from "../../Form";
import { twMerge } from "tailwind-merge";
import { Select } from "antd";
import clsx from "clsx";

const SelectBox = (props) => {
  const {
    label,
    spanLabel,
    name,
    error,
    className,
    containerClassName,
    animation = "intro-y",
    labelClass = "",
    errorClass = "0",
    children,
    ...rest
  } = props;

  return (
    <div className={animation + "  " + containerClassName}>
      {label ? (
        <FormLabel
          htmlFor={name}
          className={
            "mb-[8px] mb-[6px]  font-nunito text-[14px] font-semibold whitespace-nowrap " +
            labelClass
          }
        >
          {label}
          {spanLabel && (
            <span className="text-danger text-xs">{spanLabel}</span>
          )}
        </FormLabel>
      ) : null}

      <Select
        id={name}
        status={error && "error"}
        className={twMerge([
          "disabled:bg-slate-100 disabled:cursor-not-allowed disabled:dark:bg-darkmode-800/50",
          "[&[readonly]]:bg-slate-100 [&[readonly]]:cursor-not-allowed [&[readonly]]:dark:bg-darkmode-800/50",
          "transition duration-200 ease-in-out w-full text-sm border-slate-200 shadow-sm rounded-md  focus:ring-4 focus:ring-slate-600 focus:ring-opacity-20 focus:border-slate-600 focus:border-opacity-40 dark:bg-darkmode-800 dark:border-transparent dark:focus:ring-slate-700 dark:focus:ring-opacity-50 min-h-[37.16px] min-h-[32px]",
          true && "text-xs shadow-none",
          className,
          error ? "border-danger" : "",
        ])}
        {...rest}
        size="middle"
      />

      {error ? (
        <p className={clsx(["mt-2 font-nunito text-xs text-danger", errorClass])}>
          {error}
        </p>
      ) : null}
    </div>
  );
};

export default SelectBox;
