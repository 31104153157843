import { FormInput, FormLabel } from "../../Form";
import { forwardRef } from "react";
import { Mask } from "react-text-mask";
// import IntlCurrencyInput from "react-intl-currency-input";

const InputBox = forwardRef((props, ref) => {
  const {
    name,
    type = "text",
    label,
    error,
    containerClassName = "",
    spanLabel,
    animation = "intro-y",
    labelClass = "",
    className,
    ...rest
  } = props;

  return (
    <div className={animation + " " + containerClassName}>
      {label ? (
        <FormLabel
          htmlFor={name}
          className={"font-nunito text-[14px] font-semibold mb-[6px] " + labelClass}
        >
          {label}
          {spanLabel && (
            <span className="text-danger text-xs font-medium">{spanLabel}</span>
          )}
        </FormLabel>
      ) : null}

      <FormInput
        formInputSize="sm"
        name={name}
        id={name}
        ref={ref}
        type={type}
        className={`${className} ${error ? "border-danger" : ""}`}
        {...rest}
      />

      {error ? <p className="mt-2 font-nunito text-xs text-danger">{error}</p> : null}
    </div>
  );
});

export default InputBox;
