import clsx from "clsx";
import Lucide from "../Base/Lucide";

const badgeStyles = {
  success: "text-[#50CD89] bg-[#E8FFF3]",
  warning: "text-[#F6C000] bg-[#FFF8DD]",
  danger: "text-[#D9214E] bg-[#FFF5F8]",
  info: "text-[#3E97FF] bg-[#EEF6FF]",
  suspended: "text-[#9F7AEA] bg-[#F2EBFE]",
  dark: "text-[#7E8299] bg-[#F9F9F9]",
};

const Badge = ({ text, type, className }) => {
  return (
    <span
      className={clsx([
        "font-nunito text-[13px] font-semibold leading-[10px] flex justify-center items-center px-[20px] h-[22px] rounded-[3px] w-fit",
        badgeStyles[type],
        className,
        // "bg-white"
      ])}
    >
      {text}
    </span>
  );
};

export default Badge;
