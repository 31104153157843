import HttpClient from "../../../../services/HttpClient";

/**
 * Serviço responsável por operações relacionadas a notas.
 */
const NoteService = {
  /**
   * Adiciona uma nova nota.
   *
   * @async
   * @function addNote
   * @param {Object} noteData - Dados da nova nota.
   * @param {number} noteData.customer_id - ID do cliente associado à nota.
   * @param {string} noteData.content - Conteúdo da nota.
   * @returns {Promise<Object>} - Retorna a nota criada.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  addNote: async (noteData) => {
    try {
      const response = await HttpClient.post(`/notes`, noteData);
      return response.data;
    } catch (error) {
      console.error("Erro em NoteService.addNote:", error);
      throw error;
    }
  },

  /**
   * Edita uma nota existente.
   *
   * @async
   * @function editNote
   * @param {number|string} noteId - ID da nota a ser editada.
   * @param {Object} noteData - Dados atualizados da nota.
   * @param {string} noteData.content - Novo conteúdo da nota.
   * @returns {Promise<Object>} - Retorna a nota atualizada.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  editNote: async (noteId, noteData) => {
    try {
      const response = await HttpClient.put(`/notes/${noteId}`, noteData);
      return response.data;
    } catch (error) {
      console.error("Erro em NoteService.editNote:", error);
      throw error;
    }
  },

  /**
   * Remove uma nota.
   *
   * @async
   * @function removeNote
   * @param {number|string} noteId - ID da nota a ser removida.
   * @returns {Promise<Object>} - Retorna a resposta da exclusão.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  removeNote: async (noteId) => {
    try {
      const response = await HttpClient.delete(`/notes/${noteId}`);
      return response.data;
    } catch (error) {
      console.error("Erro em NoteService.removeNote:", error);
      throw error;
    }
  },

  /**
   * Obtém a lista de notas.
   *
   * @async
   * @function getNotes
   * @param {Object} params - Parâmetros para a consulta.
   * @param {number} params.limit - Número máximo de notas a serem retornadas por página.
   * @param {number} params.page - Número da página atual.
   * @param {number|string} params.customer_id - ID do cliente ao qual as notas estão associadas.
   * @returns {Promise<Object>} - Retorna um objeto contendo os dados das notas e informações de paginação.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getNotes: async ({ limit, page, customer_id }) => {
    let url = `/notes`;
    const queryParams = [];

    if (limit != null) queryParams.push(`limit=${limit}`);
    if (page != null) queryParams.push(`page=${page}`);
    if (customer_id != null) queryParams.push(`customer_id=${customer_id}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    try {
      const response = await HttpClient.get(url);
      return response.data;
    } catch (error) {
      console.error("Erro em NoteService.getNotes:", error);
      throw error;
    }
  },
};

export default NoteService;
