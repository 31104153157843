import HttpClient from "../../../services/HttpClient";

/**
 * Serviço responsável por operações relacionadas a logs.
 */
const LogService = {
  /**
   * Obtém a lista de logs.
   *
   * @async
   * @function getLogs
   * @param {Object} params - Parâmetros para a consulta.
   * @param {number} [params.limit] - Número máximo de logs a serem retornados por página.
   * @param {number} [params.page] - Número da página atual.
   * @param {number|string} [params.customer_id] - ID do cliente para filtrar os logs.
   * @returns {Promise<Object>} - Retorna um objeto contendo os dados dos logs e informações de paginação.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getLogs: async ({ limit, page, customer_id }) => {
    let url = `/logs`;
    const queryParams = [];

    if (limit != null) queryParams.push(`limit=${limit}`);
    if (page != null) queryParams.push(`page=${page}`);
    if (customer_id != null) queryParams.push(`customer_id=${customer_id}`);

    if (queryParams.length > 0) {
      url += `?${queryParams.join('&')}`;
    }

    try {
      const response = await HttpClient.get(url);
      return response.data;
    } catch (error) {
      console.error("Erro em LogService.getLogs:", error);
      throw error;
    }
  },

  /**
   * Obtém os detalhes de um log pelo ID.
   *
   * @async
   * @function getLogById
   * @param {number|string} logId - ID do log a ser consultado.
   * @returns {Promise<Object>} - Retorna um objeto com os dados do log.
   * @throws {Error} - Lança um erro se a requisição falhar.
   */
  getLogById: async (logId) => {
    try {
      const response = await HttpClient.get(`/logs/${logId}`);
      return response.data;
    } catch (error) {
      console.error(`Erro em LogService.getLogById para logId ${logId}:`, error);
      throw error;
    }
  },
};

export default LogService;
