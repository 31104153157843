import { matchRoutes, useLocation } from "react-router-dom";

const routes = [
  { path: "/customers/:customer_id" },
  { path: "/customers/:customer_id/profile" },
  { path: "/customers/:customer_id/orders" },
  { path: "/customers/:customer_id/invoices" },
  { path: "/customers/:customer_id/tickets" },
  { path: "/customers/:customer_id/notes" },
  { path: "/customers/:customer_id/emails" },
  { path: "/customers/:customer_id/logs" },
  // { path: "/customers/:customer_id/produtos-servicos/:customer_idProduto" },
  // { path: "/customers/:customer_id/faturas" },
  // { path: "/customers/:customer_id/faturas/:customer_idFatura" },
  // { path: "/customers/:customer_id/tickets" },
  // { path: "/customers/:customer_id/notas" },
  // { path: "/customers/:customer_id/e-mails" },
  // { path: "/customers/:customer_id/logs" },
];

const useCurrentPathReactRouterDom = () => {
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location);

  return route.path;
};

export default useCurrentPathReactRouterDom;
