import { useState, useEffect, useRef, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import clsx from "clsx";
import Button from "../../components/Base/Button";
import { FormInput } from "../../components/Base/Form";
import Lucide from "../../components/Base/Lucide";
import Tippy from "../../components/Base/Tippy";
import { Menu } from "../../components/Base/Headless";
import Table from "../../components/Base/Table";
import LoadingIcon from "../../components/Base/LoadingIcon";
import { Transition } from "@headlessui/react";
import Pagination from "../../components/Base/Pagination";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import { formatDate } from "../../utils/helpers";
import OrderService from "./Services/OrderService"; // Importando o OrderService

// Componente MenuSearch
const MenuSearch = (props) => {
  const {
    show,
    getOrders,
    handleChangeDataInput,
    inputFilterData,
    clearFilters,
    close,
  } = props;

  const menuClasses = clsx(
    "p-4 shadow-[0px_3px_20px_#0000000b] bg-white border-transparent rounded-md dark:bg-darkmode-600 dark:border-transparent  translate-y-10 -mt-10 outline-none"
  );

  return (
    <Transition
      show={show}
      enter="transition duration-300 ease-out"
      enterFrom="transform  scale-50 opacity-0"
      enterTo="transform scale-100 opacity-100 "
      leave="transition duration-300 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          close();
          getOrders();
        }}
        className={menuClasses}
      >
        <div>
          <div className="text-xs text-left">ID do Pedido</div>
          <FormInput
            autoFocus={true}
            onChange={(e) => {
              e.stopPropagation();
              handleChangeDataInput("order_id", e.target.value);
            }}
            value={inputFilterData.order_id}
            type="text"
            className="flex-1 mt-2"
            formInputSize="sm"
            placeholder="Digite o ID do pedido"
          />
        </div>
        <div className="mt-3">
          <div className="text-xs text-left">ID do Cliente</div>
          <FormInput
            onChange={(e) => handleChangeDataInput("customer_id", e.target.value)}
            value={inputFilterData.customer_id}
            formInputSize="sm"
            type="text"
            className="flex-1 mt-2"
            placeholder="Digite o ID do cliente"
          />
        </div>
        <div className="flex items-center mt-3">
          <Button
            type="button"
            variant="secondary"
            size="sm"
            onClick={() => {
              clearFilters();
              close();
            }}
            className="w-32 ml-auto"
          >
            Limpar filtros
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="sm"
            className="w-32 ml-2"
          >
            Buscar pedidos
          </Button>
        </div>
      </form>
    </Transition>
  );
};

// Hook para detectar cliques fora de um elemento
const useOutsideClick = (callback) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleOutClick = (e) => {
      const target = e.target;

      if (!ref.current?.contains(target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleOutClick);

    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, [callback]);

  return ref;
};

// Componente PopOverMenu
const PopOverMenu = ({
  getOrders,
  inputFilterData,
  someFilterActive,
  setInputFilterData,
  clearFilters,
  handleChangeDataInput,
}) => {
  const [isPopOverActive, setPopOverActive] = useState(false);

  const modalRef = useOutsideClick(() => {
    setPopOverActive(false);
  });

  useUpdateEffect(() => {
    if (!isPopOverActive) getOrders();
  }, [isPopOverActive]);

  const PTBRREF = {
    order_id: "ID do Pedido",
    customer_id: "ID do Cliente",
  };

  return (
    <div className="w-full max-w-72 relative z-[100]" ref={modalRef}>
      <div
        className="!box cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          setPopOverActive((prev) => !prev);
        }}
      >
        <div className="w-full mt-3 sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
          {someFilterActive() ? (
            <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
              <p className="text-slate-400/90 dark:placeholder:text-slate-500/80 opacity-0">
                Filtros...
              </p>

              <div className="flex items-center ms-auto overflow-x-hidden absolute w-60 translate-y-0 translate-x-16 translate-x-[-0px] translate-y-[11px] pb-3 overflow-x-scroll">
                {Object.keys(inputFilterData)
                  .filter((key) => inputFilterData[key] !== "")
                  .map((key) => (
                    <span
                      key={key}
                      id="badge-dismiss-default"
                      className="inline-flex items-center whitespace-nowrap px-3 py-1 me-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300"
                    >
                      {PTBRREF[key]}: {inputFilterData[key]}
                    </span>
                  ))}
              </div>

              <Lucide
                icon="XCircle"
                onClick={(e) => {
                  e.stopPropagation();
                  clearFilters();
                }}
                className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3 text-danger"
              />
            </div>
          ) : (
            <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
              <p className="text-slate-400/90 dark:placeholder:text-slate-500/80">
                Filtros...
              </p>
              <Lucide
                icon="Search"
                className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3"
              />
            </div>
          )}
        </div>
      </div>

      <div className="w-72 mt-1 absolute">
        <MenuSearch
          show={isPopOverActive}
          close={() => setPopOverActive(false)}
          getOrders={getOrders}
          handleChangeDataInput={handleChangeDataInput}
          inputFilterData={inputFilterData}
          clearFilters={clearFilters}
        />
      </div>
    </div>
  );
};

// Componente principal OrdersList
function OrdersList() {
  const [currentPage, setCurrentPage] = useState(1);
  const [orders, setOrders] = useState([]);
  const [pagination, setPagination] = useState({
    total: 0,
    per_page: 10,
    current_page: 1,
    last_page: 1,
    from: 0,
    to: 0,
  });
  const [isFilterSynchronizedDatabase, setFilterSynchronizedDatabase] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [inputFilterData, setInputFilterData] = useState({
    order_id: "",
    customer_id: "",
  });

  const navigate = useNavigate();

  const someFilterActive = () =>
    Object.keys(inputFilterData).some((key) => inputFilterData[key] !== "");

  const clearFilters = async () => {
    setIsLoading(true);
    setInputFilterData({
      order_id: "",
      customer_id: "",
    });
    await getOrders(false);
    setIsLoading(false);
  };

  // useEffect para buscar pedidos quando currentPage ou filtros mudarem
  useEffect(() => {
    getOrders();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  const handleChangeDataInput = (key, value) => {
    setFilterSynchronizedDatabase(false);

    setInputFilterData((old) => ({
      ...old,
      [key]: value,
    }));
  };

  const getOrders = async (filter = true) => {
    setIsLoading(true);

    const params = {
      limit: pagination.per_page,
      page: currentPage,
      orderBy: "id",
      orderDirection: "desc",
    };

    if (someFilterActive() && filter) {
      Object.keys(inputFilterData).forEach((key) => {
        if (inputFilterData[key] !== "") {
          params[key] = inputFilterData[key];
        }
      });
    }

    try {
      const response = await OrderService.getOrders(params);

      if (response) {
        setOrders(response.data);
        setPagination({
          total: response.total,
          per_page: response.per_page,
          current_page: response.current_page,
          last_page: response.last_page,
          from: response.from,
          to: response.to,
        });
      } else {
        // Caso a API retorne um resultado inesperado
        setOrders([]);
        setPagination({
          total: 0,
          per_page: pagination.per_page,
          current_page: 1,
          last_page: 1,
          from: 0,
          to: 0,
        });
      }
    } catch (error) {
      console.error("Erro ao buscar pedidos:", error);
      setOrders([]);
    } finally {
      setIsLoading(false);
    }
  };

  const onClosePopoverFilter = () => {
    if (!isFilterSynchronizedDatabase) getOrders();
  };

  // Funções de paginação
  const goToFirstPage = () => {
    setCurrentPage(1);
  };

  const goToLastPage = () => {
    setCurrentPage(pagination.last_page);
  };

  const { t } = useTranslation();

  return (
    <>
      <h2 className="text-lg font-medium intro-y flex items-center gap-1">
        Pedidos
        <div className="text-center">
          <Tippy
            className="hover:text-primary"
            content={t("000215")}
            options={{
              theme: "light",
              placement: "right",
            }}
          >
            <Lucide icon="Info" className="w-4 h-4" />
          </Tippy>
        </div>
      </h2>

      <div className="grid grid-cols-12 gap-6 mt-5">
        <div className="flex flex-wrap items-center col-span-12 mt-2 intro-y sm:flex-nowrap z-10">
          <PopOverMenu
            isFilterSynchronizedDatabase={isFilterSynchronizedDatabase}
            setFilterSynchronizedDatabase={setFilterSynchronizedDatabase}
            getOrders={getOrders}
            handleChangeDataInput={handleChangeDataInput}
            inputFilterData={inputFilterData}
            someFilterActive={someFilterActive}
            setInputFilterData={setInputFilterData}
            clearFilters={clearFilters}
          />
          <div className="ms-auto flex items-center">
            {/* Botão de Exportar (comentado) */}
            {/* <Menu>
              <Menu.Button
                as={Button}
                className="flex items-center !box text-slate-600 dark:text-slate-300 mr-2 hover:bg-dark"
              >
                <Lucide
                  icon="FileText"
                  className="hidden  w-4 h-4 mr-2 sm:block"
                />
                Exportar
              </Menu.Button>
              <Menu.Items className="w-48" placement="bottom">
                <Menu.Item>
                  <Lucide icon="FileText" className="w-4 h-4 mr-2" /> PDF
                </Menu.Item>
                <Menu.Item>
                  <Lucide icon="Sheet" className="w-4 h-4 mr-2" /> Excel
                </Menu.Item>
              </Menu.Items>
            </Menu> */}

            <Button
              variant="primary"
              className="mr-2 shadow-md"
              onClick={() => navigate("/pedidos/novo")}
            >
              <Lucide icon="Plus" className="hidden w-5 h-5 mr-2 sm:block" />
              Adicionar pedido
            </Button>
          </div>
        </div>

        {/* BEGIN: Data List */}
        <div className="col-span-12 overflow-auto intro-y lg:overflow-visible relative z-10">
          <Table className="border-spacing-y-[10px] border-separate -mt-2 z-10">
            <Table.Thead>
              <Table.Tr>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  ID
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  ID do Cliente
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  Total
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  Status
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  Status do Pagamento
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  Criado em
                </Table.Th>
                <Table.Th className="text-center border-b-0 whitespace-nowrap">
                  Ações
                </Table.Th>
              </Table.Tr>
            </Table.Thead>
            {!isLoading && (
              <Table.Tbody>
                {orders.map((order) => (
                  <Table.Tr key={order.id} className="intro-x">
                    <Table.Td className="text-center">
                      #{order.id}
                    </Table.Td>
                    <Table.Td className="text-center">
                      {order.customer_id}
                    </Table.Td>
                    <Table.Td className="text-center">
                      R$ {Number(order.total).toFixed(2)}
                    </Table.Td>
                    <Table.Td className="text-center">
                      {order.status}
                    </Table.Td>
                    <Table.Td className="text-center">
                      {order.payment_status}
                    </Table.Td>
                    <Table.Td className="text-center">
                      {formatDate(order.created_at)}
                    </Table.Td>
                    <Table.Td className="text-center">
                      <Menu>
                        <Menu.Button as={Button} variant="outline-secondary">
                          <Lucide icon="ArrowDown" className="w-4 h-4" />
                        </Menu.Button>
                        <Menu.Items className="w-48">
                          <Menu.Item
                            onClick={() => navigate(`/pedidos/editar/${order.id}`)}
                          >
                            <Lucide icon="FilePenLine" className="w-4 h-4 mr-2" />{" "}
                            Editar
                          </Menu.Item>
                          <Menu.Item
                            onClick={() => navigate(`/pedidos/${order.id}`)}
                          >
                            <Lucide icon="Eye" className="w-4 h-4 mr-2" />{" "}
                            Visualizar
                          </Menu.Item>
                          <Menu.Item
                            onClick={() => {
                              // Função para excluir o pedido
                              // Por exemplo: deleteOrder(order.id);
                              alert(`Excluir pedido ${order.id}`);
                            }}
                          >
                            <Lucide icon="Trash" className="w-4 h-4 mr-2" />{" "}
                            Excluir
                          </Menu.Item>
                        </Menu.Items>
                      </Menu>
                    </Table.Td>
                  </Table.Tr>
                ))}
              </Table.Tbody>
            )}
          </Table>

          {isLoading && (
            <div className="flex flex-col items-center justify-center w-full block my-32 absolute">
              <LoadingIcon icon="three-dots" className="w-8 h-8" />
            </div>
          )}
        </div>
        {/* END: Data List */}

        {/* BEGIN: Pagination */}
        {!isLoading && orders.length > 0 && (
          <div className="flex flex-wrap items-center col-span-12 intro-y sm:flex-row sm:flex-nowrap">
            <Pagination className="w-full sm:w-auto mx-auto">
              {/* Botão Primeira Página */}
              <Pagination.Link
                onClick={goToFirstPage}
                disabled={pagination.current_page === 1}
              >
                <Lucide icon="ChevronsLeft" className="w-4 h-4" />
              </Pagination.Link>

              {/* Botão Página Anterior */}
              <Pagination.Link
                onClick={() => {
                  if (pagination.current_page > 1) {
                    setCurrentPage(pagination.current_page - 1);
                  }
                }}
                disabled={pagination.current_page === 1}
              >
                <Lucide icon="ChevronLeft" className="w-4 h-4" />
              </Pagination.Link>

              {/* Renderiza as páginas numéricas */}
              {Array.from({ length: pagination.last_page }, (_, index) => index + 1)
                .slice(
                  Math.max(0, pagination.current_page - 3),
                  Math.min(pagination.last_page, pagination.current_page + 2)
                )
                .map((page) => (
                  <Pagination.Link
                    key={page}
                    active={page === pagination.current_page}
                    onClick={() => setCurrentPage(page)}
                  >
                    {page}
                  </Pagination.Link>
                ))}

              {/* Botão Próxima Página */}
              <Pagination.Link
                onClick={() => {
                  if (pagination.current_page < pagination.last_page) {
                    setCurrentPage(pagination.current_page + 1);
                  }
                }}
                disabled={pagination.current_page === pagination.last_page}
              >
                <Lucide icon="ChevronRight" className="w-4 h-4" />
              </Pagination.Link>

              {/* Botão Última Página */}
              <Pagination.Link
                onClick={goToLastPage}
                disabled={pagination.current_page === pagination.last_page}
              >
                <Lucide icon="ChevronsRight" className="w-4 h-4" />
              </Pagination.Link>
            </Pagination>
          </div>
        )}
        {/* END: Pagination */}
      </div>
    </>
  );
}

export default OrdersList;
