import { Dialog, Transition } from "@headlessui/react";
import React, { useState } from "react";

const MenuFloat = ({ active, closeMenuFloat, children, positions }) => {
  return (
    <Transition appear show={active} as={React.Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeMenuFloat}>
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="transition-all ease-linear duration-150"
              enterFrom="mt-5 invisible opacity-0 translate-y-1"
              enterTo="mt-1 visible opacity-100 translate-y-0"
              leave="transition-all ease-linear duration-150"
              leaveFrom="mt-1 visible opacity-100 translate-y-0"
              leaveTo="mt-5 invisible opacity-0 translate-y-1"
            >
              <Dialog.Panel
                className="relative min-w-[160px] h-fit transform overflow-hidden rounded-md bg-white dark:bg-darkmode-600 p-2 text-left align-middle shadow-xl transition-all"
                style={{ top: positions.y, left: positions.x }}
              >
                <ul>{children}</ul>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default MenuFloat;
