import { useTranslation } from 'react-i18next';

const useMenu = () => {
  const { t } = useTranslation(); // Hook deve ser usado dentro da função/componente
  
  const menuItems = [
    {
      icon: "Users",
      title: t("000083"), // Tradução do texto
      subMenu: [
        {
          icon: "Users",
          pathname: "/customers",
          title: t("000089"), // Tradução do texto
        },
      ],
    },
    {
      icon: "LayoutPanelLeft",
      title: t("000084"),
      subMenu: [
        {
          icon: "LayoutPanelLeft",
          title: t("000090"),
          pathname: "/orders",
        },
        {
          icon: "LayoutPanelLeft",
          title: t("000091"),
          pathname: "/orders/create",
        }
      ]
    },
    {
      icon: "Banknote",
      title: t("000085"),
      subMenu: [
        {
          icon: "Banknote",
          title: t("000092"),
          pathname: "/invoices",
        },
      ],
    },
    {
      icon: "Network",
      title: t("000086"),
      subMenu: [
        {
          icon: "Network",
          title: t("000093"),
          pathname: "/products/create",
        },
        {
          icon: "Network",
          title: t("000094"),
          pathname: "/products",
        },
      ],
    },
    {
      icon: "Ticket",
      title: t("000087"),
      subMenu: [
        {
          icon: "Ticket",
          title: t("000095"),
          pathname: "/tickets",
        },
        {
          icon: "Ticket",
          title: t("000221"),
          pathname: "/tickets/create",
        },
      ],
    },
    {
      icon: "Scroll",
      title: t("000088"),
      pathname: "/logs"
    },
  ];

  return menuItems;
};

export default useMenu;
