import Lucide from "../../components/Base/Lucide";
import Button from "../../components/Base/Button";
import { Menu, Popover, Tab } from "../../components/Base/Headless";
import { clsx } from "clsx";
import { Fragment, useEffect, useRef, useState } from "react";
import { useUpdateEffect } from "../../hooks/useUpdateEffect";
import { Transition } from "@headlessui/react";
import { FormCheck, FormInput, FormLabel } from "../../components/Base/Form";
import { Link, useNavigate } from "react-router-dom";
import Badge from "../../components/Badge";
import { ClassicEditor } from "../../components/Base/Ckeditor";
import Tippy from "../../components/Base/Tippy";
import CustomerSelect from '../../pages/Customers/components/CustomerSelect';

const useOutsideClick = (callback) => {
  const ref = useRef(null);

  useEffect(() => {
    const handleOutClick = (e) => {
      const target = e.target;

      if (!ref.current?.contains(target)) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleOutClick);

    return () => {
      document.removeEventListener("mousedown", handleOutClick);
    };
  }, []);

  return ref;
};

const PopOverMenu = ({
  getCustomers,
  inputFilterData,
  someFilterActive,
  setInputFilterData,
  clearFilters,
  handleChangeDataInput,
}) => {
  const [isPopOverActive, setPopOverActive] = useState(false);

  const modalRef = useOutsideClick(() => {
    setPopOverActive(false);
  });

  useUpdateEffect(() => {
    if (!isPopOverActive) getCustomers();
  }, [isPopOverActive]);

  const PTBRREF = {
    business_name: "Empresa",
    name: "Cliente",
    cpf_cnpj: "CPF / CNPJ",
    email: "E-mail",
  };

  return (
    <div className="w-full md:max-w-72 relative z-[100]" ref={modalRef}>
      <div
        className="!box cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          console.log(e.target);

          setPopOverActive((prev) => !prev);
        }}
      >
        <div className="w-full sm:w-auto sm:mt-0 sm:ml-auto md:ml-0">
          {someFilterActive() ? (
            <div className="relative w-72 text-slate-500 bg-white h-auto flex items-center p-2 !box">
              <p className="text-[#A1A5B7] ">Filtros...</p>

              <div className="flex items-center ms-auto overflow-x-hidden absolute w-60 translate-y-0 translate-x-16 translate-x-[-0px] translate-y-[11px] pb-3 overflow-x-scroll ">
                {Object.keys(inputFilterData)
                  .filter((key) => inputFilterData[key] !== "")
                  .map((key) => (
                    <span
                      key={key}
                      id="badge-dismiss-default"
                      className="inline-flex items-center whitespace-nowrap px-3 py-1 me-2 text-xs font-medium text-blue-800 bg-blue-100 rounded-full dark:bg-blue-900 dark:text-blue-300"
                    >
                      {PTBRREF[key]}: {inputFilterData[key]}
                    </span>
                  ))}
              </div>

              <Lucide
                icon="XCircle"
                onClick={(e) => {
                  e.stopPropagation();
                  clearFilters();
                }}
                className="absolute inset-y-0 right-0 w-4 h-4 my-auto mr-3 text-danger"
              />
            </div>
          ) : (
            <div className="w-full w-72 px-[12px] bg-[#F9F9F9] h-auto flex items-center rounded-[6px] border min-h-[38px] border-color-[#E1E3E">
              <Lucide icon="Search" className="w-5 h-5 my-auto mr-[8px]" />
              <p className="text-[#A1A5B7] ">Buscar um ticket</p>
            </div>
          )}
        </div>
      </div>

      <div className="w-72 mt-1 absolute">
        <MenuSearh
          show={isPopOverActive}
          close={() => setPopOverActive(false)}
          getCustomers={getCustomers}
          handleChangeDataInput={handleChangeDataInput}
          inputFilterData={inputFilterData}
          clearFilters={clearFilters}
        />
      </div>
    </div>
  );
};

const MenuSearh = (props) => {
  const {
    show,
    getCustomers,
    handleChangeDataInput,
    inputFilterData,
    clearFilters,
    close,
  } = props;

  const menuClasses = clsx(
    "p-4 shadow-[0px_3px_20px_#0000000b] bg-white border-transparent rounded-md dark:bg-darkmode-600 dark:border-transparent  translate-y-10 -mt-10 outline-none"
  );

  return (
    <Transition
      show={show}
      enter="transition duration-300 ease-out"
      enterFrom="transform  scale-50 opacity-0"
      enterTo="transform scale-100 opacity-100 "
      leave="transition duration-300 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      as={Fragment}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          close();
          getCustomers();
        }}
        className={menuClasses}
      >
        <div>
          <div className="text-xs text-left">Nome da empresa</div>
          <FormInput
            autoFocus={true}
            onChange={(e) => {
              e.stopPropagation();
              handleChangeDataInput("business_name", e.target.value);
            }}
            value={inputFilterData.business_name}
            type="text"
            className="flex-1 mt-2"
            formInputSize="sm"
            placeholder="Digite o nome da empresa"
          />
        </div>
        <div className="mt-3">
          <div className="text-xs text-left">Nome do cliente</div>
          <FormInput
            onChange={(e) => handleChangeDataInput("name", e.target.value)}
            value={inputFilterData.name}
            formInputSize="sm"
            type="text"
            className="flex-1 mt-2"
            placeholder="Digite o nome do cliente"
          />
        </div>
        <div className="mt-3">
          <div className="text-xs text-left">E-mail</div>
          <FormInput
            onChange={(e) => handleChangeDataInput("email", e.target.value)}
            value={inputFilterData.email}
            formInputSize="sm"
            type="text"
            className="flex-1 mt-2"
            placeholder="exemplo@gmail.com"
          />
        </div>
        <div className="mt-3">
          <div className="text-xs text-left">CPF / CNPJ</div>
          <FormInput
            onChange={(e) => {
              e.stopPropagation();
              handleChangeDataInput("cpf_cnpj", e.target.value);
            }}
            value={inputFilterData.cpf_cnpj}
            formInputSize="sm"
            type="text"
            className="flex-1 mt-2"
            placeholder="000.000.000-00"
          />
        </div>
        <div className="flex items-center mt-3">
          <Button
            type="button"
            variant="secondary"
            size="sm"
            onClick={() => {
              clearFilters();
              close();
            }}
            className="w-32 ml-auto"
          >
            Limpar filtros
          </Button>
          <Button
            type="submit"
            variant="primary"
            size="sm"
            className="w-32 ml-2"
          >
            Buscar clientes
          </Button>
        </div>
      </form>
    </Transition>
  );
};

const TicketsCreate = () => {
  const navigate = useNavigate();

  const [editorData, setEditorData] = useState("");
  const [inputFilterData, setInputFilterData] = useState({
    name: "",
    business_name: "",
    email: "",
    cpf_cnpj: "",
  });
  return (
    <main>
      <section className="md:min-w-[460px] bg-white  rounded-xl font-nunito">
        <div className="flex flex-wrap items-center justify-between py-4 gap-3 xl:gap-0 border-b sm:py-0 sm:h-[58px]">
          <h6 className="text-[18px] font-semibold text-[#3F4254]">
            Ticket #3412
          </h6>
          <div className="flex gap-2">
            <Button
              variant="soft-secondary"
              size="sm"
              className="font-nunito text-[14px] font-semibold"
              as={Link}
              to="/tickets"
            >
              <Lucide icon="Undo2" className="h-7 mr-1" />
              Voltar
            </Button>
          </div>
        </div>

        <div className="grid grid-cols-12 gap-5 intro-y">
        <CustomerSelect />

          <div className="col-span-12 intro-y pb-5">
            <div>
              <Tab.Group className="mt-5 overflow-hidden intro-y box">
                <Tab.List className="flex-col border-transparent dark:border-transparent sm:flex-row bg-slate-200 dark:bg-darkmode-800 bg-white">
                  <Tab fullWidth={false} className="w-56">
                    {({ selected }) => (
                      <Tab.Button
                        className={clsx([
                          "flex items-center justify-center min-w-56 px-0 py-0 sm:w-40 text-slate-500",
                          !selected &&
                            "hover:border-transparent hover:text-slate-600 hover:dark:text-slate-300 bg-slate-300",
                          selected &&
                            "text-primary border-transparent dark:bg-darkmode-600 dark:border-x-transparent dark:border-t-transparent dark:text-white",
                        ])}
                        as="button"
                      >
                        <Tippy
                          content="Esta resposta estará visível tanto para os operadores como para o cliente."
                          className="flex items-center justify-center w-full py-4"
                          aria-controls="content"
                          aria-selected="true"
                        >
                          <Lucide icon="FileText" className="w-4 h-4 mr-2" />
                          Adicionar novo ticket
                        </Tippy>
                      </Tab.Button>
                    )}
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel className="p-5">
                    <div className="rounded-md border-slate-200/60 dark:border-darkmode-400">
                      <div className="mt-5">
                        <ClassicEditor
                          value={editorData}
                          onChange={setEditorData}
                        />
                      </div>
                      <div className="text-end mt-3">
                        <Button size="sm" variant="outline-primary">
                          Responder
                        </Button>
                      </div>
                    </div>
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};

export default TicketsCreate;
