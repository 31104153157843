// import Lucide from "../../components/Base/Lucide";
import { useEffect, useState } from "react";
// import Button from "../../components/Base/Button";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { capitalizeFirstLetter } from "../../utils/helpers";
import Header from "../Header";
// import MobileMenu from "../../components/MobileMenu";
import useAuth from "../../utils/hooks/useAuth";
import { useTranslation } from 'react-i18next';

const pagesContent = {
  Dashboard: {
    title: "Dashboard",
    text: "Visualize, filtre e gerencie todas as informações do seu negócio.",
  },
};

const Home = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState({});
  const [menuActive, setMenuActive] = useState("Clientes");
  const [content, setContent] = useState({});
  const { t } = useTranslation();

  const { auth } = useAuth();

  useEffect(() => {
    setMenuActive(
      capitalizeFirstLetter(
        location.pathname.substring(1).split("/")[0].toLowerCase()
      )
    );

    setContent(
      pagesContent[
        capitalizeFirstLetter(
          location.pathname.substring(1).split("/")[0].toLowerCase()
        )
      ]
    );
  }, [location]);

  return (
    <div className="">
      


    </div>
  );
};

export default Home;
