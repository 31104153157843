import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import CustomersList from './pages/Customers/CustomersList';
import OrdersList from './pages/Orders/OrdersList';
import OrdersCreate from './pages/Orders/OrdersCreate';
import InvoicesList from './pages/Invoices/InvoicesList';
import ProductsList from './pages/Products/ProductsList';
import ProductsCreate from './pages/Products/ProductsCreate';
import TicketsList from './pages/Tickets/TicketsList';
import TicketsCreate from './pages/Tickets/TicketsCreate';
import TicketsView from './pages/Tickets/TicketsView';
import LogsList from './pages/Logs/LogsList';
import { Overview, Profile, Orders, Invoices, Tickets, Notes, Emails, Logs } from './pages/Sumary';
import Base from "./layout/Base";
import BaseSecondary from "./layout/BaseSecondary";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        

        <Route element={<Base />}>
          <Route path="/home" element={<Home />} />
          <Route path="/customers" element={<CustomersList />} />
          <Route path="/orders" element={<OrdersList />} />
          <Route path="/orders/create" element={<OrdersCreate />} />
          <Route path="/invoices" element={<InvoicesList />} />
          <Route path="/products" element={<ProductsList />} />
          <Route path="/products/create" element={<ProductsCreate />} />
          <Route path="/tickets" element={<TicketsList />} />
          <Route path="/tickets/create" element={<TicketsCreate />} />
          <Route path="/tickets/:ticket_id" element={<TicketsView />} />
          <Route path="/logs" element={<LogsList />} />
        </Route>

        <Route path="customers/:customer_id" element={<BaseSecondary />}>
            <Route index element={<Overview />} />
            <Route path="/customers/:customer_id/profile" element={<Profile />} />
            <Route path="/customers/:customer_id/orders" element={<Orders />} />
            <Route path="/customers/:customer_id/invoices" element={<Invoices />} />
            <Route path="/customers/:customer_id/tickets" element={<Tickets />} />
            <Route path="/customers/:customer_id/notes" element={<Notes />} />
            <Route path="/customers/:customer_id/emails" element={<Emails />} />
            <Route path="/customers/:customer_id/logs" element={<Logs />} />
          </Route>

      </Routes>
    </Router>
  );
}

export default App;
