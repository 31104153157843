import { Modal as ModalAntd } from "antd";
import Button from "../Button";
import Lucide from "../Lucide";
import { useTranslation } from 'react-i18next';


function HeaderModal({ title, closeModal }) {
  return (
    <div className="flex justify-between items-center">
      <h6 className="text-black text-[20px] font-semibold leading-7">
        {title}
      </h6>
      <button onClick={closeModal}>
        <Lucide
          icon="X"
          className="hover:text-danger transition duration-300 hover:scale-125 text-gray-600"
        />
      </button>
    </div>
  );
}

function FooterModal({ closeModal, formId, isSubmitting }) {
  const { t } = useTranslation();
  return (
    <div>
      <Button
        type="button"
        className="mr-2 border-0 shadow-none text-gray-600 focus:ring-0 text-[14px] font-semibold leading-3 hover:text-danger/70"
        onClick={closeModal}
        disabled={isSubmitting}
      >
        {t('000203')}
      </Button>
      <Button
        type="submit"
        form={formId}
        variant="primary"
        className="w-[124px] h-[32px] text-[13px] font-semibold leading-4"
        disabled={isSubmitting}
      >
        {isSubmitting ? (
          t('000206')
        ) : (
          t('000204')
        )}
      </Button>
    </div>
  );
}

function Modal({
  title,
  open,
  closeModal,
  footer,
  children,
  isLoading,
  isSubmitting,
  width,
  headerComponent,
  footerComponent,
  formId = "modalForm",
  ...rest
}) {
  const { t } = useTranslation();
  return (
    <ModalAntd
      zIndex={99}
      title={
        headerComponent || <HeaderModal title={title} closeModal={closeModal} />
      }
      closeIcon={false}
      confirmLoading={isLoading}
      width={width}
      centered
      footer={
        footerComponent || footer || <FooterModal closeModal={closeModal} formId={formId} isSubmitting={isSubmitting} />
      }
      open={open}
      onCancel={closeModal}
      {...rest}
    >
      {isLoading ? <p>{t('000205')}</p> : children}
    </ModalAntd>
  );
}

export default Modal;
