import Button from "../../../components/Base/Button";
import { FormCheck, FormHelp, FormLabel } from "../../../components/Base/Form";
import * as Yup from "yup";

import Input from "../../../components/Form/InputBox";
import Select from "../../../components/Form/SelectBox";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import {
  extractNumbers,
  formatCnpjCpf,
  formatPhoneNumber,
} from "../../../utils/helpers";
import { useEffect, useRef, useState } from "react";
import { validateCNPJ, validateCPF } from "../../../utils/validations";
import CustomerService from "../../../pages/Customers/Services/CustomerService";
// import { setStatusLoading } from "@/stores/loadingSlice";
import Lucide from "../../../components/Base/Lucide";

import Swal from "sweetalert2";
import { useCustomer } from "../../../layout/BaseSecondary";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import SelectBox from "../../../components/Form/SelectBox";
import PaymentMethodService from "../../../pages/Payments/Services/PaymentMethodService";

const PhysicalPerson = ({ paymentMethods, formik }) => {
  return (
    <>
      <Input
        name="name"
        label="Nome completo"
        type="tel"
        onChange={formik.handleChange}
        value={formik.values.name}
        error={(formik.touched.name || undefined) && formik.errors.name}
        containerClassName="col-span-12 sm:col-span-4"
      />

      <Input
        label="CPF"
        mask={[
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ]}
        name="document"
        placeholder="000.000.000-00"
        value={formik.values.document}
        onChange={formik.handleChange}
        error={(formik.touched.document || undefined) && formik.errors.document}
        containerClassName="col-span-12 sm:col-span-4"
      />

      <Input
        name="email"
        label="E-mail"
        type="email"
        placeholder="usuario@empresa.com"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={(formik.touched.email || undefined) && formik.errors.email}
        containerClassName="col-span-12 sm:col-span-4"
      />

      <SelectBox
        label="Método de Pagamento"
        name="payment_method_id"
        value={formik.values.payment_method_id}
        onChange={(value) => {
          formik.setFieldValue("payment_method_id", value);
        }}
        containerClassName="col-span-12 lg:col-span-4"
        error={
          (formik.touched.payment_method_id || undefined) &&
          formik.errors.payment_method_id
        }
        allowClear
        options={[
          ...paymentMethods.reduce((acc, p) => {
            return [...acc, { label: p.description, value: p.id }];
          }, []),
        ]}
      />
      <Input
        name="dataOfBirth"
        label="Data de Nascimento"
        max={new Date().toISOString().split("T")[0]}
        type="date"
        value={formik.values.birthday}
        error={
          (formik.touched.dataOfBirth || undefined) && formik.errors.dataOfBirth
        }
        containerClassName="col-span-12 sm:col-span-4"
      />

      <Input
        name="telephone"
        type="tel"
        label="Telefone"
        placeholder="(00) 00000-0000"
        mask={[
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        value={formik.values.telephone}
        error={
          (formik.touched.telephone || undefined) && formik.errors.telephone
        }
        onChange={formik.handleChange}
        containerClassName="col-span-12 sm:col-span-2"
      />

      <Input
        name="cellphone"
        type="tel"
        label="Celular"
        placeholder="(00) 00000-0000"
        mask={[
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        value={formik.values.cellphone}
        error={
          (formik.touched.cellphone || undefined) && formik.errors.cellphone
        }
        onChange={formik.handleChange}
        containerClassName="col-span-12 sm:col-span-2"
      />
    </>
  );
};

const LegalPerson = ({ paymentMethods, formik }) => {
  return (
    <>
      <Input
        name="document"
        label="CNPJ"
        mask={[
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          ".",
          /\d/,
          /\d/,
          /\d/,
          "/",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
        ]}
        placeholder="00.000.000/0000-00"
        value={formik.values.document}
        onChange={formik.handleChange}
        error={(formik.touched.document || undefined) && formik.errors.document}
        containerClassName="col-span-12 sm:col-span-3"
      />

      <Input
        name="social_reason"
        label="Razão social"
        value={formik.values.social_reason}
        onChange={formik.handleChange}
        error={
          (formik.touched.social_reason || undefined) &&
          formik.errors.social_reason
        }
        containerClassName="col-span-12 sm:col-span-3"
      />

      <Input
        name="fantasy_name"
        label="Nome fantasia"
        value={formik.values.fantasy_name}
        onChange={formik.handleChange}
        error={formik.errors.fantasy_name}
        containerClassName="col-span-12 sm:col-span-3"
      />

      <Input
        name="name"
        label="Pessoa de Contato"
        type="tel"
        onChange={formik.handleChange}
        value={formik.values.name}
        error={(formik.touched.name || undefined) && formik.errors.name}
        containerClassName="col-span-12 sm:col-span-3"
      />

      <SelectBox
        label="Método de Pagamento"
        name="payment_method_id"
        value={formik.values.payment_method_id}
        onChange={(value) => {
          formik.setFieldValue("payment_method_id", value);
        }}
        containerClassName="col-span-12 lg:col-span-3"
        error={
          (formik.touched.payment_method_id || undefined) &&
          formik.errors.payment_method_id
        }
        allowClear
        options={[
          ...paymentMethods.reduce((acc, p) => {
            return [...acc, { label: p.description, value: p.id }];
          }, []),
        ]}
      />

      <Input
        name="email"
        label="E-mail"
        type="email"
        placeholder="usuario@empresa.com"
        value={formik.values.email}
        onChange={formik.handleChange}
        error={(formik.touched.email || undefined) && formik.errors.email}
        containerClassName="col-span-12 sm:col-span-3"
      />

      <Input
        name="telephone"
        type="tel"
        label="Telefone Comercial"
        onChange={formik.handleChange}
        value={formik.values.telephone}
        error={
          (formik.touched.telephone || undefined) && formik.errors.telephone
        }
        placeholder="(00) 00000-0000"
        mask={[
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        containerClassName="col-span-12 sm:col-span-3"
      />

      <Input
        name="cellphone"
        type="tel"
        label="Celular"
        placeholder="(00) 00000-0000"
        mask={[
          "(",
          /\d/,
          /\d/,
          ")",
          " ",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          /\d/,
          "-",
          /\d/,
          /\d/,
          /\d/,
          /\d/,
        ]}
        value={formik.values.cellphone}
        onChange={formik.handleChange}
        error={
          (formik.touched.cellphone || undefined) && formik.errors.cellphone
        }
        containerClassName="col-span-12 sm:col-span-3"
      />
    </>
  );
};

const Address = ({ formik }) => {
  return (
    <>
      <div className="flex col-span-12 gap-5 grid grid-cols-12">
        <Input
          name="zipcode"
          label="CEP"
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/]}
          placeholder="00000-000"
          value={formik.values.zipcode}
          onChange={formik.handleChange}
          error={(formik.touched.zipcode || undefined) && formik.errors.zipcode}
          containerClassName="col-span-12 sm:col-span-4"
        />

        <Input
          name="street"
          label="Logradouro"
          value={formik.values.street}
          onChange={formik.handleChange}
          error={(formik.touched.street || undefined) && formik.errors.street}
          containerClassName="col-span-12 sm:col-span-4"
        />

        <Select
          label="Cidade"
          name="city"
          containerClassName="col-span-12 sm:col-span-4"
          allowClear
          value={formik.values.city}
          error={(formik.touched.city || undefined) && formik.errors.city}
          onChange={(value) => formik.setFieldValue("city", value)}
          options={[
            {
              value: "Mateus Leme",
              label: "Mateus Leme",
            },
            {
              value: "Betim",
              label: "Betim",
            },
            {
              value: "Contagem",
              label: "Contagem",
            },
            {
              value: "Belo Horizonte",
              label: "Belo Horizonte",
            },
          ]}
        />

        <Input
          name="number"
          label="Número"
          value={formik.values.number}
          onChange={formik.handleChange}
          error={(formik.touched.number || undefined) && formik.errors.number}
          containerClassName="col-span-12 sm:col-span-2"
        />

        <Select
          label="UF"
          name="state"
          value={formik.values.state}
          onChange={(value) => formik.setFieldValue("state", value)}
          containerClassName="col-span-12 sm:col-span-2"
          error={(formik.touched.uf || undefined) && formik.errors.uf}
          allowClear
          options={[
            { value: "", label: "Selecione uma opção" },
            { label: "AC", value: "AC" },
            { label: "AL", value: "AL" },
            { label: "AP", value: "AP" },
            { label: "AM", value: "AM" },
            { label: "BA", value: "BA" },
            { label: "CE", value: "CE" },
            { label: "DF", value: "DF" },
            { label: "ES", value: "ES" },
            { label: "GO", value: "GO" },
            { label: "MA", value: "MA" },
            { label: "MT", value: "MT" },
            { label: "MS", value: "MS" },
            { label: "MG", value: "MG" },
            { label: "PA", value: "PA" },
            { label: "PB", value: "PB" },
            { label: "PR", value: "PR" },
            { label: "PE", value: "PE" },
            { label: "PI", value: "PI" },
            { label: "RJ", value: "RJ" },
            { label: "RN", value: "RN" },
            { label: "RS", value: "RS" },
            { label: "RO", value: "RO" },
            { label: "RR", value: "RR" },
            { label: "SC", value: "SC" },
            { label: "SP", value: "SP" },
            { label: "SE", value: "SE" },
            { label: "TO", value: "TO" },
          ]}
        />
        <Input
          name="neighborhood"
          label="Bairro"
          value={formik.values.neighborhood}
          onChange={formik.handleChange}
          error={
            (formik.touched.neighborhood || undefined) &&
            formik.errors.neighborhood
          }
          containerClassName="col-span-12 sm:col-span-4"
        />

        <Input
          name="complement"
          label="Complemento"
          value={formik.values.complement}
          onChange={formik.handleChange}
          error={
            (formik.touched.complement || undefined) && formik.errors.complement
          }
          containerClassName="col-span-12 sm:col-span-4"
        />
      </div>
    </>
  );
};

let realInitialValues = {};

const Profile = () => {
  const [paymentMethods, setPaymentMethods] = useState([]);

  const { customer, setCustomer, customer_id } = useCustomer();

  const imgRefAds = useRef(null);
  const fileInputRef = useRef(null);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: "PF",
      id: customer_id,
      image_id: null,
      name: null,
      document: null,
      date_of_birth: null,
      social_reason: null,
      fantasy_name: null,
      birthday: null,
      nfe_order: null,
      iss_retained: null,
      payment_method_id: null,
      invoices_separates: null,
      overdue_billing_email: null,
      penalty_overdue: null,
      email: null,
      cellphone: null,
      telephone: null,
      whats_app: null,
      street: null,
      number: null,
      complement: null,
      neighborhood: null,
      uf: null,
      city: null,
      zipcode: null,
    },
    validationSchema: Yup.object().shape({
      type: Yup.string().required("Tipo de pessoa é obrigatório"),
      payment_method_id: Yup.string()
        .oneOf(["1", "2", "3"])
        .required("O método de pagamento é obrigatório"),
      name: Yup.string().when("type", (val) => {
        return Yup.string()
          .min(3, "Informe pelo menos 3 caracteres")
          .required(
            val[0] === "PF"
              ? "Nome é obrigatório"
              : "Pessoa de Contato é obrigatório"
          );
      }),
      document: Yup.string().when("type", (val) => {
        if (val[0] === "PF") {
          return Yup.string()
            .required("CPF é obrigatório")
            .test("cpf", "CPF inválido", validateCPF);
        }
        return Yup.string()
          .required("CNPJ é obrigatório")
          .test("cnpj", "CNPJ inválido", validateCNPJ);
      }),
      email: Yup.string()
        .email("E-mail inválido")
        .required("E-mail é obrigatório"),

      fantasy_name: Yup.string().when("type", (val) => {
        if (val[0] === "PJ") {
          return Yup.string().required("Nome fantasia é obrigatório");
        }
        return Yup.string();
      }),
    }),

    onSubmit: async (values) => {
      const keys = Object.keys(realInitialValues);
      const body = {};

      // if (fileInputRef?.current.files[0]) {
      //   body["photo"] = formik.values.photo;
      // }

      keys.forEach((key) => {
        if (realInitialValues[key] !== values[key]) {
          if (["cellphone", "telephone", "whats_app"].includes(key)) {
            body[key] = extractNumbers(values[key]);
          } else {
            body[key] = values[key];
          }
        }
      });
      console.log(body);

      try {
        const res = await CustomerService.updateCustomer(customer_id, body);

        console.log(res);
        if (res.error) {
          if (res.error?.error) {
            toast.error("Corrija os erros e tente novamente.");
          } else {
            formik.setErrors(res.error.data.errors);
            toast.error("Corrija os erros e tente novamente.");
          }
        } else {
          const res = await CustomerService.findCustomer(customer_id);

          setCustomer(res.data);

          Swal.fire({
            title: "Cliente editado com sucesso!",
            text: "As informações do cliente foram atualizadas.",
            icon: "success",
            confirmButtonColor: "#3E97FF",
          });
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          console.log("aqui");

          console.log(error.status);
          console.error(error.response);
          if (error.response) {
            const responseData = error.response.data;
            if (responseData && responseData.errors) {
              formik.setErrors(responseData.errors);
            }
          }
        } else {
          toast.error("Error!");
          console.error(error);
        }
      }
    },
  });

  const setFormikForm = (customer) => {
    if (Object.keys(customer).length) {
      const customer_formated = {
        ...customer,
        document: formatCnpjCpf(customer["document"]),
        cellphone: formatPhoneNumber(customer["cellphone"]),
        telephone: formatPhoneNumber(customer["telephone"]),
        whats_app: formatPhoneNumber(customer["whats_app"]),
      };

      formik.setValues({ ...customer_formated });
      realInitialValues = { ...customer_formated };
      formik.setErrors({});
    }
  };

  useEffect(() => {
    (async () => {
      const [resPaymentMethod] = await Promise.all([
        PaymentMethodService.list(),
      ]);

      setPaymentMethods(resPaymentMethod.data);
    })();
    setFormikForm(customer);
  }, [customer]);

  const openInput = () => {
    console.log(fileInputRef);
    // fileInputRef.current.click();
  };

  return (
    <main>
      <section className="md:min-w-[460px] bg-white  rounded-xl font-nunito shadow-custom-1">
        <div className="flex justify-between items-center border-b h-[58px] p-[30px]">
          <h6 className="text-[18px] font-semibold text-[#3F4254]">
            Dados de cadastro
          </h6>
        </div>
        <form
          method="post"
          encType="multipart/form-data"
          className="p-[30px] pt-4"
          onSubmit={(e) => {
            e.preventDefault();
            console.log(formik);

            formik.handleSubmit(e);
          }}
        >
          <div className=" mb-5 col-span-12 mt-6">
            <FormLabel>Tipo de pessoa</FormLabel>
            <div className="flex flex-col  sm:flex-row">
              <FormCheck className="mr-5">
                <FormCheck.Input
                  id="radio-switch-4"
                  type="radio"
                  checked={formik.values.type === "PF"}
                  onChange={() => formik.setFieldValue("type", "PF")}
                  name="horizontal_radio_button"
                  value="fisica"
                />
                <FormCheck.Label htmlFor="radio-switch-4">
                  Pessoa física
                </FormCheck.Label>
              </FormCheck>
              <FormCheck className="mt-2 mr-2 sm:mt-0">
                <FormCheck.Input
                  id="radio-switch-5"
                  type="radio"
                  checked={formik.values.type === "PJ"}
                  onChange={() => formik.setFieldValue("type", "PJ")}
                  name="horizontal_radio_button"
                  value="juridica"
                />
                <FormCheck.Label htmlFor="radio-switch-5">
                  Pessoa jurídica
                </FormCheck.Label>
              </FormCheck>
            </div>
          </div>

          <div className="grid grid-cols-12 gap-5 border-b border-dashed pb-5">
            {formik.values.type === "PF" ? (
              <PhysicalPerson paymentMethods={paymentMethods} formik={formik} />
            ) : (
              <LegalPerson paymentMethods={paymentMethods} formik={formik} />
            )}
          </div>

          <div className="border-b border-dashed pb-5">
            <h6 className="text-base font-bold py-4">
              Administrador da empresa
            </h6>
            <div className="grid grid-cols-12 gap-5">
              <Select
                label="Emitir Nota Fiscal"
                name="nfe_order"
                containerClassName="col-span-12 sm:col-span-4"
                value={formik.values.nfe_order}
                error={
                  (formik.touched.nfe_order || undefined) &&
                  formik.errors.nfe_order
                }
                onChange={(value) => formik.setFieldValue("nfe_order", value)}
                options={[
                  {
                    value: true,
                    label: "Sim",
                  },
                  {
                    value: false,
                    label: "Não",
                  },
                ]}
              />
              <Select
                label="ISS Retido"
                name="iss_retained"
                containerClassName="col-span-12 sm:col-span-4"
                value={formik.values.iss_retained}
                error={
                  (formik.touched.iss_retained || undefined) &&
                  formik.errors.iss_retained
                }
                onChange={(value) =>
                  formik.setFieldValue("iss_retained", value)
                }
                options={[
                  {
                    value: true,
                    label: "Sim",
                  },
                  {
                    value: false,
                    label: "Não",
                  },
                ]}
              />
            </div>
            <div className="mt-5">
              <div className="flex flex-col mt-2">
                <FormCheck className="mr-2 mb-2">
                  <FormCheck.Input
                    id="checkbox-switch-4"
                    type="checkbox"
                    value={1}
                    checked={formik.values.penalty_overdue}
                    name="penalty_overdue"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "penalty_overdue",
                        !!e.target.checked
                      )
                    }
                    className="h-6 w-6"
                  />
                  <FormCheck.Label htmlFor="checkbox-switch-4">
                    Não aplicar multas em caso de atraso.
                  </FormCheck.Label>
                </FormCheck>
                <FormCheck className="mt-2 mr-2  mb-2">
                  <FormCheck.Input
                    id="checkbox-switch-5"
                    type="checkbox"
                    value={1}
                    className="h-6 w-6"
                    checked={formik.values.overdue_billing_email}
                    name="overdue_billing_email"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "overdue_billing_email",
                        !!e.target.checked
                      )
                    }
                  />
                  <FormCheck.Label htmlFor="checkbox-switch-5">
                    Não enviar e-mails de cobranças em atraso.
                  </FormCheck.Label>
                </FormCheck>
                <FormCheck className="mt-2 mr-2 mb-2">
                  <FormCheck.Input
                    id="checkbox-switch-6"
                    type="checkbox"
                    value={1}
                    className="h-6 w-6"
                    checked={formik.values.invoices_separates}
                    name="invoices_separates"
                    onChange={(e) =>
                      formik.setFieldValue(
                        "invoices_separates",
                        !!e.target.checked
                      )
                    }
                  />
                  <FormCheck.Label htmlFor="checkbox-switch-6">
                    Separar faturas por serviços.
                  </FormCheck.Label>
                </FormCheck>
              </div>
            </div>
          </div>

          <div className="border-b border-dashed pb-5">
            <h6 className="text-base font-bold py-4">Endereço</h6>
            <div className="grid grid-cols-12 gap-5">
              <Address formik={formik} />
            </div>
          </div>

          <div className="mt-4 text-end">
            <Button type="submit" variant="primary">
              Salvar alterações
            </Button>
          </div>
        </form>
      </section>
    </main>
  );
};

export default Profile;
